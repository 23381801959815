import { createLifecycleFor } from 'actions/account/collections';

const TYPE = 'entitlementsV2';

export const {
  callApi: getEntitlements,
  reject,
  resolve,
  patterns,
} = createLifecycleFor(TYPE);
