import { getContext, call, put, takeEvery } from 'redux-saga/effects';
import { patterns, reject, resolve } from 'actions/entitlements-v2';
import { reportError } from 'helpers/error-reporting';

export const normalize = results => {
  return results.reduce((acc, next) => {
    acc[next.name] = next;
    return acc;
  }, {});
};

export function* fetchEntitlements() {
  try {
    const apiV2 = yield getContext('apiV2');
    const { data: results } = yield call(apiV2.getEntitlements);
    yield put(resolve(normalize(results)));
  } catch (error) {
    yield call(reportError, error);
    yield put(reject(error));
  }
}

export default function* entitlementsV2Sagas() {
  yield takeEvery([patterns.callApi], fetchEntitlements);
}
