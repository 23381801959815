import { createSelector } from 'reselect';
import { getLeafNodes } from '@studio/conditions';

import { readAccount } from 'next/entities/account';
import { selectPinIssuesAnalytics } from 'next/entities/analytics';
import {
  readExperiencesByType,
  readMobileFlows,
  selectExperience,
} from 'next/entities/experiences';

import { readFlows, selectFlow, selectFlows } from 'next/entities/flows';
import { selectPins, selectPin, readPins } from 'next/entities/pins';
import { selectProfileAttributeLabels } from 'next/entities/profile-attribute-labels';
import { readRules, selectRule } from 'next/entities/rules';
import { selectPublishedRules } from 'next/entities/published-rules';
import { readSegments, selectSegment } from 'next/entities/segments';
import { readTags, selectTag } from 'next/entities/tags';
import { readUsers } from 'next/entities/users';
import { selectVersionedExperience } from 'next/entities/versioned-experiences';
import { selectTargetingOptions } from 'next/entities/targeting';
import {
  ENTITLEMENTS,
  selectEntitlementsByName,
} from 'next/entities/entitlements';

import { getPropertyDisplayName, getOptGroup } from './profile-attributes';

/**
 * NOTE: Consider using reselect for these compound selectors as well as the
 *       various selectors that use request status.
 *
 *       Also, once we figure out how best to handle error states, we should
 *       handle that in the UI appropriately.
 *
 *       Finally, many of the compound selectors here _could_ be generalized to
 *       handle flows AND experiences and should be renamed appropriately
 */

/**
 * Helper function to determine status of requests
 *
 * @param {...RequestStatus} requests - Request statuses
 * @return {RequestStatus} Consolidated status
 */
const check = (...requests) => {
  const errored = requests.some(({ error }) => error);
  if (errored) {
    return { loading: false, error: true };
  }

  const pending = requests.some(({ loading }) => loading);
  if (pending) {
    return { loading: true, error: false };
  }

  return null;
};

/**
 * Read target domains for an experience
 *
 * @param {State} state - Redux state
 * @param {string} id - Experience ID
 * @return {RequestStatus<string[]>} List of target domains for the experience
 */

export const readDomainsForExperience = createSelector(
  state => readRules(state),
  (state, id) => id,
  (rules = {}, id) => {
    const status = check(rules);
    if (status !== null) {
      return status;
    }

    const { conditions } = rules.data[id] ?? {};
    const extracted = conditions ? getLeafNodes(conditions) : [];

    const data = extracted
      .filter(({ domains }) => {
        return Boolean(domains) && domains.operator === '==';
      })
      .map(({ domains: { value } }) => value)
      .filter(Boolean);

    return { data, loading: false, error: false };
  }
);

/**
 * Read segments for an experience
 *
 * @param {State} state - Redux state
 * @param {string} id - Experience ID
 * @return {RequestStatus<Segment[]>} List of segments for the experience
 */
export const readSegmentsForExperience = (state, id) => {
  const rules = readRules(state);
  const segments = readSegments(state);

  const status = check(rules, segments);
  if (status !== null) {
    return status;
  }

  const { conditions } = selectRule(state, id) ?? {};
  const extracted = getLeafNodes(conditions);

  const data = extracted
    .filter(condition => Boolean(condition.segments))
    .flatMap(condition => Object.values(condition))
    .map(({ segment }) => selectSegment(state, segment))
    .filter(Boolean);

  return { data, loading: false, error: false };
};

export const readTagsForExperience = (state, tagIds) => {
  const data = (tagIds ?? []).map(tag => selectTag(state, tag)).filter(Boolean);

  return { data, loading: false, error: false };
};

/**
 * Read tags for a flow
 *
 * @param {State} state - Redux state
 * @param {string} id - Flow ID
 * @return {RequestStatus<Tag[]>} List of tags for the flow
 */
export const readTagsForFlow = (state, id, type) => {
  const flows = readFlows(state);
  const mobile = readMobileFlows(state);

  const tags = readTags(state);

  const status = check(flows, mobile, tags);
  if (status !== null) {
    return status;
  }

  const { tagIds } = ['mobile', 'mobile_embed'].includes(type)
    ? selectExperience(state, id) ?? { tagIds: [] }
    : selectFlow(state, id) ?? { tagIds: [] };

  return readTagsForExperience(state, tagIds);
};

/**
 * Read tags for a pin
 *
 * @param {State} state - Redux state
 * @param {string} id - Pin ID
 * @return {RequestStatus<Tag[]>} List of tags for the pin
 */
export const readTagsForPins = (state, id) => {
  const pins = readPins(state);
  const tags = readTags(state);

  const status = check(pins, tags);
  if (status !== null) {
    return status;
  }

  const { tagIds } = selectPin(state, id) ?? {};
  return readTagsForExperience(state, tagIds);
};

/**
 * Read tags for a an experience
 *
 * @param {State} state - Redux state
 * @param {string} id - Experience ID
 * @return {RequestStatus<Tag[]>} List of tags for the experience
 */
export const readExperienceTags = (state, id) => {
  const tags = readTags(state);

  const status = check(tags);
  if (status !== null) {
    return status;
  }

  const { tagIds } = selectExperience(state, id) ?? { tagIds: [] };
  return readTagsForExperience(state, tagIds);
};

/**
 * Select associations for tags
 *
 * @param {State} state - Redux state
 * @return {object<string[]>} List of IDs associated with tags
 */
export const selectAssociationsForTags = createSelector(
  selectFlows,
  selectPins,
  (flows = {}, pins = {}) => {
    const experiences = { ...flows, ...pins };

    return Object.values(experiences).reduce((acc, experience) => {
      const tags = experience.tagIds ?? [];

      tags.forEach(id => {
        acc[id] = [...(acc[id] || []), experience.id];
      });

      return acc;
    }, {});
  }
);

/**
 * Select associations for a single tag
 *
 * @param {State} state - Redux state
 * @return {string[]} List of IDs associated for tag
 */
export const selectAssociationsForTag = createSelector(
  selectAssociationsForTags,
  (state, id) => id,
  (associations, id) => associations[id]
);

/**
 * Select account user with username
 *
 * @param {State} state - Redux state
 * @return {RequestStatus<id,name>} List of user
 */
export const readCreators = state => {
  const users = readUsers(state);

  const data = Object.values(users.data).reduce(
    (acc, { id, email, fullname }) => {
      // there are some users without a fullname or email
      acc[id] = { id, name: fullname ?? email ?? id };

      return acc;
    },
    {}
  );
  return { ...users, data };
};

/**
 *  Select domains from current account
 *
 * @param {State} state - Redux state
 * @return {string[]} List of domains
 */
export const readDomainsForAccount = createSelector(
  readAccount,
  (account = {}) => {
    const status = check(account);
    if (status !== null) {
      return status;
    }

    const domains = account.data?.domains || [];

    return { data: domains, loading: false, error: false };
  }
);

/**
 * Read experience associations for domains
 *
 * @param {State} state - Redux state
 * @param {Object<Flow|Pin>} experiences
 * @return {RequestStatus<string[]>} object where key is domain and value is array of experience ids
 */

export const readAssociationsForDomains = createSelector(
  (state, experiences) => experiences,
  readRules,
  readDomainsForAccount,
  (experiences = {}, rules = {}, accountDomains = []) => {
    const status = check(rules, accountDomains);
    if (status !== null) {
      return status;
    }

    const { data: rulesData } = rules;
    const { data: accountDomainsData } = accountDomains;

    /*
     * Create keys from all domains where values are arrays of experience IDs
     * ex. {'google.com': ['flow-1', 'flow-2', 'pin-0']}
     */
    const associations = Object.values(experiences).reduce((acc, { id }) => {
      const { data: domains } = readDomainsForExperience(
        { rules: rulesData },
        id
      );

      domains.forEach(domain => {
        acc[domain] = [...(acc[domain] || []), id];
      });

      return acc;
    }, {});

    /* Create key/value pairs from accountDomains list */
    const unassociatedDomains = accountDomainsData.reduce((acc, domain) => {
      acc[domain] = [];
      return acc;
    }, {});

    return {
      data: { ...unassociatedDomains, ...associations },
      loading: false,
      error: false,
    };
  }
);

/**
 * Read flow associations for domains
 *
 * @param {State} state - Redux state
 * @return {RequestStatus<string[]>} object where key is domain and value is array of flow ids
 */
export const readFlowAssociationsForDomains = createSelector(
  readFlows,
  state => state,
  (flows = {}, state = {}) => {
    const status = check(flows);
    if (status !== null) {
      return status;
    }

    const { data } = flows;
    return readAssociationsForDomains(state, data);
  }
);

/**
 * Read pin associations for domains
 *
 * @param {State} state - Redux state
 * @return {RequestStatus<string[]>} object where key is domain and value is array of pin ids
 */
export const readPinAssociationsForDomains = createSelector(
  readPins,
  state => state,
  (pins = {}, state = {}) => {
    const status = check(pins);
    if (status !== null) {
      return status;
    }

    const { data } = pins;
    return readAssociationsForDomains(state, data);
  }
);

/**
 * Read Experience per Type associations for domains
 *
 * @param {State} state - Redux state
 * @return {RequestStatus<string[]>} object where key is domain and value is array of experience ids
 */
export const readExperienceAssociationsForDomains = createSelector(
  (state, type) => readExperiencesByType(state, type),
  state => state,
  (experiences = {}, state = {}) => {
    const status = check(experiences);
    if (status !== null) {
      return status;
    }

    const { data } = experiences;
    return readAssociationsForDomains(state, data);
  }
);

/**
 * Select how many days back a customer can view data historically
 *
 * @return {number} Number of days from today
 */
export const selectHistoricalLimit = createSelector(
  state => selectEntitlementsByName(state, ENTITLEMENTS.ANALYTICS_LOOKBACK),
  entitlements => entitlements?.allowed_units
);

// Alias for backwards compatibility
export const selectExperienceHistoricalLimit = selectHistoricalLimit;

export const selectAccountProfileAttributes = createSelector(
  [selectTargetingOptions, selectProfileAttributeLabels],
  (targeting, meta) => {
    return (targeting?.profileAttributes ?? []).reduce(
      (acc, { name: attribute }) => {
        const {
          id,
          label = meta[attribute]?.label || getPropertyDisplayName(attribute),
          description = '',
          showInUI = true,
        } = Object.values(meta).find(({ name }) => name === attribute) || {};

        // @todo determine how to fix this
        const lastSeenTs = 0;

        acc[attribute] = {
          source: id ? 'firebase' : 'api',
          description,
          name: attribute,
          id,
          label,
          lastSeenTs,
          showInUI,
          // @todo are values needed here?
          values: [],
        };

        return acc;
      },
      {}
    );
  }
);

const ATTRIBUTE_DENYLIST = new Set(['_ABGroup', '_testContentId']);

export const selectProfileAttributeDropdownOptions = createSelector(
  [selectAccountProfileAttributes],
  (attributes = {}) => {
    return Object.values(
      Object.values(attributes)
        .filter(
          ({ name, showInUI }) =>
            name &&
            !ATTRIBUTE_DENYLIST.has(name) &&
            (showInUI === undefined || !!showInUI)
        )
        .map(({ label, name: value }) => ({
          label: label || getPropertyDisplayName(value),
          value,
          isAutoProp: value.match(/^_/),
          group: getOptGroup(value),
        }))
        .reduce(
          (acc, option) => {
            const { group } = option;
            if (acc[group]) {
              acc[group].options.push(option);
            }
            return acc;
          },
          {
            normal: {
              label: 'CUSTOM PROPERTIES',
              options: [],
              order: 1,
            },
            form: {
              label: 'FORM RESPONSES',
              options: [],
              order: 2,
            },
            device: {
              label: 'DEVICE PROPERTIES',
              options: [],
              order: 3,
            },
            satisfaction: {
              label: 'USER SATISFACTION',
              options: [],
              order: 4,
            },
            auto: {
              label: 'AUTO-PROPERTIES',
              options: [],
              order: 5,
            },
          }
        )
    );
  }
);

/**
 * If an experience has been published, return the versioned-experience from
 * the most recent publish.
 *
 * Otherwise, return the experience
 *
 * @param state
 * @param id
 */

export const selectPublishedExperience = (state, id) => {
  const experience = selectExperience(state, id);

  if (experience) {
    const { publishedAt } = experience;

    if (publishedAt) {
      return selectVersionedExperience(state, id, publishedAt);
    }

    return experience;
  }

  return null;
};

/**
 * If a pin has been published, return the versioned-experience from
 * the most recent publish.
 *
 * Otherwise, return the pin
 *
 * @param state
 * @param id
 */

export const selectPublishedPin = (state, id) => {
  const pin = selectPin(state, id);

  if (pin) {
    const { publishedAt } = pin;

    if (publishedAt) {
      return selectVersionedExperience(state, id, publishedAt);
    }

    return pin;
  }

  return null;
};

export const selectPineIssues = (state, queryParams, experienceId) => {
  const analytics = selectPinIssuesAnalytics(state, queryParams);

  const { steps } = selectPin(state, experienceId) || {};

  return analytics?.map(({ step_id: stepId, name: stepName, ...rest }) => {
    const { name } = steps.find(step => step.id === stepId) ?? { name: '' };
    return {
      step_id: stepId,
      name,
      ...rest,
      timestamp: new Date(rest.timestamp),
    };
  });
};

export const selectPublishedFlowRules = createSelector(
  selectPublishedRules,
  selectFlows,
  (rules, flows) => {
    return Object.entries(rules ?? {})
      .filter(([, { contentType }]) => contentType === 'journey')
      .reduce((acc, [id, rule]) => {
        acc[id] = { ...rule, flowName: flows[id]?.name };
        return acc;
      }, {});
  }
);
