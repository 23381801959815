/**
 * Do not edit directly
 * To update design tokens, edit the files in the tokens directory
 */

module.exports = {
  color: {
    neutral: {
      0: {
        value: '#ffffff',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#FFFFFF',
          type: 'color',
        },
        name: 'ColorNeutral0',
        attributes: {
          category: 'color',
          type: 'neutral',
          item: '0',
        },
        path: ['color', 'neutral', '0'],
      },
      50: {
        value: '#f7faff',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#F7FAFF',
          type: 'color',
        },
        name: 'ColorNeutral50',
        attributes: {
          category: 'color',
          type: 'neutral',
          item: '50',
        },
        path: ['color', 'neutral', '50'],
      },
      100: {
        value: '#edf2fa',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#EDF2FA',
          type: 'color',
        },
        name: 'ColorNeutral100',
        attributes: {
          category: 'color',
          type: 'neutral',
          item: '100',
        },
        path: ['color', 'neutral', '100'],
      },
      200: {
        value: '#dce4f2',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#DCE4F2',
          type: 'color',
        },
        name: 'ColorNeutral200',
        attributes: {
          category: 'color',
          type: 'neutral',
          item: '200',
        },
        path: ['color', 'neutral', '200'],
      },
      300: {
        value: '#bdc7db',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#BDC7DB',
          type: 'color',
        },
        name: 'ColorNeutral300',
        attributes: {
          category: 'color',
          type: 'neutral',
          item: '300',
        },
        path: ['color', 'neutral', '300'],
      },
      400: {
        value: '#99a6bf',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#99A6BF',
          type: 'color',
        },
        name: 'ColorNeutral400',
        attributes: {
          category: 'color',
          type: 'neutral',
          item: '400',
        },
        path: ['color', 'neutral', '400'],
      },
      500: {
        value: '#8492ae',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#8492AE',
          type: 'color',
        },
        name: 'ColorNeutral500',
        attributes: {
          category: 'color',
          type: 'neutral',
          item: '500',
        },
        path: ['color', 'neutral', '500'],
      },
      600: {
        value: '#627293',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#627293',
          type: 'color',
        },
        name: 'ColorNeutral600',
        attributes: {
          category: 'color',
          type: 'neutral',
          item: '600',
        },
        path: ['color', 'neutral', '600'],
      },
      700: {
        value: '#425678',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#425678',
          type: 'color',
        },
        name: 'ColorNeutral700',
        attributes: {
          category: 'color',
          type: 'neutral',
          item: '700',
        },
        path: ['color', 'neutral', '700'],
      },
      800: {
        value: '#1f2f4f',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#1F2F4F',
          type: 'color',
        },
        name: 'ColorNeutral800',
        attributes: {
          category: 'color',
          type: 'neutral',
          item: '800',
        },
        path: ['color', 'neutral', '800'],
      },
      900: {
        value: '#0b1a38',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#0B1A38',
          type: 'color',
        },
        name: 'ColorNeutral900',
        attributes: {
          category: 'color',
          type: 'neutral',
          item: '900',
        },
        path: ['color', 'neutral', '900'],
      },
    },
    blurple: {
      50: {
        value: '#fafaff',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#FAFAFF',
          type: 'color',
        },
        name: 'ColorBlurple50',
        attributes: {
          category: 'color',
          type: 'blurple',
          item: '50',
        },
        path: ['color', 'blurple', '50'],
      },
      100: {
        value: '#eeeeff',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#EEEEFF',
          type: 'color',
        },
        name: 'ColorBlurple100',
        attributes: {
          category: 'color',
          type: 'blurple',
          item: '100',
        },
        path: ['color', 'blurple', '100'],
      },
      200: {
        value: '#e0e0ff',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#E0E0FF',
          type: 'color',
        },
        name: 'ColorBlurple200',
        attributes: {
          category: 'color',
          type: 'blurple',
          item: '200',
        },
        path: ['color', 'blurple', '200'],
      },
      300: {
        value: '#c2c2ff',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#C2C2FF',
          type: 'color',
        },
        name: 'ColorBlurple300',
        attributes: {
          category: 'color',
          type: 'blurple',
          item: '300',
        },
        path: ['color', 'blurple', '300'],
      },
      400: {
        value: '#9c9cfe',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#9C9CFE',
          type: 'color',
        },
        name: 'ColorBlurple400',
        attributes: {
          category: 'color',
          type: 'blurple',
          item: '400',
        },
        path: ['color', 'blurple', '400'],
      },
      500: {
        value: '#8686fe',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#8686FE',
          type: 'color',
        },
        name: 'ColorBlurple500',
        attributes: {
          category: 'color',
          type: 'blurple',
          item: '500',
        },
        path: ['color', 'blurple', '500'],
      },
      600: {
        value: '#5c5cff',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#5C5CFF',
          type: 'color',
        },
        name: 'ColorBlurple600',
        attributes: {
          category: 'color',
          type: 'blurple',
          item: '600',
        },
        path: ['color', 'blurple', '600'],
      },
      700: {
        value: '#4343c5',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#4343C5',
          type: 'color',
        },
        name: 'ColorBlurple700',
        attributes: {
          category: 'color',
          type: 'blurple',
          item: '700',
        },
        path: ['color', 'blurple', '700'],
      },
    },
    green: {
      50: {
        value: '#f2fcfc',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#F2FCFC',
          type: 'color',
        },
        name: 'ColorGreen50',
        attributes: {
          category: 'color',
          type: 'green',
          item: '50',
        },
        path: ['color', 'green', '50'],
      },
      100: {
        value: '#dcf7f7',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#DCF7F7',
          type: 'color',
        },
        name: 'ColorGreen100',
        attributes: {
          category: 'color',
          type: 'green',
          item: '100',
        },
        path: ['color', 'green', '100'],
      },
      300: {
        value: '#20e0d6',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#20E0D6',
          type: 'color',
        },
        name: 'ColorGreen300',
        attributes: {
          category: 'color',
          type: 'green',
          item: '300',
        },
        path: ['color', 'green', '300'],
      },
      400: {
        value: '#00c2c2',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#00C2C2',
          type: 'color',
        },
        name: 'ColorGreen400',
        attributes: {
          category: 'color',
          type: 'green',
          item: '400',
        },
        path: ['color', 'green', '400'],
      },
      500: {
        value: '#00a3a3',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#00A3A3',
          type: 'color',
        },
        name: 'ColorGreen500',
        attributes: {
          category: 'color',
          type: 'green',
          item: '500',
        },
        path: ['color', 'green', '500'],
      },
      600: {
        value: '#108484',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#108484',
          type: 'color',
        },
        name: 'ColorGreen600',
        attributes: {
          category: 'color',
          type: 'green',
          item: '600',
        },
        path: ['color', 'green', '600'],
      },
      700: {
        value: '#005e5e',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#005E5E',
          type: 'color',
        },
        name: 'ColorGreen700',
        attributes: {
          category: 'color',
          type: 'green',
          item: '700',
        },
        path: ['color', 'green', '700'],
      },
    },
    pink: {
      50: {
        value: '#fff7fb',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#FFF7FB',
          type: 'color',
        },
        name: 'ColorPink50',
        attributes: {
          category: 'color',
          type: 'pink',
          item: '50',
        },
        path: ['color', 'pink', '50'],
      },
      100: {
        value: '#ffebf3',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#FFEBF3',
          type: 'color',
        },
        name: 'ColorPink100',
        attributes: {
          category: 'color',
          type: 'pink',
          item: '100',
        },
        path: ['color', 'pink', '100'],
      },
      300: {
        value: '#ffadcf',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#FFADCF',
          type: 'color',
        },
        name: 'ColorPink300',
        attributes: {
          category: 'color',
          type: 'pink',
          item: '300',
        },
        path: ['color', 'pink', '300'],
      },
      400: {
        value: '#ff75af',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#FF75AF',
          type: 'color',
        },
        name: 'ColorPink400',
        attributes: {
          category: 'color',
          type: 'pink',
          item: '400',
        },
        path: ['color', 'pink', '400'],
      },
      500: {
        value: '#ff4592',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#FF4592',
          type: 'color',
        },
        name: 'ColorPink500',
        attributes: {
          category: 'color',
          type: 'pink',
          item: '500',
        },
        path: ['color', 'pink', '500'],
      },
      600: {
        value: '#dd2270',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#DD2270',
          type: 'color',
        },
        name: 'ColorPink600',
        attributes: {
          category: 'color',
          type: 'pink',
          item: '600',
        },
        path: ['color', 'pink', '600'],
      },
      700: {
        value: '#a6084a',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#A6084A',
          type: 'color',
        },
        name: 'ColorPink700',
        attributes: {
          category: 'color',
          type: 'pink',
          item: '700',
        },
        path: ['color', 'pink', '700'],
      },
    },
    blue: {
      50: {
        value: '#f5faff',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#F5FAFF',
          type: 'color',
        },
        name: 'ColorBlue50',
        attributes: {
          category: 'color',
          type: 'blue',
          item: '50',
        },
        path: ['color', 'blue', '50'],
      },
      100: {
        value: '#e3f2ff',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#E3F2FF',
          type: 'color',
        },
        name: 'ColorBlue100',
        attributes: {
          category: 'color',
          type: 'blue',
          item: '100',
        },
        path: ['color', 'blue', '100'],
      },
      300: {
        value: '#91ccff',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#91CCFF',
          type: 'color',
        },
        name: 'ColorBlue300',
        attributes: {
          category: 'color',
          type: 'blue',
          item: '300',
        },
        path: ['color', 'blue', '300'],
      },
      400: {
        value: '#47a9ff',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#47A9FF',
          type: 'color',
        },
        name: 'ColorBlue400',
        attributes: {
          category: 'color',
          type: 'blue',
          item: '400',
        },
        path: ['color', 'blue', '400'],
      },
      500: {
        value: '#1492ff',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#1492FF',
          type: 'color',
        },
        name: 'ColorBlue500',
        attributes: {
          category: 'color',
          type: 'blue',
          item: '500',
        },
        path: ['color', 'blue', '500'],
      },
      600: {
        value: '#0072d6',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#0072D6',
          type: 'color',
        },
        name: 'ColorBlue600',
        attributes: {
          category: 'color',
          type: 'blue',
          item: '600',
        },
        path: ['color', 'blue', '600'],
      },
      700: {
        value: '#0056a1',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#0056A1',
          type: 'color',
        },
        name: 'ColorBlue700',
        attributes: {
          category: 'color',
          type: 'blue',
          item: '700',
        },
        path: ['color', 'blue', '700'],
      },
    },
    yellow: {
      50: {
        value: '#fffae6',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#FFFAE6',
          type: 'color',
        },
        name: 'ColorYellow50',
        attributes: {
          category: 'color',
          type: 'yellow',
          item: '50',
        },
        path: ['color', 'yellow', '50'],
      },
      100: {
        value: '#fff0b3',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#FFF0B3',
          type: 'color',
        },
        name: 'ColorYellow100',
        attributes: {
          category: 'color',
          type: 'yellow',
          item: '100',
        },
        path: ['color', 'yellow', '100'],
      },
      300: {
        value: '#ffc400',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#FFC400',
          type: 'color',
        },
        name: 'ColorYellow300',
        attributes: {
          category: 'color',
          type: 'yellow',
          item: '300',
        },
        path: ['color', 'yellow', '300'],
      },
      400: {
        value: '#ffab00',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#FFAB00',
          type: 'color',
        },
        name: 'ColorYellow400',
        attributes: {
          category: 'color',
          type: 'yellow',
          item: '400',
        },
        path: ['color', 'yellow', '400'],
      },
      500: {
        value: '#eb8d00',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#EB8D00',
          type: 'color',
        },
        name: 'ColorYellow500',
        attributes: {
          category: 'color',
          type: 'yellow',
          item: '500',
        },
        path: ['color', 'yellow', '500'],
      },
      600: {
        value: '#a66300',
        type: 'color',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '#A66300',
          type: 'color',
        },
        name: 'ColorYellow600',
        attributes: {
          category: 'color',
          type: 'yellow',
          item: '600',
        },
        path: ['color', 'yellow', '600'],
      },
    },
  },
  foreground: {
    primary: {
      value: '#1f2f4f',
      type: 'color',
      comment: 'Default color for headings, labels, inputs, tags, and badges',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.neutral.800}',
        type: 'color',
        comment: 'Default color for headings, labels, inputs, tags, and badges',
      },
      name: 'ForegroundPrimary',
      attributes: {
        category: 'foreground',
        type: 'primary',
      },
      path: ['foreground', 'primary'],
    },
    secondary: {
      value: '#425678',
      type: 'color',
      comment: 'Default body text & icon color ',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.neutral.700}',
        type: 'color',
        comment: 'Default body text & icon color ',
      },
      name: 'ForegroundSecondary',
      attributes: {
        category: 'foreground',
        type: 'secondary',
      },
      path: ['foreground', 'secondary'],
    },
    tertiary: {
      value: '#627293',
      type: 'color',
      comment: 'Default for tertiary text',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.neutral.600}',
        type: 'color',
        comment: 'Default for tertiary text',
      },
      name: 'ForegroundTertiary',
      attributes: {
        category: 'foreground',
        type: 'tertiary',
      },
      path: ['foreground', 'tertiary'],
    },
    disabled: {
      value: '#8492ae',
      type: 'color',
      comment: 'Default for disabled text & icons',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.neutral.500}',
        type: 'color',
        comment: 'Default for disabled text & icons',
      },
      name: 'ForegroundDisabled',
      attributes: {
        category: 'foreground',
        type: 'disabled',
      },
      path: ['foreground', 'disabled'],
    },
    link: {
      value: '#5c5cff',
      type: 'color',
      comment: 'Default for text links',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.blurple.600}',
        type: 'color',
        comment: 'Default for text links',
      },
      name: 'ForegroundLink',
      attributes: {
        category: 'foreground',
        type: 'link',
      },
      path: ['foreground', 'link'],
    },
    'link-active': {
      value: '#4343c5',
      type: 'color',
      comment: 'Default active state for text links',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.blurple.700}',
        type: 'color',
        comment: 'Default active state for text links',
      },
      name: 'ForegroundLinkActive',
      attributes: {
        category: 'foreground',
        type: 'link-active',
      },
      path: ['foreground', 'link-active'],
    },
    success: {
      value: '#108484',
      type: 'color',
      comment: 'Use for icons & text of success messages',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.green.600}',
        type: 'color',
        comment: 'Use for icons & text of success messages',
      },
      name: 'ForegroundSuccess',
      attributes: {
        category: 'foreground',
        type: 'success',
      },
      path: ['foreground', 'success'],
    },
    error: {
      value: '#dd2270',
      type: 'color',
      comment: 'Use for icons & text emphasizing errors, action is required',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.pink.600}',
        type: 'color',
        comment: 'Use for icons & text emphasizing errors, action is required',
      },
      name: 'ForegroundError',
      attributes: {
        category: 'foreground',
        type: 'error',
      },
      path: ['foreground', 'error'],
    },
    'inline-error': {
      value: '#dd2270',
      type: 'color',
      comment: 'Use for icons & text in inline error messages',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.pink.600}',
        type: 'color',
        comment: 'Use for icons & text in inline error messages',
      },
      name: 'ForegroundInlineError',
      attributes: {
        category: 'foreground',
        type: 'inline-error',
      },
      path: ['foreground', 'inline-error'],
    },
    warning: {
      value: '#a66300',
      type: 'color',
      comment: 'Use for icons & text emphasizing warnings',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.yellow.600}',
        type: 'color',
        comment: 'Use for icons & text emphasizing warnings',
      },
      name: 'ForegroundWarning',
      attributes: {
        category: 'foreground',
        type: 'warning',
      },
      path: ['foreground', 'warning'],
    },
    info: {
      value: '#0072d6',
      type: 'color',
      comment: 'Use for icons associated with help or educational messages',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.blue.600}',
        type: 'color',
        comment: 'Use for icons associated with help or educational messages',
      },
      name: 'ForegroundInfo',
      attributes: {
        category: 'foreground',
        type: 'info',
      },
      path: ['foreground', 'info'],
    },
    inverse: {
      value: '#ffffff',
      type: 'color',
      comment: 'Use for text and icons on high contrast backgrounds',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.neutral.0}',
        type: 'color',
        comment: 'Use for text and icons on high contrast backgrounds',
      },
      name: 'ForegroundInverse',
      attributes: {
        category: 'foreground',
        type: 'inverse',
      },
      path: ['foreground', 'inverse'],
    },
    button: {
      primary: {
        default: {
          value: '#ffffff',
          type: 'color',
          comment: 'Default for text & icons on primary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.neutral.0}',
            type: 'color',
            comment: 'Default for text & icons on primary buttons',
          },
          name: 'ForegroundButtonPrimaryDefault',
          attributes: {
            category: 'foreground',
            type: 'button',
            item: 'primary',
            subitem: 'default',
          },
          path: ['foreground', 'button', 'primary', 'default'],
        },
        hover: {
          value: '#ffffff',
          type: 'color',
          comment: 'Use for text & icons on hover for primary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.neutral.0}',
            type: 'color',
            comment: 'Use for text & icons on hover for primary buttons',
          },
          name: 'ForegroundButtonPrimaryHover',
          attributes: {
            category: 'foreground',
            type: 'button',
            item: 'primary',
            subitem: 'hover',
          },
          path: ['foreground', 'button', 'primary', 'hover'],
        },
        active: {
          value: '#ffffff',
          type: 'color',
          comment: 'Use for text & icons for active primary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.neutral.0}',
            type: 'color',
            comment: 'Use for text & icons for active primary buttons',
          },
          name: 'ForegroundButtonPrimaryActive',
          attributes: {
            category: 'foreground',
            type: 'button',
            item: 'primary',
            subitem: 'active',
          },
          path: ['foreground', 'button', 'primary', 'active'],
        },
        disabled: {
          value: '#ffffff',
          type: 'color',
          comment: 'Default for text & icons on primary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.neutral.0}',
            type: 'color',
            comment: 'Default for text & icons on primary buttons',
          },
          name: 'ForegroundButtonPrimaryDisabled',
          attributes: {
            category: 'foreground',
            type: 'button',
            item: 'primary',
            subitem: 'disabled',
          },
          path: ['foreground', 'button', 'primary', 'disabled'],
        },
      },
      secondary: {
        default: {
          value: '#5c5cff',
          type: 'color',
          comment: 'Default for text & icons on secondary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.blurple.600}',
            type: 'color',
            comment: 'Default for text & icons on secondary buttons',
          },
          name: 'ForegroundButtonSecondaryDefault',
          attributes: {
            category: 'foreground',
            type: 'button',
            item: 'secondary',
            subitem: 'default',
          },
          path: ['foreground', 'button', 'secondary', 'default'],
        },
        hover: {
          value: '#4343c5',
          type: 'color',
          comment: 'Use for text & icons on hover for secondary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.blurple.700}',
            type: 'color',
            comment: 'Use for text & icons on hover for secondary buttons',
          },
          name: 'ForegroundButtonSecondaryHover',
          attributes: {
            category: 'foreground',
            type: 'button',
            item: 'secondary',
            subitem: 'hover',
          },
          path: ['foreground', 'button', 'secondary', 'hover'],
        },
        active: {
          value: '#4343c5',
          type: 'color',
          comment: 'Use for text & icons for active  secondary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.blurple.700}',
            type: 'color',
            comment: 'Use for text & icons for active  secondary buttons',
          },
          name: 'ForegroundButtonSecondaryActive',
          attributes: {
            category: 'foreground',
            type: 'button',
            item: 'secondary',
            subitem: 'active',
          },
          path: ['foreground', 'button', 'secondary', 'active'],
        },
        disabled: {
          value: '#8492ae',
          type: 'color',
          comment: 'Default for text & icons on secondary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.neutral.500}',
            type: 'color',
            comment: 'Default for text & icons on secondary buttons',
          },
          name: 'ForegroundButtonSecondaryDisabled',
          attributes: {
            category: 'foreground',
            type: 'button',
            item: 'secondary',
            subitem: 'disabled',
          },
          path: ['foreground', 'button', 'secondary', 'disabled'],
        },
      },
      tertiary: {
        default: {
          value: '#5c5cff',
          type: 'color',
          comment: 'Default for text & icons on tertiary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.blurple.600}',
            type: 'color',
            comment: 'Default for text & icons on tertiary buttons',
          },
          name: 'ForegroundButtonTertiaryDefault',
          attributes: {
            category: 'foreground',
            type: 'button',
            item: 'tertiary',
            subitem: 'default',
          },
          path: ['foreground', 'button', 'tertiary', 'default'],
        },
        hover: {
          value: '#4343c5',
          type: 'color',
          comment: 'Use for text & icons on hover for tertiary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.blurple.700}',
            type: 'color',
            comment: 'Use for text & icons on hover for tertiary buttons',
          },
          name: 'ForegroundButtonTertiaryHover',
          attributes: {
            category: 'foreground',
            type: 'button',
            item: 'tertiary',
            subitem: 'hover',
          },
          path: ['foreground', 'button', 'tertiary', 'hover'],
        },
        active: {
          value: '#4343c5',
          type: 'color',
          comment: 'Use for text & icons for active tertiary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.blurple.700}',
            type: 'color',
            comment: 'Use for text & icons for active tertiary buttons',
          },
          name: 'ForegroundButtonTertiaryActive',
          attributes: {
            category: 'foreground',
            type: 'button',
            item: 'tertiary',
            subitem: 'active',
          },
          path: ['foreground', 'button', 'tertiary', 'active'],
        },
        disabled: {
          value: '#8492ae',
          type: 'color',
          comment: 'Default for text & icons on tertiary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.neutral.500}',
            type: 'color',
            comment: 'Default for text & icons on tertiary buttons',
          },
          name: 'ForegroundButtonTertiaryDisabled',
          attributes: {
            category: 'foreground',
            type: 'button',
            item: 'tertiary',
            subitem: 'disabled',
          },
          path: ['foreground', 'button', 'tertiary', 'disabled'],
        },
      },
      destructive: {
        default: {
          value: '#ffffff',
          type: 'color',
          comment: 'Default for text & icons on destructive buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.neutral.0}',
            type: 'color',
            comment: 'Default for text & icons on destructive buttons',
          },
          name: 'ForegroundButtonDestructiveDefault',
          attributes: {
            category: 'foreground',
            type: 'button',
            item: 'destructive',
            subitem: 'default',
          },
          path: ['foreground', 'button', 'destructive', 'default'],
        },
        hover: {
          value: '#ffffff',
          type: 'color',
          comment: 'Use for text & icons on hover for destructive buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.neutral.0}',
            type: 'color',
            comment: 'Use for text & icons on hover for destructive buttons',
          },
          name: 'ForegroundButtonDestructiveHover',
          attributes: {
            category: 'foreground',
            type: 'button',
            item: 'destructive',
            subitem: 'hover',
          },
          path: ['foreground', 'button', 'destructive', 'hover'],
        },
        active: {
          value: '#ffffff',
          type: 'color',
          comment: 'Use for text & icons for active destructive buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.neutral.0}',
            type: 'color',
            comment: 'Use for text & icons for active destructive buttons',
          },
          name: 'ForegroundButtonDestructiveActive',
          attributes: {
            category: 'foreground',
            type: 'button',
            item: 'destructive',
            subitem: 'active',
          },
          path: ['foreground', 'button', 'destructive', 'active'],
        },
        disabled: {
          value: '#ffffff',
          type: 'color',
          comment: 'Default for text & icons on destructive buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.neutral.0}',
            type: 'color',
            comment: 'Default for text & icons on destructive buttons',
          },
          name: 'ForegroundButtonDestructiveDisabled',
          attributes: {
            category: 'foreground',
            type: 'button',
            item: 'destructive',
            subitem: 'disabled',
          },
          path: ['foreground', 'button', 'destructive', 'disabled'],
        },
      },
    },
    input: {
      placeholder: {
        value: '#627293',
        type: 'color',
        comment: 'Use for placeholder text on text inputs',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.neutral.600}',
          type: 'color',
          comment: 'Use for placeholder text on text inputs',
        },
        name: 'ForegroundInputPlaceholder',
        attributes: {
          category: 'foreground',
          type: 'input',
          item: 'placeholder',
        },
        path: ['foreground', 'input', 'placeholder'],
      },
    },
    selected: {
      value: '#5c5cff',
      type: 'color',
      comment: 'Used for highlighted items text color',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.blurple.600}',
        type: 'color',
        comment: 'Used for highlighted items text color',
      },
      name: 'ForegroundSelected',
      attributes: {
        category: 'foreground',
        type: 'selected',
      },
      path: ['foreground', 'selected'],
    },
    boolean: {
      active: {
        value: '#0056a1',
        type: 'color',
        comment: 'Use for text and iconography of active toggle buttons',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.blue.700}',
          type: 'color',
          comment: 'Use for text and iconography of active toggle buttons',
        },
        name: 'ForegroundBooleanActive',
        attributes: {
          category: 'foreground',
          type: 'boolean',
          item: 'active',
        },
        path: ['foreground', 'boolean', 'active'],
      },
    },
    brand: {
      value: '#5c5cff',
      type: 'color',
      comment:
        'Use for icons & text elements that reflect our main brand color, but are not interactive (use sparingly)',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.blurple.600}',
        type: 'color',
        comment:
          'Use for icons & text elements that reflect our main brand color, but are not interactive (use sparingly)',
      },
      name: 'ForegroundBrand',
      attributes: {
        category: 'foreground',
        type: 'brand',
      },
      path: ['foreground', 'brand'],
    },
  },
  background: {
    default: {
      base: {
        value: '#edf2fa',
        type: 'color',
        comment:
          'Used for backgrounds of low importance elements, can be used with icons and text, but no interactive elements',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.neutral.100}',
          type: 'color',
          comment:
            'Used for backgrounds of low importance elements, can be used with icons and text, but no interactive elements',
        },
        name: 'BackgroundDefaultBase',
        attributes: {
          category: 'background',
          type: 'default',
          item: 'base',
        },
        path: ['background', 'default', 'base'],
      },
    },
    brand: {
      base: {
        value: '#eeeeff',
        type: 'color',
        comment:
          'Use for backgrounds of featured or branded elements, can be used with text and icons, but no interactive elements',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.blurple.100}',
          type: 'color',
          comment:
            'Use for backgrounds of featured or branded elements, can be used with text and icons, but no interactive elements',
        },
        name: 'BackgroundBrandBase',
        attributes: {
          category: 'background',
          type: 'brand',
          item: 'base',
        },
        path: ['background', 'brand', 'base'],
      },
    },
    app: {
      value: '#f7faff',
      type: 'color',
      comment: 'Used for the default page background',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.neutral.50}',
        type: 'color',
        comment: 'Used for the default page background',
      },
      name: 'BackgroundApp',
      attributes: {
        category: 'background',
        type: 'app',
      },
      path: ['background', 'app'],
    },
    level: {
      1: {
        value: '#ffffff',
        type: 'color',
        comment: 'Use for background of level 1 surfaces',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.neutral.0}',
          type: 'color',
          comment: 'Use for background of level 1 surfaces',
        },
        name: 'BackgroundLevel1',
        attributes: {
          category: 'background',
          type: 'level',
          item: '1',
        },
        path: ['background', 'level', '1'],
      },
      2: {
        value: '#ffffff',
        type: 'color',
        comment: 'Use for background of level 2 surfaces',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.neutral.0}',
          type: 'color',
          comment: 'Use for background of level 2 surfaces',
        },
        name: 'BackgroundLevel2',
        attributes: {
          category: 'background',
          type: 'level',
          item: '2',
        },
        path: ['background', 'level', '2'],
      },
      3: {
        value: '#ffffff',
        type: 'color',
        comment: 'Use for background of level 3 surfaces',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.neutral.0}',
          type: 'color',
          comment: 'Use for background of level 3 surfaces',
        },
        name: 'BackgroundLevel3',
        attributes: {
          category: 'background',
          type: 'level',
          item: '3',
        },
        path: ['background', 'level', '3'],
      },
    },
    disabled: {
      value: '#bdc7db',
      type: 'color',
      comment: 'Use for backgrounds for disabled sections',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.neutral.300}',
        type: 'color',
        comment: 'Use for backgrounds for disabled sections',
      },
      name: 'BackgroundDisabled',
      attributes: {
        category: 'background',
        type: 'disabled',
      },
      path: ['background', 'disabled'],
    },
    success: {
      value: '#f2fcfc',
      type: 'color',
      comment: 'Used to highlight success or positive messages',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.green.50}',
        type: 'color',
        comment: 'Used to highlight success or positive messages',
      },
      name: 'BackgroundSuccess',
      attributes: {
        category: 'background',
        type: 'success',
      },
      path: ['background', 'success'],
    },
    'success-base': {
      value: '#dcf7f7',
      type: 'color',
      comment:
        'Use for backgrounds of positive or success elements, can be used with text and icons, but no interactive elements',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.green.100}',
        type: 'color',
        comment:
          'Use for backgrounds of positive or success elements, can be used with text and icons, but no interactive elements',
      },
      name: 'BackgroundSuccessBase',
      attributes: {
        category: 'background',
        type: 'success-base',
      },
      path: ['background', 'success-base'],
    },
    error: {
      value: '#fff7fb',
      type: 'color',
      comment:
        'Use for highlighting errors or blocked statuses that require action',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.pink.50}',
        type: 'color',
        comment:
          'Use for highlighting errors or blocked statuses that require action',
      },
      name: 'BackgroundError',
      attributes: {
        category: 'background',
        type: 'error',
      },
      path: ['background', 'error'],
    },
    'error-base': {
      value: '#ffebf3',
      type: 'color',
      comment:
        'Use for backgrounds of critical, error, or blocking elements, can be used with text and icons, but no interactive elements',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.pink.100}',
        type: 'color',
        comment:
          'Use for backgrounds of critical, error, or blocking elements, can be used with text and icons, but no interactive elements',
      },
      name: 'BackgroundErrorBase',
      attributes: {
        category: 'background',
        type: 'error-base',
      },
      path: ['background', 'error-base'],
    },
    warning: {
      value: '#fffae6',
      type: 'color',
      comment: 'Use for highlighting warnings that require a user’s attention',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.yellow.50}',
        type: 'color',
        comment:
          'Use for highlighting warnings that require a user’s attention',
      },
      name: 'BackgroundWarning',
      attributes: {
        category: 'background',
        type: 'warning',
      },
      path: ['background', 'warning'],
    },
    'warning-base': {
      value: '#fff0b3',
      type: 'color',
      comment:
        'Use for backgrounds of warnings, can be used with text and icons, but no interactive elements',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.yellow.100}',
        type: 'color',
        comment:
          'Use for backgrounds of warnings, can be used with text and icons, but no interactive elements',
      },
      name: 'BackgroundWarningBase',
      attributes: {
        category: 'background',
        type: 'warning-base',
      },
      path: ['background', 'warning-base'],
    },
    info: {
      base: {
        value: '#e3f2ff',
        type: 'color',
        comment:
          'Use for backgrounds for informational or educational elements, can be used with icons and text, but no interactive elements',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.blue.100}',
          type: 'color',
          comment:
            'Use for backgrounds for informational or educational elements, can be used with icons and text, but no interactive elements',
        },
        name: 'BackgroundInfoBase',
        attributes: {
          category: 'background',
          type: 'info',
          item: 'base',
        },
        path: ['background', 'info', 'base'],
      },
      weak: {
        value: '#f5faff',
        type: 'color',
        comment: 'Use for highlighting informational or educational items',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.blue.50}',
          type: 'color',
          comment: 'Use for highlighting informational or educational items',
        },
        name: 'BackgroundInfoWeak',
        attributes: {
          category: 'background',
          type: 'info',
          item: 'weak',
        },
        path: ['background', 'info', 'weak'],
      },
    },
    input: {
      default: {
        value: '#ffffff',
        type: 'color',
        comment: 'Used for highlighting interactive text & icons',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.neutral.0}',
          type: 'color',
          comment: 'Used for highlighting interactive text & icons',
        },
        name: 'BackgroundInputDefault',
        attributes: {
          category: 'background',
          type: 'input',
          item: 'default',
        },
        path: ['background', 'input', 'default'],
      },
      disabled: {
        value: '#f7faff',
        type: 'color',
        comment: 'Used for highlighting interactive text & icons',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.neutral.50}',
          type: 'color',
          comment: 'Used for highlighting interactive text & icons',
        },
        name: 'BackgroundInputDisabled',
        attributes: {
          category: 'background',
          type: 'input',
          item: 'disabled',
        },
        path: ['background', 'input', 'disabled'],
      },
    },
    selected: {
      value: '#f7faff',
      type: 'color',
      comment: 'Used for highlighted items background',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.neutral.50}',
        type: 'color',
        comment: 'Used for highlighted items background',
      },
      name: 'BackgroundSelected',
      attributes: {
        category: 'background',
        type: 'selected',
      },
      path: ['background', 'selected'],
    },
    button: {
      primary: {
        default: {
          value: '#5c5cff',
          type: 'color',
          comment: 'Default background for primary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.blurple.600}',
            type: 'color',
            comment: 'Default background for primary buttons',
          },
          name: 'BackgroundButtonPrimaryDefault',
          attributes: {
            category: 'background',
            type: 'button',
            item: 'primary',
            subitem: 'default',
          },
          path: ['background', 'button', 'primary', 'default'],
        },
        hover: {
          value: '#4343c5',
          type: 'color',
          comment: 'Use for background on hover state of primary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.blurple.700}',
            type: 'color',
            comment: 'Use for background on hover state of primary buttons',
          },
          name: 'BackgroundButtonPrimaryHover',
          attributes: {
            category: 'background',
            type: 'button',
            item: 'primary',
            subitem: 'hover',
          },
          path: ['background', 'button', 'primary', 'hover'],
        },
        active: {
          value: 'linear-gradient(90deg, #5c5cff 0%, #7D52FF 100%)',
          type: 'color',
          comment: 'Use for background on active primary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{gradient.blurple}',
            type: 'color',
            comment: 'Use for background on active primary buttons',
          },
          name: 'BackgroundButtonPrimaryActive',
          attributes: {
            category: 'background',
            type: 'button',
            item: 'primary',
            subitem: 'active',
          },
          path: ['background', 'button', 'primary', 'active'],
        },
        disabled: {
          value: '#bdc7db',
          type: 'color',
          comment: 'Use for background of disabled primary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.neutral.300}',
            type: 'color',
            comment: 'Use for background of disabled primary buttons',
          },
          name: 'BackgroundButtonPrimaryDisabled',
          attributes: {
            category: 'background',
            type: 'button',
            item: 'primary',
            subitem: 'disabled',
          },
          path: ['background', 'button', 'primary', 'disabled'],
        },
      },
      secondary: {
        default: {
          value: '#ffffff',
          type: 'color',
          comment: 'Default background for secondary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.neutral.0}',
            type: 'color',
            comment: 'Default background for secondary buttons',
          },
          name: 'BackgroundButtonSecondaryDefault',
          attributes: {
            category: 'background',
            type: 'button',
            item: 'secondary',
            subitem: 'default',
          },
          path: ['background', 'button', 'secondary', 'default'],
        },
        hover: {
          value: '#eeeeff',
          type: 'color',
          comment: 'Use for background on hover state of secondary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.blurple.100}',
            type: 'color',
            comment: 'Use for background on hover state of secondary buttons',
          },
          name: 'BackgroundButtonSecondaryHover',
          attributes: {
            category: 'background',
            type: 'button',
            item: 'secondary',
            subitem: 'hover',
          },
          path: ['background', 'button', 'secondary', 'hover'],
        },
        active: {
          value: '#e0e0ff',
          type: 'color',
          comment: 'Use for background on active secondary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.blurple.200}',
            type: 'color',
            comment: 'Use for background on active secondary buttons',
          },
          name: 'BackgroundButtonSecondaryActive',
          attributes: {
            category: 'background',
            type: 'button',
            item: 'secondary',
            subitem: 'active',
          },
          path: ['background', 'button', 'secondary', 'active'],
        },
        disabled: {
          value: '#ffffff',
          type: 'color',
          comment: 'Use for background of disabled secondary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.neutral.0}',
            type: 'color',
            comment: 'Use for background of disabled secondary buttons',
          },
          name: 'BackgroundButtonSecondaryDisabled',
          attributes: {
            category: 'background',
            type: 'button',
            item: 'secondary',
            subitem: 'disabled',
          },
          path: ['background', 'button', 'secondary', 'disabled'],
        },
      },
      tertiary: {
        default: {
          value: '#ffffff',
          type: 'color',
          comment: 'Default background for tertiary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.neutral.0}',
            type: 'color',
            comment: 'Default background for tertiary buttons',
          },
          name: 'BackgroundButtonTertiaryDefault',
          attributes: {
            category: 'background',
            type: 'button',
            item: 'tertiary',
            subitem: 'default',
          },
          path: ['background', 'button', 'tertiary', 'default'],
        },
        hover: {
          value: '#eeeeff',
          type: 'color',
          comment: 'Use for background on hover state of tertiary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.blurple.100}',
            type: 'color',
            comment: 'Use for background on hover state of tertiary buttons',
          },
          name: 'BackgroundButtonTertiaryHover',
          attributes: {
            category: 'background',
            type: 'button',
            item: 'tertiary',
            subitem: 'hover',
          },
          path: ['background', 'button', 'tertiary', 'hover'],
        },
        active: {
          value: '#e0e0ff',
          type: 'color',
          comment: 'Use for background on active tertiary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.blurple.200}',
            type: 'color',
            comment: 'Use for background on active tertiary buttons',
          },
          name: 'BackgroundButtonTertiaryActive',
          attributes: {
            category: 'background',
            type: 'button',
            item: 'tertiary',
            subitem: 'active',
          },
          path: ['background', 'button', 'tertiary', 'active'],
        },
        disabled: {
          value: '#ffffff',
          type: 'color',
          comment: 'Use for background of disabled tertiary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.neutral.0}',
            type: 'color',
            comment: 'Use for background of disabled tertiary buttons',
          },
          name: 'BackgroundButtonTertiaryDisabled',
          attributes: {
            category: 'background',
            type: 'button',
            item: 'tertiary',
            subitem: 'disabled',
          },
          path: ['background', 'button', 'tertiary', 'disabled'],
        },
      },
      destructive: {
        default: {
          value: '#dd2270',
          type: 'color',
          comment: 'Default background for destructive buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.pink.600}',
            type: 'color',
            comment: 'Default background for destructive buttons',
          },
          name: 'BackgroundButtonDestructiveDefault',
          attributes: {
            category: 'background',
            type: 'button',
            item: 'destructive',
            subitem: 'default',
          },
          path: ['background', 'button', 'destructive', 'default'],
        },
        hover: {
          value: '#a6084a',
          type: 'color',
          comment: 'Use for background on hover state of destructive buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.pink.700}',
            type: 'color',
            comment: 'Use for background on hover state of destructive buttons',
          },
          name: 'BackgroundButtonDestructiveHover',
          attributes: {
            category: 'background',
            type: 'button',
            item: 'destructive',
            subitem: 'hover',
          },
          path: ['background', 'button', 'destructive', 'hover'],
        },
        active: {
          value: '#dd2270',
          type: 'color',
          comment: 'Use for background on active destructive buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.pink.600}',
            type: 'color',
            comment: 'Use for background on active destructive buttons',
          },
          name: 'BackgroundButtonDestructiveActive',
          attributes: {
            category: 'background',
            type: 'button',
            item: 'destructive',
            subitem: 'active',
          },
          path: ['background', 'button', 'destructive', 'active'],
        },
        disabled: {
          value: '#bdc7db',
          type: 'color',
          comment: 'Use for background of disabled destructive buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.neutral.300}',
            type: 'color',
            comment: 'Use for background of disabled destructive buttons',
          },
          name: 'BackgroundButtonDestructiveDisabled',
          attributes: {
            category: 'background',
            type: 'button',
            item: 'destructive',
            subitem: 'disabled',
          },
          path: ['background', 'button', 'destructive', 'disabled'],
        },
      },
    },
    'toggle-button': {
      label: {
        value: '#dce4f2',
        type: 'color',
        comment: 'Use as the background on toggle button labels',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.neutral.200}',
          type: 'color',
          comment: 'Use as the background on toggle button labels',
        },
        name: 'BackgroundToggleButtonLabel',
        attributes: {
          category: 'background',
          type: 'toggle-button',
          item: 'label',
        },
        path: ['background', 'toggle-button', 'label'],
      },
    },
    boolean: {
      active: {
        value: '#e3f2ff',
        type: 'color',
        comment: 'Use for background of toggle buttons while active',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.blue.100}',
          type: 'color',
          comment: 'Use for background of toggle buttons while active',
        },
        name: 'BackgroundBooleanActive',
        attributes: {
          category: 'background',
          type: 'boolean',
          item: 'active',
        },
        path: ['background', 'boolean', 'active'],
      },
      hover: {
        value: '#edf2fa',
        type: 'color',
        comment: 'Use for background of toggle buttons while hovering',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.neutral.100}',
          type: 'color',
          comment: 'Use for background of toggle buttons while hovering',
        },
        name: 'BackgroundBooleanHover',
        attributes: {
          category: 'background',
          type: 'boolean',
          item: 'hover',
        },
        path: ['background', 'boolean', 'hover'],
      },
    },
    interactive: {
      active: {
        default: {
          value: '#0072d6',
          type: 'color',
          comment: 'Use for active, selected interactive elements',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.blue.600}',
            type: 'color',
            comment: 'Use for active, selected interactive elements',
          },
          name: 'BackgroundInteractiveActiveDefault',
          attributes: {
            category: 'background',
            type: 'interactive',
            item: 'active',
            subitem: 'default',
          },
          path: ['background', 'interactive', 'active', 'default'],
        },
        hover: {
          value: '#0056a1',
          type: 'color',
          comment:
            'Use for hover state on active, selected interactive elements',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.blue.700}',
            type: 'color',
            comment:
              'Use for hover state on active, selected interactive elements',
          },
          name: 'BackgroundInteractiveActiveHover',
          attributes: {
            category: 'background',
            type: 'interactive',
            item: 'active',
            subitem: 'hover',
          },
          path: ['background', 'interactive', 'active', 'hover'],
        },
      },
      error: {
        default: {
          value: '#dd2270',
          type: 'color',
          comment:
            'Use for error state on active or selected interactive elements',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.pink.600}',
            type: 'color',
            comment:
              'Use for error state on active or selected interactive elements',
          },
          name: 'BackgroundInteractiveErrorDefault',
          attributes: {
            category: 'background',
            type: 'interactive',
            item: 'error',
            subitem: 'default',
          },
          path: ['background', 'interactive', 'error', 'default'],
        },
        hover: {
          value: '#a6084a',
          type: 'color',
          comment:
            'Use for hover on error state of active or selected interactive elements',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.pink.700}',
            type: 'color',
            comment:
              'Use for hover on error state of active or selected interactive elements',
          },
          name: 'BackgroundInteractiveErrorHover',
          attributes: {
            category: 'background',
            type: 'interactive',
            item: 'error',
            subitem: 'hover',
          },
          path: ['background', 'interactive', 'error', 'hover'],
        },
      },
    },
  },
  border: {
    default: {
      value: '#dce4f2',
      type: 'color',
      comment: 'Default color for dividers',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.neutral.200}',
        type: 'color',
        comment: 'Default color for dividers',
      },
      name: 'BorderDefault',
      attributes: {
        category: 'border',
        type: 'default',
      },
      path: ['border', 'default'],
    },
    'default-base': {
      value: '#edf2fa',
      type: 'color',
      comment:
        'Default color for borders on low important messages, badges, and neutral avatars',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.neutral.100}',
        type: 'color',
        comment:
          'Default color for borders on low important messages, badges, and neutral avatars',
      },
      name: 'BorderDefaultBase',
      attributes: {
        category: 'border',
        type: 'default-base',
      },
      path: ['border', 'default-base'],
    },
    brand: {
      value: '#5c5cff',
      type: 'color',
      comment: 'Use for borders on branded elements',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.blurple.600}',
        type: 'color',
        comment: 'Use for borders on branded elements',
      },
      name: 'BorderBrand',
      attributes: {
        category: 'border',
        type: 'brand',
      },
      path: ['border', 'brand'],
    },
    'brand-base': {
      value: '#eeeeff',
      type: 'color',
      comment:
        'Use for borders on brand or featured badges, and blurple avatars',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.blurple.100}',
        type: 'color',
        comment:
          'Use for borders on brand or featured badges, and blurple avatars',
      },
      name: 'BorderBrandBase',
      attributes: {
        category: 'border',
        type: 'brand-base',
      },
      path: ['border', 'brand-base'],
    },
    input: {
      default: {
        value: '#8492ae',
        type: 'color',
        comment: 'Default input border for textarea, text input & selects',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.neutral.500}',
          type: 'color',
          comment: 'Default input border for textarea, text input & selects',
        },
        name: 'BorderInputDefault',
        attributes: {
          category: 'border',
          type: 'input',
          item: 'default',
        },
        path: ['border', 'input', 'default'],
      },
      hover: {
        value: '#425678',
        type: 'color',
        comment: 'Use for hover state on textarea, text input & selects',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.neutral.700}',
          type: 'color',
          comment: 'Use for hover state on textarea, text input & selects',
        },
        name: 'BorderInputHover',
        attributes: {
          category: 'border',
          type: 'input',
          item: 'hover',
        },
        path: ['border', 'input', 'hover'],
      },
      active: {
        value: '#0072d6',
        type: 'color',
        comment: 'Use for active state on textarea, text input & selects',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.blue.600}',
          type: 'color',
          comment: 'Use for active state on textarea, text input & selects',
        },
        name: 'BorderInputActive',
        attributes: {
          category: 'border',
          type: 'input',
          item: 'active',
        },
        path: ['border', 'input', 'active'],
      },
      'active-outline': {
        value: '#91ccff',
        type: 'color',
        comment: 'Use for outer focus ring for textarea, text input & selects',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.blue.300}',
          type: 'color',
          comment:
            'Use for outer focus ring for textarea, text input & selects',
        },
        name: 'BorderInputActiveOutline',
        attributes: {
          category: 'border',
          type: 'input',
          item: 'active-outline',
        },
        path: ['border', 'input', 'active-outline'],
      },
      error: {
        value: '#dd2270',
        type: 'color',
        comment: 'Use for error state on textarea, text input & selects',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.pink.600}',
          type: 'color',
          comment: 'Use for error state on textarea, text input & selects',
        },
        name: 'BorderInputError',
        attributes: {
          category: 'border',
          type: 'input',
          item: 'error',
        },
        path: ['border', 'input', 'error'],
      },
      'error-outline': {
        value: '#ffadcf',
        type: 'color',
        comment:
          'Use for outer ring for error state on textarea, text input & selects',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.pink.300}',
          type: 'color',
          comment:
            'Use for outer ring for error state on textarea, text input & selects',
        },
        name: 'BorderInputErrorOutline',
        attributes: {
          category: 'border',
          type: 'input',
          item: 'error-outline',
        },
        path: ['border', 'input', 'error-outline'],
      },
      disabled: {
        value: '#dce4f2',
        type: 'color',
        comment: 'Used to highlight success or positive messages',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.neutral.200}',
          type: 'color',
          comment: 'Used to highlight success or positive messages',
        },
        name: 'BorderInputDisabled',
        attributes: {
          category: 'border',
          type: 'input',
          item: 'disabled',
        },
        path: ['border', 'input', 'disabled'],
      },
      'read-only': {
        value: '#8492ae',
        type: 'color',
        comment: 'Use for borders on read only inputs',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.neutral.500}',
          type: 'color',
          comment: 'Use for borders on read only inputs',
        },
        name: 'BorderInputReadOnly',
        attributes: {
          category: 'border',
          type: 'input',
          item: 'read-only',
        },
        path: ['border', 'input', 'read-only'],
      },
    },
    link: {
      focus: {
        value: '#0072d6',
        type: 'color',
        comment: 'Used to highlight focus on links',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.blue.600}',
          type: 'color',
          comment: 'Used to highlight focus on links',
        },
        name: 'BorderLinkFocus',
        attributes: {
          category: 'border',
          type: 'link',
          item: 'focus',
        },
        path: ['border', 'link', 'focus'],
      },
    },
    success: {
      value: '#108484',
      type: 'color',
      comment: 'Use for borders on success messages',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.green.600}',
        type: 'color',
        comment: 'Use for borders on success messages',
      },
      name: 'BorderSuccess',
      attributes: {
        category: 'border',
        type: 'success',
      },
      path: ['border', 'success'],
    },
    'success-base': {
      value: '#dcf7f7',
      type: 'color',
      comment: 'Use for borders on success badges, and green avatars',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.green.100}',
        type: 'color',
        comment: 'Use for borders on success badges, and green avatars',
      },
      name: 'BorderSuccessBase',
      attributes: {
        category: 'border',
        type: 'success-base',
      },
      path: ['border', 'success-base'],
    },
    error: {
      value: '#dd2270',
      type: 'color',
      comment: 'Use for borders on errors that require action',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.pink.600}',
        type: 'color',
        comment: 'Use for borders on errors that require action',
      },
      name: 'BorderError',
      attributes: {
        category: 'border',
        type: 'error',
      },
      path: ['border', 'error'],
    },
    'error-base': {
      value: '#ffebf3',
      type: 'color',
      comment:
        'Use for borders on critical, danger, or error badges and pink avatars',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.pink.100}',
        type: 'color',
        comment:
          'Use for borders on critical, danger, or error badges and pink avatars',
      },
      name: 'BorderErrorBase',
      attributes: {
        category: 'border',
        type: 'error-base',
      },
      path: ['border', 'error-base'],
    },
    warning: {
      value: '#a66300',
      type: 'color',
      comment: 'Use for borders on warnings that require a user’s attention',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.yellow.600}',
        type: 'color',
        comment: 'Use for borders on warnings that require a user’s attention',
      },
      name: 'BorderWarning',
      attributes: {
        category: 'border',
        type: 'warning',
      },
      path: ['border', 'warning'],
    },
    'warning-base': {
      value: '#fff0b3',
      type: 'color',
      comment: 'Use for borders on warning badges, and yellow avatars',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.yellow.100}',
        type: 'color',
        comment: 'Use for borders on warning badges, and yellow avatars',
      },
      name: 'BorderWarningBase',
      attributes: {
        category: 'border',
        type: 'warning-base',
      },
      path: ['border', 'warning-base'],
    },
    info: {
      value: '#0072d6',
      type: 'color',
      comment: 'Use for borders on informational or educational messages',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.blue.600}',
        type: 'color',
        comment: 'Use for borders on informational or educational messages',
      },
      name: 'BorderInfo',
      attributes: {
        category: 'border',
        type: 'info',
      },
      path: ['border', 'info'],
    },
    'info-base': {
      value: '#e3f2ff',
      type: 'color',
      comment:
        'Use for borders on informational or educational messages, and blue avatars',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '{color.blue.100}',
        type: 'color',
        comment:
          'Use for borders on informational or educational messages, and blue avatars',
      },
      name: 'BorderInfoBase',
      attributes: {
        category: 'border',
        type: 'info-base',
      },
      path: ['border', 'info-base'],
    },
    button: {
      focus: {
        value: '#0072d6',
        type: 'color',
        comment: 'Use for outer focus ring on all buttons',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.blue.600}',
          type: 'color',
          comment: 'Use for outer focus ring on all buttons',
        },
        name: 'BorderButtonFocus',
        attributes: {
          category: 'border',
          type: 'button',
          item: 'focus',
        },
        path: ['border', 'button', 'focus'],
      },
      active: {
        value: '#bdc7db',
        type: 'color',
        comment: 'Use for outer borders on all active state buttons',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.neutral.300}',
          type: 'color',
          comment: 'Use for outer borders on all active state buttons',
        },
        name: 'BorderButtonActive',
        attributes: {
          category: 'border',
          type: 'button',
          item: 'active',
        },
        path: ['border', 'button', 'active'],
      },
      primary: {
        default: {
          value: 'linear-gradient(90deg, #5c5cff 0%, #7D52FF 100%)',
          type: 'color',
          comment: 'Use for borders on primary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{gradient.blurple}',
            type: 'color',
            comment: 'Use for borders on primary buttons',
          },
          name: 'BorderButtonPrimaryDefault',
          attributes: {
            category: 'border',
            type: 'button',
            item: 'primary',
            subitem: 'default',
          },
          path: ['border', 'button', 'primary', 'default'],
        },
        hover: {
          value: '#4343c5',
          type: 'color',
          comment: 'Use for borders on hover for primary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.blurple.700}',
            type: 'color',
            comment: 'Use for borders on hover for primary buttons',
          },
          name: 'BorderButtonPrimaryHover',
          attributes: {
            category: 'border',
            type: 'button',
            item: 'primary',
            subitem: 'hover',
          },
          path: ['border', 'button', 'primary', 'hover'],
        },
        focus: {
          value: 'linear-gradient(90deg, #5c5cff 0%, #7D52FF 100%)',
          type: 'color',
          comment: 'Use for borders on hover for primary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{gradient.blurple}',
            type: 'color',
            comment: 'Use for borders on hover for primary buttons',
          },
          name: 'BorderButtonPrimaryFocus',
          attributes: {
            category: 'border',
            type: 'button',
            item: 'primary',
            subitem: 'focus',
          },
          path: ['border', 'button', 'primary', 'focus'],
        },
        active: {
          value: 'linear-gradient(90deg, #5c5cff 0%, #7D52FF 100%)',
          type: 'color',
          comment: 'Use for borders for active primary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{gradient.blurple}',
            type: 'color',
            comment: 'Use for borders for active primary buttons',
          },
          name: 'BorderButtonPrimaryActive',
          attributes: {
            category: 'border',
            type: 'button',
            item: 'primary',
            subitem: 'active',
          },
          path: ['border', 'button', 'primary', 'active'],
        },
        disabled: {
          value: '#bdc7db',
          type: 'color',
          comment: 'Use for borders for disabled primary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.neutral.300}',
            type: 'color',
            comment: 'Use for borders for disabled primary buttons',
          },
          name: 'BorderButtonPrimaryDisabled',
          attributes: {
            category: 'border',
            type: 'button',
            item: 'primary',
            subitem: 'disabled',
          },
          path: ['border', 'button', 'primary', 'disabled'],
        },
      },
      secondary: {
        default: {
          value: '#5c5cff',
          type: 'color',
          comment: 'Use for borders on secondary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.blurple.600}',
            type: 'color',
            comment: 'Use for borders on secondary buttons',
          },
          name: 'BorderButtonSecondaryDefault',
          attributes: {
            category: 'border',
            type: 'button',
            item: 'secondary',
            subitem: 'default',
          },
          path: ['border', 'button', 'secondary', 'default'],
        },
        hover: {
          value: '#5c5cff',
          type: 'color',
          comment: 'Use for borders on hover for secondary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.blurple.600}',
            type: 'color',
            comment: 'Use for borders on hover for secondary buttons',
          },
          name: 'BorderButtonSecondaryHover',
          attributes: {
            category: 'border',
            type: 'button',
            item: 'secondary',
            subitem: 'hover',
          },
          path: ['border', 'button', 'secondary', 'hover'],
        },
        focus: {
          value: '#5c5cff',
          type: 'color',
          comment: 'Use for borders on hover for secondary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.blurple.600}',
            type: 'color',
            comment: 'Use for borders on hover for secondary buttons',
          },
          name: 'BorderButtonSecondaryFocus',
          attributes: {
            category: 'border',
            type: 'button',
            item: 'secondary',
            subitem: 'focus',
          },
          path: ['border', 'button', 'secondary', 'focus'],
        },
        active: {
          value: '#4343c5',
          type: 'color',
          comment: 'Use for borders for active secondary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.blurple.700}',
            type: 'color',
            comment: 'Use for borders for active secondary buttons',
          },
          name: 'BorderButtonSecondaryActive',
          attributes: {
            category: 'border',
            type: 'button',
            item: 'secondary',
            subitem: 'active',
          },
          path: ['border', 'button', 'secondary', 'active'],
        },
        disabled: {
          value: '#dce4f2',
          type: 'color',
          comment: 'Use for borders for disabled secondary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.neutral.200}',
            type: 'color',
            comment: 'Use for borders for disabled secondary buttons',
          },
          name: 'BorderButtonSecondaryDisabled',
          attributes: {
            category: 'border',
            type: 'button',
            item: 'secondary',
            subitem: 'disabled',
          },
          path: ['border', 'button', 'secondary', 'disabled'],
        },
      },
      tertiary: {
        hover: {
          value: '#eeeeff',
          type: 'color',
          comment: 'Use for borders on hover for tertiary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.blurple.100}',
            type: 'color',
            comment: 'Use for borders on hover for tertiary buttons',
          },
          name: 'BorderButtonTertiaryHover',
          attributes: {
            category: 'border',
            type: 'button',
            item: 'tertiary',
            subitem: 'hover',
          },
          path: ['border', 'button', 'tertiary', 'hover'],
        },
        active: {
          value: '#e0e0ff',
          type: 'color',
          comment: 'Use for borders for active tertiary buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.blurple.200}',
            type: 'color',
            comment: 'Use for borders for active tertiary buttons',
          },
          name: 'BorderButtonTertiaryActive',
          attributes: {
            category: 'border',
            type: 'button',
            item: 'tertiary',
            subitem: 'active',
          },
          path: ['border', 'button', 'tertiary', 'active'],
        },
      },
      destructive: {
        default: {
          value: '#dd2270',
          type: 'color',
          comment: 'Use for borders on destructive buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.pink.600}',
            type: 'color',
            comment: 'Use for borders on destructive buttons',
          },
          name: 'BorderButtonDestructiveDefault',
          attributes: {
            category: 'border',
            type: 'button',
            item: 'destructive',
            subitem: 'default',
          },
          path: ['border', 'button', 'destructive', 'default'],
        },
        hover: {
          value: '#a6084a',
          type: 'color',
          comment: 'Use for borders on hover for destructive buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.pink.700}',
            type: 'color',
            comment: 'Use for borders on hover for destructive buttons',
          },
          name: 'BorderButtonDestructiveHover',
          attributes: {
            category: 'border',
            type: 'button',
            item: 'destructive',
            subitem: 'hover',
          },
          path: ['border', 'button', 'destructive', 'hover'],
        },
        focus: {
          value: '#dd2270',
          type: 'color',
          comment: 'Use for borders on hover for destructive buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.pink.600}',
            type: 'color',
            comment: 'Use for borders on hover for destructive buttons',
          },
          name: 'BorderButtonDestructiveFocus',
          attributes: {
            category: 'border',
            type: 'button',
            item: 'destructive',
            subitem: 'focus',
          },
          path: ['border', 'button', 'destructive', 'focus'],
        },
        active: {
          value: '#dd2270',
          type: 'color',
          comment: 'Use for borders for active destructive buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.pink.600}',
            type: 'color',
            comment: 'Use for borders for active destructive buttons',
          },
          name: 'BorderButtonDestructiveActive',
          attributes: {
            category: 'border',
            type: 'button',
            item: 'destructive',
            subitem: 'active',
          },
          path: ['border', 'button', 'destructive', 'active'],
        },
        disabled: {
          value: '#bdc7db',
          type: 'color',
          comment: 'Use for borders for disabled destructive buttons',
          filePath: 'tokens/color.json',
          isSource: true,
          original: {
            value: '{color.neutral.300}',
            type: 'color',
            comment: 'Use for borders for disabled destructive buttons',
          },
          name: 'BorderButtonDestructiveDisabled',
          attributes: {
            category: 'border',
            type: 'button',
            item: 'destructive',
            subitem: 'disabled',
          },
          path: ['border', 'button', 'destructive', 'disabled'],
        },
      },
    },
    boolean: {
      default: {
        value: '#8492ae',
        type: 'color',
        comment: 'Use on outline of toggle in default state',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.neutral.500}',
          type: 'color',
          comment: 'Use on outline of toggle in default state',
        },
        name: 'BorderBooleanDefault',
        attributes: {
          category: 'border',
          type: 'boolean',
          item: 'default',
        },
        path: ['border', 'boolean', 'default'],
      },
      hover: {
        value: '#425678',
        type: 'color',
        comment: 'Use on outline of toggle in hover state',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.neutral.700}',
          type: 'color',
          comment: 'Use on outline of toggle in hover state',
        },
        name: 'BorderBooleanHover',
        attributes: {
          category: 'border',
          type: 'boolean',
          item: 'hover',
        },
        path: ['border', 'boolean', 'hover'],
      },
      active: {
        value: '#0072d6',
        type: 'color',
        comment:
          'Use on border of active button or fill of checkbox, radio, etc.',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.blue.600}',
          type: 'color',
          comment:
            'Use on border of active button or fill of checkbox, radio, etc.',
        },
        name: 'BorderBooleanActive',
        attributes: {
          category: 'border',
          type: 'boolean',
          item: 'active',
        },
        path: ['border', 'boolean', 'active'],
      },
      'active-hover': {
        value: '#0056a1',
        type: 'color',
        comment:
          'Use on border of active toggle button or fill of checkbox, radio, etc.',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.blue.700}',
          type: 'color',
          comment:
            'Use on border of active toggle button or fill of checkbox, radio, etc.',
        },
        name: 'BorderBooleanActiveHover',
        attributes: {
          category: 'border',
          type: 'boolean',
          item: 'active-hover',
        },
        path: ['border', 'boolean', 'active-hover'],
      },
      'error-hover': {
        value: '#a6084a',
        type: 'color',
        comment: 'Use on hovered error state of checkbox, radio, etc.',
        filePath: 'tokens/color.json',
        isSource: true,
        original: {
          value: '{color.pink.700}',
          type: 'color',
          comment: 'Use on hovered error state of checkbox, radio, etc.',
        },
        name: 'BorderBooleanErrorHover',
        attributes: {
          category: 'border',
          type: 'boolean',
          item: 'error-hover',
        },
        path: ['border', 'boolean', 'error-hover'],
      },
    },
  },
  gradient: {
    blurple: {
      value: 'linear-gradient(90deg, #5c5cff 0%, #7D52FF 100%)',
      type: 'color',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: 'linear-gradient(90deg, {color.blurple.600} 0%, #7D52FF 100%)',
        type: 'color',
      },
      name: 'GradientBlurple',
      attributes: {
        category: 'gradient',
        type: 'blurple',
      },
      path: ['gradient', 'blurple'],
    },
    pink: {
      value: 'linear-gradient(90deg, #dd2270 0%, #ff4592 100%)',
      type: 'color',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value:
          'linear-gradient(90deg, {color.pink.600} 0%, {color.pink.500} 100%)',
        type: 'color',
      },
      name: 'GradientPink',
      attributes: {
        category: 'gradient',
        type: 'pink',
      },
      path: ['gradient', 'pink'],
    },
    green: {
      value: 'linear-gradient(90deg, #108484 0%, #00c2c2 100%)',
      type: 'color',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value:
          'linear-gradient(90deg, {color.green.600} 0%, {color.green.400} 100%)',
        type: 'color',
      },
      name: 'GradientGreen',
      attributes: {
        category: 'gradient',
        type: 'green',
      },
      path: ['gradient', 'green'],
    },
    blue: {
      value: 'linear-gradient(90deg, #0072d6 0%, #1492ff 100%)',
      type: 'color',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value:
          'linear-gradient(90deg, {color.blue.600} 0%, {color.blue.500} 100%)',
        type: 'color',
      },
      name: 'GradientBlue',
      attributes: {
        category: 'gradient',
        type: 'blue',
      },
      path: ['gradient', 'blue'],
    },
    neutral: {
      value: 'linear-gradient(90deg, #0b1a38 0%, #425678 100%)',
      type: 'color',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value:
          'linear-gradient(90deg, {color.neutral.900} 0%, {color.neutral.700} 100%)',
        type: 'color',
      },
      name: 'GradientNeutral',
      attributes: {
        category: 'gradient',
        type: 'neutral',
      },
      path: ['gradient', 'neutral'],
    },
    'pink-blurple': {
      value: 'linear-gradient(90deg, #ff4592 0%, #8960FF 100%)',
      type: 'color',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: 'linear-gradient(90deg, {color.pink.500} 0%, #8960FF 100%)',
        type: 'color',
      },
      name: 'GradientPinkBlurple',
      attributes: {
        category: 'gradient',
        type: 'pink-blurple',
      },
      path: ['gradient', 'pink-blurple'],
    },
  },
  elevation: {
    100: {
      value: '0px  2px  6px rgba(11, 26, 56, 0.10)',
      comment: 'Shadow used for surfaces of resting cards & containers',
      type: 'boxShadow',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '0px  2px  6px rgba(11, 26, 56, 0.10)',
        comment: 'Shadow used for surfaces of resting cards & containers',
        type: 'boxShadow',
      },
      name: 'Elevation100',
      attributes: {
        category: 'elevation',
        type: '100',
      },
      path: ['elevation', '100'],
    },
    200: {
      value: '0px  4px  16px rgba(11, 26, 56, 0.16)',
      comment:
        'Shadow used for interactive cards (hover state) & fixed elements',
      type: 'boxShadow',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '0px  4px  16px rgba(11, 26, 56, 0.16)',
        comment:
          'Shadow used for interactive cards (hover state) & fixed elements',
        type: 'boxShadow',
      },
      name: 'Elevation200',
      attributes: {
        category: 'elevation',
        type: '200',
      },
      path: ['elevation', '200'],
    },
    300: {
      value: '0px  12px  34px rgba(11, 26, 56, 0.2)',
      comment: 'Shadow used for menus',
      type: 'boxShadow',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '0px  12px  34px rgba(11, 26, 56, 0.2)',
        comment: 'Shadow used for menus',
        type: 'boxShadow',
      },
      name: 'Elevation300',
      attributes: {
        category: 'elevation',
        type: '300',
      },
      path: ['elevation', '300'],
    },
    400: {
      value: '0px  12px  34px rgba(11, 26, 56, 0.35)',
      comment: 'Shadow used for modals & dialogs',
      type: 'boxShadow',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: '0px  12px  34px rgba(11, 26, 56, 0.35)',
        comment: 'Shadow used for modals & dialogs',
        type: 'boxShadow',
      },
      name: 'Elevation400',
      attributes: {
        category: 'elevation',
        type: '400',
      },
      path: ['elevation', '400'],
    },
    basement: {
      value: 'inset 0px  0px  2px  rgba(11, 26, 56, 0.40)',
      comment: 'Inner shadow used for avatars & color swatches',
      type: 'boxShadow',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: 'inset 0px  0px  2px  rgba(11, 26, 56, 0.40)',
        comment: 'Inner shadow used for avatars & color swatches',
        type: 'boxShadow',
      },
      name: 'ElevationBasement',
      attributes: {
        category: 'elevation',
        type: 'basement',
      },
      path: ['elevation', 'basement'],
    },
  },
  overlay: {
    100: {
      value: 'rgba(11, 26, 56, 0.4)',
      type: 'opacity',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: 'rgba(11, 26, 56, 0.4)',
        type: 'opacity',
      },
      name: 'Overlay100',
      attributes: {
        category: 'overlay',
        type: '100',
      },
      path: ['overlay', '100'],
    },
    200: {
      value: 'rgba(11, 26, 56, 0.6)',
      type: 'opacity',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value: 'rgba(11, 26, 56, 0.6)',
        type: 'opacity',
      },
      name: 'Overlay200',
      attributes: {
        category: 'overlay',
        type: '200',
      },
      path: ['overlay', '200'],
    },
    300: {
      value:
        'linear-gradient(180deg, rgba(11, 26, 56, 0) 0%, rgba(11, 26, 56, 0.08) 100%)',
      type: 'opacity',
      filePath: 'tokens/color.json',
      isSource: true,
      original: {
        value:
          'linear-gradient(180deg, rgba(11, 26, 56, 0) 0%, rgba(11, 26, 56, 0.08) 100%)',
        type: 'opacity',
      },
      name: 'Overlay300',
      attributes: {
        category: 'overlay',
        type: '300',
      },
      path: ['overlay', '300'],
    },
  },
  'border-radius': {
    'x-small': {
      value: '4px',
      comment: 'Smallest size available used for sections, buttons, and inputs',
      type: 'borderRadius',
      filePath: 'tokens/size.json',
      isSource: true,
      original: {
        value: '4px',
        comment:
          'Smallest size available used for sections, buttons, and inputs',
        type: 'borderRadius',
      },
      name: 'BorderRadiusXSmall',
      attributes: {
        category: 'border-radius',
        type: 'x-small',
      },
      path: ['border-radius', 'x-small'],
    },
    small: {
      value: '6px',
      comment: 'Default radius used for sections, buttons, and inputs',
      type: 'borderRadius',
      filePath: 'tokens/size.json',
      isSource: true,
      original: {
        value: '6px',
        comment: 'Default radius used for sections, buttons, and inputs',
        type: 'borderRadius',
      },
      name: 'BorderRadiusSmall',
      attributes: {
        category: 'border-radius',
        type: 'small',
      },
      path: ['border-radius', 'small'],
    },
    large: {
      value: '50%',
      comment: 'Default radius for avatars or tags',
      type: 'borderRadius',
      filePath: 'tokens/size.json',
      isSource: true,
      original: {
        value: '50%',
        comment: 'Default radius for avatars or tags',
        type: 'borderRadius',
      },
      name: 'BorderRadiusLarge',
      attributes: {
        category: 'border-radius',
        type: 'large',
      },
      path: ['border-radius', 'large'],
    },
  },
  'border-width': {
    'x-small': {
      value: '1px',
      comment: 'Default border for dividers and inputs',
      type: 'borderWidth',
      filePath: 'tokens/size.json',
      isSource: true,
      original: {
        value: '1px',
        comment: 'Default border for dividers and inputs',
        type: 'borderWidth',
      },
      name: 'BorderWidthXSmall',
      attributes: {
        category: 'border-width',
        type: 'x-small',
      },
      path: ['border-width', 'x-small'],
    },
    small: {
      value: '2px',
      comment: 'Default border used for focus state of elements',
      type: 'borderWidth',
      filePath: 'tokens/size.json',
      isSource: true,
      original: {
        value: '2px',
        comment: 'Default border used for focus state of elements',
        type: 'borderWidth',
      },
      name: 'BorderWidthSmall',
      attributes: {
        category: 'border-width',
        type: 'small',
      },
      path: ['border-width', 'small'],
    },
  },
  content: {
    modal: {
      width: {
        value: '608px',
        comment: 'Default width for modals',
        type: 'modalWidth',
        filePath: 'tokens/size.json',
        isSource: true,
        original: {
          value: '608px',
          comment: 'Default width for modals',
          type: 'modalWidth',
        },
        name: 'ContentModalWidth',
        attributes: {
          category: 'content',
          type: 'modal',
          item: 'width',
        },
        path: ['content', 'modal', 'width'],
      },
    },
  },
  icon: {
    size: {
      'x-small': {
        value: '12px',
        comment: 'Smallest size available, not to be used alone or for actions',
        type: 'sizing',
        filePath: 'tokens/size.json',
        isSource: true,
        original: {
          value: '12px',
          comment:
            'Smallest size available, not to be used alone or for actions',
          type: 'sizing',
        },
        name: 'IconSizeXSmall',
        attributes: {
          category: 'icon',
          type: 'size',
          item: 'x-small',
        },
        path: ['icon', 'size', 'x-small'],
      },
      small: {
        value: '14px',
        comment: 'Small size available, not to be used alone or for actions',
        type: 'sizing',
        filePath: 'tokens/size.json',
        isSource: true,
        original: {
          value: '14px',
          comment: 'Small size available, not to be used alone or for actions',
          type: 'sizing',
        },
        name: 'IconSizeSmall',
        attributes: {
          category: 'icon',
          type: 'size',
          item: 'small',
        },
        path: ['icon', 'size', 'small'],
      },
      regular: {
        value: '16px',
        comment: 'Default size for icons, most common in our system',
        type: 'sizing',
        filePath: 'tokens/size.json',
        isSource: true,
        original: {
          value: '16px',
          comment: 'Default size for icons, most common in our system',
          type: 'sizing',
        },
        name: 'IconSizeRegular',
        attributes: {
          category: 'icon',
          type: 'size',
          item: 'regular',
        },
        path: ['icon', 'size', 'regular'],
      },
      large: {
        value: '24px',
        comment: 'Used for avatars',
        type: 'sizing',
        filePath: 'tokens/size.json',
        isSource: true,
        original: {
          value: '24px',
          comment: 'Used for avatars',
          type: 'sizing',
        },
        name: 'IconSizeLarge',
        attributes: {
          category: 'icon',
          type: 'size',
          item: 'large',
        },
        path: ['icon', 'size', 'large'],
      },
      'x-large': {
        value: '32px',
        comment: 'Used rarely for decorative, product specific icons',
        type: 'sizing',
        filePath: 'tokens/size.json',
        isSource: true,
        original: {
          value: '32px',
          comment: 'Used rarely for decorative, product specific icons',
          type: 'sizing',
        },
        name: 'IconSizeXLarge',
        attributes: {
          category: 'icon',
          type: 'size',
          item: 'x-large',
        },
        path: ['icon', 'size', 'x-large'],
      },
    },
  },
  spacing: {
    'x-small': {
      value: '4px',
      comment: 'Use for spacing within components, and when space is limited',
      filePath: 'tokens/size.json',
      isSource: true,
      original: {
        value: '4px',
        comment: 'Use for spacing within components, and when space is limited',
      },
      name: 'SpacingXSmall',
      attributes: {
        category: 'spacing',
        type: 'x-small',
      },
      path: ['spacing', 'x-small'],
    },
    small: {
      value: '8px',
      comment: 'Use for separating related elements',
      filePath: 'tokens/size.json',
      isSource: true,
      original: {
        value: '8px',
        comment: 'Use for separating related elements',
      },
      name: 'SpacingSmall',
      attributes: {
        category: 'spacing',
        type: 'small',
      },
      path: ['spacing', 'small'],
    },
    regular: {
      value: '16px',
      comment: 'Default, use for space between elements',
      filePath: 'tokens/size.json',
      isSource: true,
      original: {
        value: '16px',
        comment: 'Default, use for space between elements',
      },
      name: 'SpacingRegular',
      attributes: {
        category: 'spacing',
        type: 'regular',
      },
      path: ['spacing', 'regular'],
    },
    large: {
      value: '24px',
      comment: 'Use for space between content containers',
      filePath: 'tokens/size.json',
      isSource: true,
      original: {
        value: '24px',
        comment: 'Use for space between content containers',
      },
      name: 'SpacingLarge',
      attributes: {
        category: 'spacing',
        type: 'large',
      },
      path: ['spacing', 'large'],
    },
    'x-large': {
      value: '32px',
      comment:
        'Use for space between components and content sections within a container',
      filePath: 'tokens/size.json',
      isSource: true,
      original: {
        value: '32px',
        comment:
          'Use for space between components and content sections within a container',
      },
      name: 'SpacingXLarge',
      attributes: {
        category: 'spacing',
        type: 'x-large',
      },
      path: ['spacing', 'x-large'],
    },
    'x-x-large': {
      value: '48px',
      comment:
        'Use for space between large-scale components and content sections within a container, as needed',
      filePath: 'tokens/size.json',
      isSource: true,
      original: {
        value: '48px',
        comment:
          'Use for space between large-scale components and content sections within a container, as needed',
      },
      name: 'SpacingXXLarge',
      attributes: {
        category: 'spacing',
        type: 'x-x-large',
      },
      path: ['spacing', 'x-x-large'],
    },
    'x-x-x-large': {
      value: '64px',
      comment: 'Use for space between loosely related sections, as needed',
      filePath: 'tokens/size.json',
      isSource: true,
      original: {
        value: '64px',
        comment: 'Use for space between loosely related sections, as needed',
      },
      name: 'SpacingXXXLarge',
      attributes: {
        category: 'spacing',
        type: 'x-x-x-large',
      },
      path: ['spacing', 'x-x-x-large'],
    },
  },
  'z-index': {
    100: {
      value: '100',
      comment:
        'Use for tooltips and other elements that need to be above base elements',
      filePath: 'tokens/size.json',
      isSource: true,
      original: {
        value: '100',
        comment:
          'Use for tooltips and other elements that need to be above base elements',
      },
      name: 'ZIndex100',
      attributes: {
        category: 'z-index',
        type: '100',
      },
      path: ['z-index', '100'],
    },
    200: {
      value: '200',
      comment:
        'Use for modals and other elements that need to be above whole screens',
      filePath: 'tokens/size.json',
      isSource: true,
      original: {
        value: '200',
        comment:
          'Use for modals and other elements that need to be above whole screens',
      },
      name: 'ZIndex200',
      attributes: {
        category: 'z-index',
        type: '200',
      },
      path: ['z-index', '200'],
    },
    300: {
      value: '300',
      comment:
        'Use for drawers and other elements that need to be above anything else, including modals',
      filePath: 'tokens/size.json',
      isSource: true,
      original: {
        value: '300',
        comment:
          'Use for drawers and other elements that need to be above anything else, including modals',
      },
      name: 'ZIndex300',
      attributes: {
        category: 'z-index',
        type: '300',
      },
      path: ['z-index', '300'],
    },
  },
  font: {
    family: {
      base: {
        value: "'Mulish', 'Helvetica Neue', Helvetica, Arial, sans-serif",
        comment: 'Our default brand font used at Appcues',
        name: 'FontFamilyBase',
        type: 'fontFamilies',
        filePath: 'tokens/typography.json',
        isSource: true,
        original: {
          value: "'Mulish', 'Helvetica Neue', Helvetica, Arial, sans-serif",
          comment: 'Our default brand font used at Appcues',
          name: 'Mulish',
          type: 'fontFamilies',
        },
        attributes: {
          category: 'font',
          type: 'family',
          item: 'base',
        },
        path: ['font', 'family', 'base'],
      },
      mono: {
        value: "'Source Code Pro' , 'Consolas', 'Monaco', monospace",
        comment: 'Font family for showing code snippets & examples',
        name: 'FontFamilyMono',
        type: 'fontFamilies',
        filePath: 'tokens/typography.json',
        isSource: true,
        original: {
          value: "'Source Code Pro' , 'Consolas', 'Monaco', monospace",
          comment: 'Font family for showing code snippets & examples',
          name: 'Source Code Pro',
          type: 'fontFamilies',
        },
        attributes: {
          category: 'font',
          type: 'family',
          item: 'mono',
        },
        path: ['font', 'family', 'mono'],
      },
    },
    size: {
      small: {
        value: '0.75rem',
        calculated: '12px',
        comment: 'Smallest font size, used for captions',
        type: 'fontSizes',
        filePath: 'tokens/typography.json',
        isSource: true,
        original: {
          value: '0.75rem',
          calculated: '12px',
          comment: 'Smallest font size, used for captions',
          type: 'fontSizes',
        },
        name: 'FontSizeSmall',
        attributes: {
          category: 'font',
          type: 'size',
          item: 'small',
        },
        path: ['font', 'size', 'small'],
      },
      regular: {
        value: '0.875rem',
        calculated: '14px',
        comment: 'Default body font size',
        type: 'fontSizes',
        filePath: 'tokens/typography.json',
        isSource: true,
        original: {
          value: '0.875rem',
          calculated: '14px',
          comment: 'Default body font size',
          type: 'fontSizes',
        },
        name: 'FontSizeRegular',
        attributes: {
          category: 'font',
          type: 'size',
          item: 'regular',
        },
        path: ['font', 'size', 'regular'],
      },
      medium: {
        value: '1rem',
        calculated: '16px',
        comment: 'Medium heading font size',
        type: 'fontSizes',
        filePath: 'tokens/typography.json',
        isSource: true,
        original: {
          value: '1rem',
          calculated: '16px',
          comment: 'Medium heading font size',
          type: 'fontSizes',
        },
        name: 'FontSizeMedium',
        attributes: {
          category: 'font',
          type: 'size',
          item: 'medium',
        },
        path: ['font', 'size', 'medium'],
      },
      large: {
        value: '1.25rem',
        calculated: '20px',
        comment: 'Used for headings',
        type: 'fontSizes',
        filePath: 'tokens/typography.json',
        isSource: true,
        original: {
          value: '1.25rem',
          calculated: '20px',
          comment: 'Used for headings',
          type: 'fontSizes',
        },
        name: 'FontSizeLarge',
        attributes: {
          category: 'font',
          type: 'size',
          item: 'large',
        },
        path: ['font', 'size', 'large'],
      },
      'x-large': {
        value: '1.5rem',
        calculated: '24px',
        comment: 'Used for headings',
        type: 'fontSizes',
        filePath: 'tokens/typography.json',
        isSource: true,
        original: {
          value: '1.5rem',
          calculated: '24px',
          comment: 'Used for headings',
          type: 'fontSizes',
        },
        name: 'FontSizeXLarge',
        attributes: {
          category: 'font',
          type: 'size',
          item: 'x-large',
        },
        path: ['font', 'size', 'x-large'],
      },
      'x-x-large': {
        value: '2rem',
        calculated: '32px',
        comment: 'Used for headings',
        type: 'fontSizes',
        filePath: 'tokens/typography.json',
        isSource: true,
        original: {
          value: '2rem',
          calculated: '32px',
          comment: 'Used for headings',
          type: 'fontSizes',
        },
        name: 'FontSizeXXLarge',
        attributes: {
          category: 'font',
          type: 'size',
          item: 'x-x-large',
        },
        path: ['font', 'size', 'x-x-large'],
      },
    },
    weight: {
      regular: {
        value: 400,
        comment: 'Default font weight',
        type: 'fontWeights',
        filePath: 'tokens/typography.json',
        isSource: true,
        original: {
          value: 400,
          comment: 'Default font weight',
          type: 'fontWeights',
        },
        name: 'FontWeightRegular',
        attributes: {
          category: 'font',
          type: 'weight',
          item: 'regular',
        },
        path: ['font', 'weight', 'regular'],
      },
      semibold: {
        value: 600,
        comment: 'Used for headings & button labels',
        type: 'fontWeights',
        filePath: 'tokens/typography.json',
        isSource: true,
        original: {
          value: 600,
          comment: 'Used for headings & button labels',
          type: 'fontWeights',
        },
        name: 'FontWeightSemibold',
        attributes: {
          category: 'font',
          type: 'weight',
          item: 'semibold',
        },
        path: ['font', 'weight', 'semibold'],
      },
      bold: {
        value: 700,
        comment: 'Used for headers, headings & links',
        type: 'fontWeights',
        filePath: 'tokens/typography.json',
        isSource: true,
        original: {
          value: 700,
          comment: 'Used for headers, headings & links',
          type: 'fontWeights',
        },
        name: 'FontWeightBold',
        attributes: {
          category: 'font',
          type: 'weight',
          item: 'bold',
        },
        path: ['font', 'weight', 'bold'],
      },
    },
  },
  'letter-spacing': {
    dense: {
      value: '-0.025rem',
      comment: 'Used only for largest headings',
      type: 'letterSpacing',
      filePath: 'tokens/typography.json',
      isSource: true,
      original: {
        value: '-0.025rem',
        comment: 'Used only for largest headings',
        type: 'letterSpacing',
      },
      name: 'LetterSpacingDense',
      attributes: {
        category: 'letter-spacing',
        type: 'dense',
      },
      path: ['letter-spacing', 'dense'],
    },
    regular: {
      value: 'auto',
      comment: 'Used for 200, 300 level headings, & all body text',
      type: 'letterSpacing',
      filePath: 'tokens/typography.json',
      isSource: true,
      original: {
        value: 'auto',
        comment: 'Used for 200, 300 level headings, & all body text',
        type: 'letterSpacing',
      },
      name: 'LetterSpacingRegular',
      attributes: {
        category: 'letter-spacing',
        type: 'regular',
      },
      path: ['letter-spacing', 'regular'],
    },
    spacious: {
      value: '.05rem',
      comment: 'Used for all caps labels',
      type: 'letterSpacing',
      filePath: 'tokens/typography.json',
      isSource: true,
      original: {
        value: '.05rem',
        comment: 'Used for all caps labels',
        type: 'letterSpacing',
      },
      name: 'LetterSpacingSpacious',
      attributes: {
        category: 'letter-spacing',
        type: 'spacious',
      },
      path: ['letter-spacing', 'spacious'],
    },
  },
  'line-height': {
    'x-small': {
      value: 1.5,
      comment: 'Used for 100 & 200 level headings',
      type: 'lineHeights',
      filePath: 'tokens/typography.json',
      isSource: true,
      original: {
        value: 1.5,
        comment: 'Used for 100 & 200 level headings',
        type: 'lineHeights',
      },
      name: 'LineHeightXSmall',
      attributes: {
        category: 'line-height',
        type: 'x-small',
      },
      path: ['line-height', 'x-small'],
    },
    small: {
      value: 1.6,
      comment: 'Used for 300 level headings',
      type: 'lineHeights',
      filePath: 'tokens/typography.json',
      isSource: true,
      original: {
        value: 1.6,
        comment: 'Used for 300 level headings',
        type: 'lineHeights',
      },
      name: 'LineHeightSmall',
      attributes: {
        category: 'line-height',
        type: 'small',
      },
      path: ['line-height', 'small'],
    },
    regular: {
      value: 1.7,
      comment: 'Used for default body text, button labels, & code',
      type: 'lineHeights',
      filePath: 'tokens/typography.json',
      isSource: true,
      original: {
        value: 1.7,
        comment: 'Used for default body text, button labels, & code',
        type: 'lineHeights',
      },
      name: 'LineHeightRegular',
      attributes: {
        category: 'line-height',
        type: 'regular',
      },
      path: ['line-height', 'regular'],
    },
    large: {
      value: 2,
      comment: 'Used for smallest text sizes & accent text',
      type: 'lineHeights',
      filePath: 'tokens/typography.json',
      isSource: true,
      original: {
        value: 2,
        comment: 'Used for smallest text sizes & accent text',
        type: 'lineHeights',
      },
      name: 'LineHeightLarge',
      attributes: {
        category: 'line-height',
        type: 'large',
      },
      path: ['line-height', 'large'],
    },
  },
};
