import { createCollectionActions } from 'next/lib/collections';

export const TYPE = 'translations';

export const TRANSLATIONS_REQUESTED = 'TRANSLATIONS_REQUESTED';
export const TRANSLATIONS_DOWNLOADED = 'TRANSLATIONS_DOWNLOADED';
export const REMOVE_TRANSLATIONS = 'REMOVE_TRANSLATIONS';
export const TRANSLATIONS_REMOVED = 'TRANSLATIONS_REMOVED';
export const TRANSLATION_UPLOADED = 'TRANSLATION_UPLOADED';
export const TRANSLATION_UPLOADED_SUCCESS = 'TRANSLATION_UPLOADED_SUCCESS';
export const TRANSLATION_DELETED = 'TRANSLATION_DELETED';
export const TRANSLATION_DELETED_SUCCESS = 'TRANSLATION_DELETED_SUCCESS';
export const UPDATE_FLOW_NAME_TRANSLATION = 'UPDATE_FLOW_NAME_TRANSLATION';

export const { read, resolve, reject, patterns, insert, remove } =
  createCollectionActions(TYPE);

export const requestTranslations = payload => ({
  type: TRANSLATIONS_REQUESTED,
  payload,
});

export const translationsDownloaded = id => ({
  type: TRANSLATIONS_DOWNLOADED,
  payload: { id },
});

export const removeTranslations = id => ({
  type: REMOVE_TRANSLATIONS,
  payload: { id },
});

export const translationsRemoved = id => ({
  type: TRANSLATIONS_REMOVED,
  payload: { id },
});

export const uploadTranslation = payload => ({
  type: TRANSLATION_UPLOADED,
  payload,
});

export const uploadTranslationSuccess = payload => ({
  type: TRANSLATION_UPLOADED_SUCCESS,
  payload,
});

export const deleteTranslation = ({ flowId, translationId }) => ({
  type: TRANSLATION_DELETED,
  payload: { flowId, translationId },
});

export const deleteTranslationSuccess = payload => ({
  type: TRANSLATION_DELETED_SUCCESS,
  payload,
});

export const updateFlowNameTranslation = payload => ({
  type: UPDATE_FLOW_NAME_TRANSLATION,
  payload,
});
