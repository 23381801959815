import PropTypes from 'prop-types';
import pick from 'lodash.pick';

import { StepShape } from 'next/entities/experiences';
import { ContentStateShape } from 'next/lib/shapes';

/**
 * Full shape of pin data model
 */
const Shape = {
  appId: PropTypes.string,
  archivedAt: PropTypes.number,
  archivedBy: PropTypes.string,
  createdBy: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  previewUrl: PropTypes.string,
  published: PropTypes.bool,
  publishedAt: PropTypes.string,
  publishedBy: PropTypes.string,
  publishedAndDirty: PropTypes.bool,
  state: ContentStateShape,
  steps: PropTypes.arrayOf(StepShape),
  tagIds: PropTypes.arrayOf(PropTypes.string),
  theme: PropTypes.objectOf(
    PropTypes.shape({
      themeId: PropTypes.string,
      accountId: PropTypes.string,
    })
  ),
  traits: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.oneOf(['journey']),
  unpublishedAt: PropTypes.string,
  unpublishedBy: PropTypes.string,
  updatedAt: PropTypes.number,
  updatedBy: PropTypes.string,
};

/**
 * Allowed fields for storage
 */
const allowed = [
  'createdAt',
  'createdBy',
  'id',
  'name',
  'previewUrl',
  'published',
  'publishedAt',
  'publishedAndDirty',
  'state',
  'steps',
  'tagIds',
  'updatedAt',
  'updatedBy',
];

/**
 * Pin parsing function
 *
 * @param {Object<keyof Pin>} pin - Object with pin fields
 * @return {Object<keyof Pin>} Object with parsed pin fields
 */
export const parse = pin => pick(pin, allowed);

export default PropTypes.shape(parse(Shape));
