import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Text } from '@appcues/component-library';
import {
  DateRangePicker,
  getEndOfToday,
  useDateRange,
  FULL_RANGE_OPTIONS,
} from '@studio/legacy-components';
import { BoundedDateRangePicker } from 'next/components/BoundedDateRange';
import Input from 'components/Common/Forms/Input';
import RadioButton from 'components/Common/Forms/RadioButton';
import {
  exportModalMessages,
  exportChecklistModalMessages,
} from 'constants/modalMessages';

// All time option for date range picker
const ALL_TIME = 'all-time';

/**
 * Date range options for export with all time
 *
 * @type {Option[]} Limited date range options
 */
const EXPORT_RANGE_OPTIONS = [
  { label: 'All time', value: ALL_TIME },
  ...FULL_RANGE_OPTIONS,
];

const ExportInputFields = ({
  createdAt,
  exportMode,
  exportType,
  includeFormResponsesOption,
  email,
  handleEmailChange,
  handleEnterPress,
  handleExportModeChange,
  onDateChange,
}) => {
  const exportMessage =
    exportType === 'checklist'
      ? exportChecklistModalMessages
      : exportModalMessages;

  // Create date for when the experience was created
  const created = useMemo(
    () => moment(createdAt).startOf('day').toDate(),
    [createdAt]
  );

  // Force earliest start date to be created at date
  const calculate = range =>
    range === ALL_TIME
      ? { range: ALL_TIME, start: created, end: getEndOfToday() }
      : null;

  const [dates, setDates] = useDateRange(
    EXPORT_RANGE_OPTIONS,
    { range: ALL_TIME },
    calculate
  );

  const handleDateChange = ({ range, start, end }) => {
    setDates({ range, start, end });
    onDateChange({ range, start, end });
  };

  /**
   * NOTE: Due to how this modal is implemented, this ensures that the parent
   *       modal component has the complete calculated date
   */
  useEffect(() => {
    onDateChange(dates);
  }, []);

  return (
    <InputFieldsWrapper>
      <ExportHeading className="export-label" for="date-range-select">
        Export Options
      </ExportHeading>
      <RadioButton
        checked={exportMode === 'basic'}
        label="Basic"
        name="basic"
        onChange={() => handleExportModeChange('basic')}
        optionDescription={exportMessage.basic}
        value="basic"
      />
      <RadioButton
        checked={exportMode === 'full'}
        label="Full"
        name="full"
        onChange={() => handleExportModeChange('full')}
        optionDescription={exportMessage.full}
        value="full"
      />
      {includeFormResponsesOption && exportMessage.survey && (
        <RadioButton
          checked={exportMode === 'survey'}
          label="Survey"
          name="survey"
          onChange={() => handleExportModeChange('survey')}
          optionDescription={exportMessage.survey}
          value="survey"
        />
      )}

      <FieldWrapper>
        <BoundedDateRangePicker
          calculate={calculate}
          onChange={handleDateChange}
          options={EXPORT_RANGE_OPTIONS}
          portal
          stacked
          value={dates}
        />

        <ExportHeading className="export-label" for="email-input">
          <strong>Email Address</strong> &#8212; we&#8217;ll send your CSV here
        </ExportHeading>
        <EmailInputWrapper>
          <Input
            name="email-input"
            onKeyPress={handleEnterPress}
            onChange={handleEmailChange}
            type="text"
            value={email}
          />
        </EmailInputWrapper>
      </FieldWrapper>
    </InputFieldsWrapper>
  );
};

const InputFieldsWrapper = styled.div`
  margin: 18px 0;
`;

const EmailInputWrapper = styled.div`
  display: flex;

  input {
    background-color: var(--white);
    border: 1px solid;
    border-color: var(--input);
    border-radius: 6px;
    color: var(--input-text);
    font-size: var(--regular);
    font-weight: var(--normal);
    height: 40px;
    outline: none;
    padding: 10px 14px;
    width: 100%;
  }
`;

const FieldWrapper = styled.div`
  margin-top: 13px;

  ${DateRangePicker} {
    margin-bottom: 16px;
  }
`;

const ExportHeading = styled(Text)`
  font-weight: bold;
  display: block;
  margin-bottom: 16px;
`;

export default ExportInputFields;
