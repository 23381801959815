import { createSelector } from 'reselect';
import { createEntityReducer } from 'next/lib/collections';
import { format, isAfter, isBefore } from 'next/lib/date';
import { readCreators } from 'next/lib/selectors';

import { TYPE } from './actions';

export default createEntityReducer(TYPE);

export const selectExperienceVersions = state => state[TYPE];

/**
 * Select versions for an experience by start time
 *
 * @param {State} state - Redux state
 * @param {string} startTime - Timestamp for beginning of range
 * @return {Version[]} List of versions for the experience
 */
export const selectExperienceVersionsByStart = createSelector(
  selectExperienceVersions,
  (state, meta) => meta,
  (versions = {}, { id, startTime, endTime }) => {
    const experienceVersions = versions[id];
    return experienceVersions
      ? Object.values(experienceVersions)
          .filter(({ version }) => {
            const date = format(Number(version), 'YYYY-MM-DD');
            return (
              date === startTime ||
              date === endTime ||
              (isAfter(date, startTime) && isBefore(date, endTime))
            );
          })
          .reduce((acc, version) => {
            const date = format(Number(version.version), 'YYYY-MM-DD');

            return acc[date]
              ? { ...acc, [date]: [...acc[date], version] }
              : { ...acc, [date]: [version] };
          }, {})
      : [];
  }
);

export const selectExperienceHistory = createSelector(
  selectExperienceVersions,
  readCreators,
  (state, meta) => meta,
  (versions = {}, { data: users = {} } = {}, id) => {
    const experienceVersions = versions[id] ? Object.values(versions[id]) : [];

    // Versions are ordered newest -> oldest (so index - 1 == newer version, index + 1 == older version)
    return experienceVersions.flatMap((version, index) => {
      // If there's a gap between when this version was unpublished and when the newer version was published, then log as unpublished.
      // There's also a strange case where the current published version has an incorrect unpublishedAt value,
      // so checking that the version has been unpublished after it's been created is a reasonable sanity check.
      const isUnpublish =
        version.unpublishedAt !== experienceVersions[index - 1]?.version &&
        version.unpublishedAt > version.publishedAt;
      // If this version was published at the exact same time the older version was unpublished, it's pushing changes,
      // otherwise it's a publish (including the initial version).
      const isPushingChanges =
        experienceVersions[index + 1]?.unpublishedAt === version.publishedAt;

      return [
        ...(isUnpublish
          ? [
              {
                updatedAt: version.unpublishedAt,
                user: users[version.unpublishedBy] || {},
                status: 'Unpublished',
              },
            ]
          : []),
        {
          // TODO: the updatedAt changes on unpublish, which means we show the wrong update date. The publish date is more correct (See a/b above)
          updatedAt: version.publishedAt,
          user: users[version.updatedBy] || {},
          status: isPushingChanges ? 'Pushed changes' : 'Published',
        },
      ];
    });
  }
);
