import { all, call, put, getContext, takeEvery } from 'redux-saga/effects';
import { takeEveryPromised } from 'next/lib/effects';
import toast from 'next/lib/toast';
import { replacePagePatternFor } from 'next/entities/page';
import { downloadFile } from 'utils/filesystem';
import {
  reject,
  resolve,
  insert,
  remove,
  translationsDownloaded,
  translationsRemoved,
  uploadTranslationSuccess,
  deleteTranslationSuccess,
  TRANSLATIONS_REQUESTED,
  REMOVE_TRANSLATIONS,
  TRANSLATION_UPLOADED,
  TRANSLATION_UPLOADED_SUCCESS,
  TRANSLATION_DELETED,
  TRANSLATION_DELETED_SUCCESS,
  UPDATE_FLOW_NAME_TRANSLATION,
} from './actions';

export function* createTranslation({
  payload: { flowId, localeId, translation },
}) {
  try {
    const api = yield getContext('api');
    const response = yield call(
      api.uploadTranslation,
      flowId,
      localeId,
      translation
    );
    yield put(insert({ [response.id]: response }));
    yield put(
      uploadTranslationSuccess({
        id: flowId,
      })
    );
    yield call(toast.success, 'Successfully uploaded');
    yield response;
  } catch (error) {
    yield put(reject(error));
    throw error;
  }
}

export function* fetchTranslations({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getTranslationMetaForFlow, id);
    const { translations } = response;

    yield put(
      resolve(
        translations.reduce((acc, translation) => {
          acc[translation.id] = translation;
          return acc;
        }, {})
      )
    );
  } catch (error) {
    yield put(reject(error));
    yield call(toast.error, error);
  }
}

export function* updateFlowNameTranslation({ payload: { id, translations } }) {
  try {
    const api = yield getContext('api');

    yield all(
      translations.map(({ translationId, name }) =>
        call(api.updateFlowNameTranslation, id, translationId, name)
      )
    );

    yield call(toast.success, 'Flow names translated');
    yield call(fetchTranslations, { payload: { id } });
  } catch (error) {
    yield put(reject(error));
    yield call(toast.error, error);
  }
}

export function* downloadAllTranslations({ payload: { flowId, format } }) {
  try {
    const api = yield getContext('api');
    yield call(api.localizeFlow, flowId);
    const response = yield call(api.downloadTranslations, flowId, format);
    yield call(downloadFile, response, flowId, 'zip');
    yield put(translationsDownloaded(flowId));
  } catch (error) {
    yield put(reject(error));
  }
}

export function* removeTranslations({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    yield call(api.delocalizeFlow, id);
    yield put(translationsRemoved(id));
    yield call(toast.success, 'Translations deleted');
  } catch (error) {
    yield put(reject(error));
    yield call(toast.error, 'Failed to delete translations');
  }
}

export function* deleteTranslation({ payload: { flowId, translationId } }) {
  try {
    const api = yield getContext('api');
    yield call(api.deleteTranslation, flowId, translationId);
    yield put(remove(translationId));
    yield put(
      deleteTranslationSuccess({
        id: flowId,
      })
    );
  } catch (error) {
    yield put(reject(error));
    yield call(toast.error, 'Could not delete translation - please try again');
  }
}

export default function* saga() {
  yield takeEvery(
    replacePagePatternFor('/flows/:flowId/settings'),
    fetchTranslations
  );

  yield takeEveryPromised(TRANSLATIONS_REQUESTED, downloadAllTranslations);
  yield takeEvery(
    [TRANSLATION_UPLOADED_SUCCESS, TRANSLATION_DELETED_SUCCESS],
    fetchTranslations
  );
  yield takeEveryPromised(REMOVE_TRANSLATIONS, removeTranslations);
  yield takeEveryPromised(TRANSLATION_UPLOADED, createTranslation);
  yield takeEveryPromised(TRANSLATION_DELETED, deleteTranslation);
  yield takeEveryPromised(
    UPDATE_FLOW_NAME_TRANSLATION,
    updateFlowNameTranslation
  );
}
