import { createCollectionReducer } from 'next/lib/collections';
import { TYPE } from './actions';

export default createCollectionReducer(TYPE);

export const selectEntitlementsByName = (state, name) => {
  if (!state.entitlements) {
    return state;
  }
  return state.entitlements[name];
};

export const selectIsEntitledByName = (state, name) => {
  const entitlement = selectEntitlementsByName(state, name);
  return entitlement?.allowed_units > entitlement?.used_units;
};
