import React, { useState } from 'react';

import SpecialCard from 'components/Common/SpecialCard';
import QueryResults from 'components/Common/QueryResults';

const ONE_DAY_MS = 1000 * 60 * 60 * 24;
const THIRTY_DAYS_MS = ONE_DAY_MS * 30;

const NpsSpecialCard = ({ hasCreatedNPS, npsId }) => {
  const [endTime] = useState(Date.now());

  let npsScore = 0;
  let isLoading = true;

  // 30 days is the api's default for `start_time` if not supplied
  // defaulting in this component in part because it's still used by legacy
  // components which don't vary the time frame
  const startTime = endTime - THIRTY_DAYS_MS;

  const queryWindow = Math.round((endTime - startTime) / ONE_DAY_MS) || 1;

  const computedScoreConditions = [
    ['flow_id', '==', npsId],
    ['window', '==', queryWindow],
  ];

  return (
    <QueryResults
      query={{
        metrics: ['nps_computed_score'],
        dimensions: ['day'],
        conditions: computedScoreConditions,
        start_time: endTime,
        end_time: endTime,
        meta: {
          tags: {
            feature: 'NPS Score Card',
            description: 'Computed NPS score',
          },
        },
      }}
    >
      {results => {
        if (results) {
          const { nps_computed_score = 0 } = results[0] || {};
          npsScore = Math.floor(nps_computed_score);
          isLoading = false;
        }

        return (
          <SpecialCard
            name={hasCreatedNPS ? 'Current NPS Score' : 'NPS Survey'}
            value={hasCreatedNPS ? npsScore : 'Create one'}
            description="Net Promoter Score (NPS)"
            backgroundImage="$grapefruit"
            iconClassName="comment-alt"
            to="/nps"
            isLoading={hasCreatedNPS && isLoading}
          />
        );
      }}
    </QueryResults>
  );
};

export default NpsSpecialCard;
