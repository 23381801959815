import React, { useEffect, lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { getAccountRoutes } from 'components/App/account-routes';
import Overview from 'components/Overview';
import RedirectWithQueryParams from 'components/App/RedirectWithQueryParams';
import RouteNotFound from 'components/RouteNotFound';
import { APP_TYPES } from 'constants/apps';
import { addToErrReportTags } from 'helpers/error-reporting';

const LazySubscription = lazy(() => import('components/Subscription'));
const LazyCarouselPreview = lazy(() => import('components/CarouselPreview'));
const LazySubscriptionPicker = lazy(() =>
  import('components/SubscriptionPicker')
);
const LazySubscriptionStory = lazy(() =>
  import('components/SubscriptionStory')
);
const LazyThemes = lazy(() => import('components/Themes'));
const LazySatisfactionSurveys = lazy(() =>
  import('components/SatisfactionSurveys')
);
const LazyDiagnostics = lazy(() => import('components/Diagnostics'));
const LazyChecklists = lazy(() => import('components/Checklists'));
const LazySegments = lazy(() => import('components/Segments'));
const LazyGoals = lazy(() => import('components/Goals'));
const LazyInstallationGuide = lazy(() =>
  import('components/InstallationGuide')
);
const LazySettings = lazy(() => import('components/Settings'));
const LazySubscriptionUpgrade = lazy(() =>
  import('components/SubscriptionUpgrade')
);
const LazyBilling = lazy(() => import('components/SubscriptionBilling'));
const LazySubscriptionCheckout = lazy(() =>
  import('components/SubscriptionCheckout').then(module => ({
    default: module.SubscriptionCheckout,
  }))
);
const LazyEditInChrome = lazy(() => import('components/EditInChrome'));
const LazyFlows = lazy(() => import('components/Flows'));
const LazyReports = lazy(() => import('components/Reports'));
const LazyInsights = lazy(() => import('components/Insights'));
const LazyAudience = lazy(() => import('components/Audience'));
const LazySegmentEnabled = lazy(() => import('components/SegmentEnabled'));
const LazyPriorities = lazy(() => import('components/Priorities'));

export const WebRoutes = () => {
  useEffect(() => {
    addToErrReportTags({ appType: APP_TYPES.WEB_APP });
  }, []);

  return (
    <Switch>
      <Route
        path="/subscription/public"
        component={LazySubscription}
        isPublic
      />

      <Route path="/carousel" component={LazyCarouselPreview} />
      <Route path="/subscription/compare" component={LazySubscriptionPicker} />
      <Route path="/subscription/story" component={LazySubscriptionStory} />
      <Route path="/segments" component={LazySegments} />
      <Route path="/goals" component={LazyGoals} />
      <Route
        path="/settings/installation/guide"
        component={LazyInstallationGuide}
      />
      <Route path="/settings" component={LazySettings} />
      <Route
        path="/subscription/upgrade"
        component={LazySubscriptionUpgrade}
        exact
      />
      <Route path="/subscription/billing" component={LazyBilling} exact />
      <Route
        path="/subscription/:type"
        component={LazySubscriptionCheckout}
        exact
      />
      <Route
        path="/themes"
        metric="render.theme.editor"
        component={LazyThemes}
      />
      <Route path="/nps" component={LazySatisfactionSurveys} />
      <Route path="/diagnostics" component={LazyDiagnostics} />
      <Route path="/checklists" component={LazyChecklists} />

      <Route
        shouldHideNav
        path="/edit-in-chrome"
        component={LazyEditInChrome}
      />

      <Route path="/flows" component={LazyFlows} />

      {/* TEMPORARY REDIRECTS DURING MIGRATON */}

      <Redirect
        path="/journeys/:flowId/analytics"
        to="/flows/:flowId/analytics"
      />
      <Redirect
        path="/journeys/:flowId/settings"
        to="/flows/:flowId/settings"
      />
      <Redirect
        path="/journeys/:flowId/conditions"
        to="/flows/:flowId/settings"
      />

      {/* /TEMPORARY REDIRECTS DURING MIGRATON */}

      <RedirectWithQueryParams path="/reporting" to="/reports" />
      <Route path="/reports" component={LazyReports} />
      <Route path="/insights" component={LazyInsights} />
      <Route path="/events" component={LazyInsights} />
      <Route path="/priorities" component={LazyPriorities} />

      <Route path="/segment-enabled" component={LazySegmentEnabled} />
      <Route path="/audience" component={LazyAudience} />
      <Redirect path="/users/:userId" to="/audience/users/:userId" />
      <RedirectWithQueryParams path="/account" to="/settings/account" />
      <RedirectWithQueryParams path="/install" to="/settings/installation" />
      <RedirectWithQueryParams
        path="/integrations"
        to="/settings/integrations"
      />
      <RedirectWithQueryParams
        path="/events-attributes"
        to="/settings/events"
      />
      <RedirectWithQueryParams path="/team" to="/settings/team" />
      <RedirectWithQueryParams path="/billing" to="/settings/subscription" />
      <RedirectWithQueryParams
        path="/subscription"
        to="/settings/subscription"
      />
      <RedirectWithQueryParams path="/overview" to="/" />
      <Route exact path="/" component={Overview} />

      {getAccountRoutes()}

      <Route component={RouteNotFound} />
    </Switch>
  );
};

export default WebRoutes;
