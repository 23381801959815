import moment from 'moment';
import HTTPError from 'errors/HTTPError';

import { getAnalyticsBackend } from 'helpers/api-client';

export default function createAnalyticsV2Client(auth) {
  const request = async (body = {}) => {
    const jwt = await auth.getToken();
    const accountId = await auth.getAccountId();
    /* global CUSTOMER_API_URL */
    const queryUrl = `${CUSTOMER_API_URL}/v2/accounts/${accountId}/analytics/query`;
    // NOTE: currently the analytics api requires account_id to be
    // present in both the query params and the request body
    const urlWithAccountParam = `${queryUrl}?account_id=${accountId}`;
    const timeZoneOffset = moment().utcOffset();
    const analyticsBackend = getAnalyticsBackend();
    const bodyWithAccountIdAndTimeZoneOffset = {
      ...body,
      account_id: accountId,
      time_zone: timeZoneOffset,
      meta: {
        ...body.meta,
        // we are temporarily adding this to the meta object to allow for testing
        // the migration of Analytics v2 queries from snowflake to clickhouse
        ...(analyticsBackend && {
          force_analytics: analyticsBackend,
        }),
      },
    };
    const apiBody = JSON.stringify(bodyWithAccountIdAndTimeZoneOffset);

    const response = await fetch(urlWithAccountParam, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: apiBody,
    });
    if (!response.ok) {
      throw new HTTPError(response);
    }

    return response.json();
  };

  return {
    getAnalytics: ({ query }) => request(query),
  };
}
