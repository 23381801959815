import {
  all,
  call,
  fork,
  getContext,
  put,
  spawn,
  take,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects';

import {
  SYNC_AFTER_LOGIN,
  FIREBASE_READY,
  CHECK_LOGIN_STATUS,
  SYNC_ACCOUNT_WITH_FIREBASE,
} from 'constants/actionTypes';
import { syncAccountWithFirebase, syncAfterLogin } from 'actions/bootstrap';

import { checkForChromeExtension } from 'sagas/chromeExtension';
import {
  addToErrReportContext,
  addToErrReportTags,
  reportError,
} from 'helpers/error-reporting';
import { syncUser, USER_REPLACED } from 'actions/user';

import { fetchIntegrations } from 'sagas/account/integrations';
import { fetchFlows } from 'sagas/account/flows';
import { fetchAllNPS } from 'sagas/account/satisfaction';
import { fetchInstalledDomains } from 'sagas/account/installedDomains';
import { fetchLocales } from 'sagas/account/locales';
import { fetchSegments } from 'sagas/account/segments';
import { fetchGoals } from 'sagas/account/goals';
import { fetchAccountMeta } from 'sagas/account/meta';
import { getAllProfileAttributeLabels } from 'entities/profile-attribute-labels';
import { getAllProfileAttributes } from 'entities/profile-attributes';
import { getAllGroupAttributes } from 'entities/group-attributes';
import { fetchExperiences } from 'entities/experiences';
import { fetchGates } from 'entities/gates';
import { fetchTrialStatus } from 'entities/trial-status';
import { fetchAccountUsers } from 'sagas/account/users';
import { fetchThemes } from 'sagas/account/themes';
import { getAllEventNames } from 'entities/event-names';
import { getAllEventLabels } from 'entities/event-labels';
import { fetchAccountStats } from 'sagas/account/stats';
import { fetchChecklists } from 'entities/checklists';
import { fetchEntitlements } from './entitlements-v2';

/**
 * Determines if there is an active session, and if so
 * jumpstarts Firebase
 */
export function* checkLoginStatus() {
  try {
    yield call(checkForChromeExtension);
    yield put(syncAfterLogin());
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    reportError(error);
  }
}

/**
 *
 * @param {User} user User obj returned by auth client
 */

export function* initUserSync() {
  try {
    const auth = yield getContext('auth');
    const userId = yield call(auth.getUserId);
    const isSpoofing = yield call(auth.isSpoofing);

    yield put(syncUser(userId));
    yield take(USER_REPLACED);

    yield put({
      type: FIREBASE_READY,
    });
    if (!isSpoofing) {
      addToErrReportContext({
        userId,
        id: userId,
      });
      addToErrReportTags({
        userId,
      });
    }
  } catch (error) {
    yield call(reportError, error);
  }
}

/**
 * Begin syncing the first accountId listed in `/users/${userId}/accounts`, or
 * the `accountId` if provided, with our local reducers.
 *
 * @param action
 */

export function* initAccountSync() {
  try {
    const auth = yield getContext('auth');
    const accountId = yield call(auth.getAccountId);
    const isSpoofing = yield call(auth.isSpoofing);

    yield put(syncAccountWithFirebase(accountId));

    if (!isSpoofing) {
      addToErrReportContext({
        accountId,
      });
      addToErrReportTags({
        accountId,
      });
    }
  } catch (error) {
    yield call(reportError, error);
  }
}

export function* prefetchAccountData() {
  yield all([
    // NOT firebase
    spawn(fetchAccountMeta),
    spawn(fetchFlows),
    spawn(fetchAllNPS),
    spawn(fetchInstalledDomains),
    spawn(fetchLocales),
    spawn(fetchSegments),
    spawn(fetchGoals),
    spawn(getAllProfileAttributeLabels),
    spawn(getAllProfileAttributes),
    spawn(getAllGroupAttributes),
    spawn(getAllEventNames),
    spawn(getAllEventLabels),
    spawn(fetchExperiences),
    spawn(fetchGates),
    spawn(fetchTrialStatus),
    spawn(fetchAccountUsers),
    spawn(fetchIntegrations),
    spawn(fetchThemes),
    spawn(fetchAccountStats),
    spawn(fetchChecklists),
    spawn(fetchEntitlements),
  ]);
}

function* authSaga() {
  yield takeLeading(SYNC_ACCOUNT_WITH_FIREBASE, prefetchAccountData);

  yield takeEvery(CHECK_LOGIN_STATUS, checkLoginStatus);
  yield takeEvery(SYNC_AFTER_LOGIN, initUserSync);
  yield takeEvery(SYNC_AFTER_LOGIN, checkForChromeExtension);

  yield take(FIREBASE_READY);
  yield fork(initAccountSync);
}

export default authSaga;
