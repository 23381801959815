import { useEffect } from 'react';
import { history } from 'next/lib/history';
import { local } from 'next/lib/storage';

const pagesTypes = {
  pins: 'pins',
  flows: 'flows',
  launchpads: 'launchpad',
  banners: 'banner',
  journeys: 'journeys',
};

/**
 * Hook to run listeners on route changes
 */
export default function useListeners() {
  useEffect(() => {
    const unlisten = history.listen(location => {
      const { pathname } = location;

      Object.keys(pagesTypes).forEach(page => {
        const regex = new RegExp(`/${page}`);
        if (!regex.test(pathname)) {
          local.remove(`appcues:${pagesTypes[page]}:search`);
          local.remove(`appcues:${pagesTypes[page]}:filter`);
        }
      });
    });

    return () => {
      unlisten();
    };
  }, []);
}
