import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import styled from 'styled-components';

import { CTab, CPage, Row, Grid, Box, H3 } from '@appcues/component-library';

import { isTrialAccount } from '@studio/navigation';
import {
  HIDE_FLYWHEEL_FROM_TRIALS,
  HIDE_FLYWHEEL,
  OVERVIEW_ACTIVITY,
} from 'next/entities/features';
import IndexPage from 'components/Common/IndexPage';
import FilteredFlowList from 'components/Common/FilteredFlowList';
import SpecialCard from 'components/Common/SpecialCard';
import NpsSpecialCard from 'components/Common/SpecialCard/NpsSpecialCard';
import FlowListLayout from 'components/Dashboard/FlowListLayout';
import LifecycleSegments from 'components/Common/LifecycleSegments';
import Loader from 'components/Common/Loader';

import {
  selectAccountFirstSatisfactionId,
  selectSatisfactionState,
} from 'selectors/satisfaction';
import {
  selectAccountId,
  selectAccountMeta,
  selectAccountMetaSynced,
} from 'reducers/account/meta';
import { selectFlows, selectAreFlowsSynced } from 'reducers/account/flows';
import {
  selectAccountChecklists,
  selectAccountChecklistsSynced,
} from 'entities/checklists';
import { selectAccountSegments } from 'reducers/account/segments';
import { selectMobileFlows, selectPins } from 'entities/experiences';

import { navigate } from 'actions/routing';
import { trackEvent } from 'actions/events';
import { handleFlowActions } from 'actions/view';

import { SORT_TYPES } from 'constants/view';
import { CLICKED_BUTTON } from 'constants/events';

import { selectAccountUsersSynced } from 'reducers/account/users';
import { selectSegmentMemberships } from 'reducers/account/segmentMemberships';
import { callApi as fetchSegmentMemberships } from 'actions/account/segmentMemberships';
import { selectAccountFeature } from 'reducers/account/features';
import { selectTrialStatus } from 'entities/trial-status';
import RecentGroups from 'components/RecentGroups/RecentGroupsProvider';
import EndUserProfiles from 'components/EndUserProfiles/EndUserProfilesProvider';
import OverviewMetrics from './OverviewMetrics';
import { RecentWrapper } from './styled';

const DASHBOARD_TIME_PERIOD = {
  LAST_7_DAYS: 'weekly',
  LAST_30_DAYS: 'monthly',
};

const DASHBOARD_TIME_PERIOD_INTERVAL_DAYS = {
  [DASHBOARD_TIME_PERIOD.LAST_7_DAYS]: 7,
  [DASHBOARD_TIME_PERIOD.LAST_30_DAYS]: 30,
};

class Dashboard extends Component {
  state = {
    timePeriod: DASHBOARD_TIME_PERIOD.LAST_7_DAYS,
  };

  componentDidMount() {
    this.fetchDashboardStats();
  }

  goToFlows = (buttonLabel, flowListView) => () => {
    const { actions } = this.props;
    actions.trackEvent(CLICKED_BUTTON, {
      flowListView,
      buttonLabel,
    });
    actions.navigate('/flows');
  };

  changeDashboardTimePeriod = timePeriod => {
    this.setState({
      timePeriod,
    });
  };

  fetchDashboardStats = () => {
    const { actions } = this.props;
    actions.fetchSegmentMemberships();
  };

  render() {
    const {
      className,
      steps,
      synced,
      stepsSynced,
      checklistsSynced,
      checklists,
      hasCreatedNPS,
      segments,
      userCountsBySegment,
      satisfactionId,
      mobileFlows,
      pins,
      isTrial,
      hasHideFlyWheelFromTrials,
      hasHideFlyWheel,
      hasOverviewActivity,
      groupIdentifier,
      userIdentifier,
    } = this.props;
    const { timePeriod } = this.state;

    const hasPins = Object.keys(pins).length > 0;
    const flows = Object.values(steps).filter(step => step.type === 'journey');
    const liveFlows = flows.filter(flow => flow.published);
    const livePins = Object.values(pins).filter(pin => pin.published);
    const liveMobileFlows = Object.values(mobileFlows).filter(
      step => step.published
    );

    const liveChecklists = checklists.filter(checklist => checklist.published);

    const hasAnyFlows =
      flows.length > 0 || Object.values(mobileFlows).length > 0;

    if (!synced) {
      return <Loader />;
    }

    return (
      <IndexPage className={className} title="Overview">
        <CPage.Container marginTop={48}>
          <Box>
            <Box is="header" marginBottom={24} display="table" width="100%">
              <H3 float="left" lineHeight="40px">
                Your content
              </H3>
            </Box>
            <Grid marginBottom={25} minItemWidths={[250, [1400, 240]]}>
              <SpecialCard
                name="Flows"
                value={
                  hasAnyFlows
                    ? liveFlows.length + liveMobileFlows.length
                    : 'Create one'
                }
                description={
                  hasAnyFlows ? 'Live Flows on your apps' : 'No flows yet'
                }
                backgroundImage="$blurple-haze"
                iconClassName="map"
                to="/flows"
                isLoading={!stepsSynced}
              />
              <SpecialCard
                name="Pins"
                value={hasPins ? livePins.length : 'Create one'}
                description={
                  hasPins
                    ? `Live Pin${livePins.length === 1 ? '' : 's'} on your site`
                    : 'No Pins yet'
                }
                backgroundImage="$gradient-green"
                iconClassName="thumbtack"
                to="/pins"
                isLoading={!stepsSynced}
              />
              <SpecialCard
                name="Checklists"
                value={
                  checklists.length > 0 ? liveChecklists.length : 'Create one'
                }
                description={
                  checklists.length > 0
                    ? 'Live checklists on your site'
                    : 'No checklists yet'
                }
                backgroundImage="$daba-dee-daba-die"
                iconClassName="check-circle"
                to="/checklists"
                isLoading={!checklistsSynced}
              />
              <NpsSpecialCard
                npsId={satisfactionId}
                hasCreatedNPS={hasCreatedNPS}
              />
            </Grid>{' '}
          </Box>
          <CTab.Bar
            marginBottom={20}
            value={timePeriod}
            onChange={this.changeDashboardTimePeriod}
          >
            <CTab id={DASHBOARD_TIME_PERIOD.LAST_7_DAYS}>Last 7 days</CTab>
            <CTab id={DASHBOARD_TIME_PERIOD.LAST_30_DAYS}>Last 30 days</CTab>
          </CTab.Bar>

          <Grid
            marginBottom={40}
            minItemWidths={[
              245,
              [1430, 200],
              [1250, 185],
              [1190, 260],
              [920, 185],
            ]}
          >
            <OverviewMetrics
              period={DASHBOARD_TIME_PERIOD_INTERVAL_DAYS[timePeriod]}
            />
          </Grid>
          {hasOverviewActivity && (
            <RecentWrapper>
              <Box is="header" marginBottom={24} display="table" width="100%">
                <H3 float="left" lineHeight="40px">
                  Recent Activity
                </H3>
              </Box>
              <Row>
                <RecentGroups identifier={groupIdentifier} />
                <EndUserProfiles identifier={userIdentifier} />
              </Row>
            </RecentWrapper>
          )}
          {synced && flows.length > 0 && (
            <Row flexWrap="wrap">
              <FlowListLayout
                title="Recently updated flows"
                linkOnClick={
                  Object.keys(steps).length > 0 &&
                  this.goToFlows('All Flows', SORT_TYPES.RECENT_UPDATED)
                }
              >
                <FilteredFlowList
                  sort={SORT_TYPES.RECENT_UPDATED}
                  limit={5}
                  steps={steps}
                />
              </FlowListLayout>
            </Row>
          )}
          {!hasHideFlyWheel && !(isTrial && hasHideFlyWheelFromTrials) && (
            <LifecycleSegments
              segments={segments}
              userCountsBySegment={userCountsBySegment}
              marginTop={40}
            />
          )}
        </CPage.Container>
      </IndexPage>
    );
  }
}

function mapStateToProps(state) {
  const accountMetaIsSynced = selectAccountMetaSynced(state);
  const satisfactionState = selectSatisfactionState(state);

  const satisfactionId = satisfactionState.satisfaction
    ? selectAccountFirstSatisfactionId(state)
    : null;
  const accountMeta = selectAccountMeta(state);
  const trialStatus = selectTrialStatus(state);

  return {
    accountId: selectAccountId(state),
    steps: selectFlows(state),
    mobileFlows: selectMobileFlows(state),
    pins: selectPins(state),
    satisfactionId,
    accountMetaIsSynced,
    accountMeta,
    checklists: Object.values(selectAccountChecklists(state)),
    hasCreatedNPS: !!selectAccountFirstSatisfactionId(state),
    stepsSynced: selectAreFlowsSynced(state),
    checklistsSynced: selectAccountChecklistsSynced(state),
    synced:
      selectAreFlowsSynced(state) &&
      selectAccountUsersSynced(state) &&
      accountMetaIsSynced,
    segments: selectAccountSegments(state),
    userCountsBySegment: selectSegmentMemberships(state),
    isTrial: isTrialAccount(accountMeta, trialStatus),
    hasHideFlyWheelFromTrials: selectAccountFeature(
      state,
      HIDE_FLYWHEEL_FROM_TRIALS
    ),
    hasHideFlyWheel: selectAccountFeature(state, HIDE_FLYWHEEL),
    hasOverviewActivity: selectAccountFeature(state, OVERVIEW_ACTIVITY),
    userIdentifier: accountMeta?.userProfileIdentifier,
    groupIdentifier: accountMeta?.groupProfileIdentifier,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        navigate,
        trackEvent,
        handleFlowActions,
        fetchSegmentMemberships,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styled(Dashboard)`
  .analyticsFlexRow {
    display: flex;
    flex-direction: row;
    width: 100%;

    &:first-child {
      margin-bottom: 24px;
    }

    > * {
      &:not(:first-child) {
        margin-left: 23px;
      }
    }
  }

  .header {
    margin-bottom: 1.5rem;
  }

  .headerTitle {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 0.5rem;
    color: #364760; /* TODO: Change to variable */
    line-height: 24px;

    i {
      color: #c8d2e0; /* TODO: Change to variable */
      margin-right: 1rem;
    }
  }

  .flowsSection {
    margin-top: 16px;
  }

  .dashboardSection {
    width: 100%;
    margin-top: 16px;
  }

  .dashboardFlowList {
    width: 100%;
  }

  .listItem {
    width: 100%;
    border-radius: 6px;
    border: solid 1px rgba(167, 176, 193, 0.29); /* TODO: Change to variable */
    padding: 10px;
    margin-bottom: 10px;
  }

  .flowList {
    margin-bottom: 1.5rem;
  }

  .rowSpace {
    height: 100px;
    width: 100%;
  }

  .reloadStatsButton {
    margin-left: 16px;
  }
`);
