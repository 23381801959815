import { getContext, call, put, takeLeading } from 'redux-saga/effects';
import { replacePagePatternFor } from 'next/entities/page';
import { resolve, reject } from './actions';

// This is done to keep compatibility with the data structure provided by the old
// groupAttributes graphql query. We are using the new groupProfileAttributes query
// now because it allows the results to be filtered by showInUi.
function parseTargetingOptions(data) {
  const parsedData = { ...data };
  parsedData.groupAttributes = parsedData.groupProfileAttributes.attributes;
  delete parsedData.groupProfileAttributes;
  return parsedData;
}

function* fetchTargetingOptions() {
  try {
    const api = yield getContext('api');

    const { data } = yield call(api.getTargetingOptions);

    yield put(resolve(parseTargetingOptions(data)));
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* saga() {
  yield takeLeading(
    [
      replacePagePatternFor('/pins/:pinId/settings'),
      replacePagePatternFor('/banners/:experienceId/settings'),
      replacePagePatternFor('/mobile/flows/:experienceId/settings'),
      replacePagePatternFor('/flows/:flowId/settings'),
      replacePagePatternFor('/launchpads/:experienceId/settings'),

      replacePagePatternFor('/mobile/flows/:experienceId/analytics'),
      replacePagePatternFor('/pins/:pinId/analytics'),
      replacePagePatternFor('/banners/:experienceId/analytics'),
    ],
    fetchTargetingOptions
  );
}
