import { call, getContext, put, takeEvery } from 'redux-saga/effects';

import { replacePagePatternFor } from 'next/entities/page';

import { patterns, reject, replace } from './actions';

function* fetchExperienceVersions({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getExperienceVersions, id);
    yield put(replace({ [id]: response }));
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* saga() {
  yield takeEvery([patterns.readOne], fetchExperienceVersions);

  // Page actions
  yield takeEvery(
    [
      replacePagePatternFor('/pins/:pinId/analytics'),
      replacePagePatternFor('/banners/:experienceId/analytics'),
      replacePagePatternFor('/mobile/flows/:experienceId/analytics'),
    ],
    fetchExperienceVersions
  );
}
