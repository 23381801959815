import { call, getContext, put, take, race, delay } from 'redux-saga/effects';
import { reject, replace, drop, patterns } from 'next/entities/content-status';
import { patterns as rulePatterns } from 'next/entities/rules';
import { replacePagePatternFor } from 'next/entities/page';

import {
  PIN_PUBLISHED,
  PIN_REVERTED,
  PIN_UNPUBLISHED,
  patterns as pinPatterns,
} from 'next/entities/pins';

import {
  EXPERIENCE_PUBLISHED,
  EXPERIENCE_REVERTED,
  EXPERIENCE_UNPUBLISHED,
  patterns as experiencePatterns,
} from 'next/entities/experiences';

import {
  FLOW_PUBLISHED,
  FLOW_REVERTED,
  FLOW_UNPUBLISHED,
  patterns as flowPatterns,
} from 'next/entities/flows';

export const POLLING_INTERVAL = 6 * 1000;

const POLL_ACTIONS = [
  patterns.readOne,
  replacePagePatternFor('/pins/:pinId/settings'),
  replacePagePatternFor('/banners/:experienceId/settings'),
  replacePagePatternFor('/mobile/flows/:experienceId/settings'),
  replacePagePatternFor('/flows/:flowId/settings'),
  replacePagePatternFor('/launchpads/:experienceId/settings'),
  replacePagePatternFor('/flows/v2/:experienceId/settings'),
];

const EXPERIENCE_ACTIONS = [
  PIN_PUBLISHED,
  PIN_UNPUBLISHED,
  PIN_REVERTED,
  EXPERIENCE_PUBLISHED,
  EXPERIENCE_UNPUBLISHED,
  EXPERIENCE_REVERTED,
  patterns.flush,
  rulePatterns.flush,
  pinPatterns.flush,
  experiencePatterns.flush,
];

const FLOWS_ACTIONS = [
  FLOW_PUBLISHED,
  FLOW_UNPUBLISHED,
  FLOW_REVERTED,
  flowPatterns.flush,
];

const FLOW_PATHS = new Set(['/flows/:flowId/settings']);

const RE_POLL_ACTIONS = [...EXPERIENCE_ACTIONS, ...FLOWS_ACTIONS];

const CANCEL_ACTIONS = [patterns.drop];

function* poll({ type, payload: { id, path } }) {
  const api = yield getContext('api');
  while (true) {
    try {
      const contentType =
        FLOWS_ACTIONS.includes(type) || FLOW_PATHS.has(path)
          ? 'flows'
          : 'experiences';
      const contentStatus = yield call(api.getContentStatus, id, contentType);
      yield put(replace({ ...contentStatus, id }));
    } catch (error) {
      yield put(reject(error));
    } finally {
      yield race([delay(POLLING_INTERVAL), take(RE_POLL_ACTIONS)]);
    }
  }
}

function* dropContentStatus({ payload: { id } }) {
  yield put(drop(id));
}

export default function* saga() {
  while (true) {
    const action = yield take(POLL_ACTIONS);
    yield race({
      task: call(poll, action),
      cancel: take(CANCEL_ACTIONS),
    });
    yield call(dropContentStatus, action);
  }
}
