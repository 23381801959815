import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { promisaga } from 'utils/as-promised';
import { resolve, reject, patterns } from './actions';

function* fetchUserProfileAttributes() {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getUserProfileAttributes);

    const data = response?.data?.data;

    yield put(
      resolve({ attributes: data?.userProfileAttributes?.attributes || [] })
    );
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* saga() {
  yield takeEvery(patterns.callApi, promisaga(fetchUserProfileAttributes));
}
