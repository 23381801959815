import {
  call,
  select,
  getContext,
  put,
  takeEvery,
  fork,
  takeLatest,
} from 'redux-saga/effects';
import { selectUser } from 'next/entities/user';
import { replacePagePatternFor } from 'next/entities/page';
import { takeEveryPromised, takeLeadingPromised } from 'next/lib/effects';

import { INITIALIZE } from 'next/root/actions';
import {
  reject,
  replace,
  resolve,
  readOne,
  patterns,
  INVITE_TEAM_MEMBER,
} from './actions';
import { parse } from './shape';

/**
 * Normalize accountUser summary response as collection
 *
 * @param {Response} response - Raw accountUser summary response
 * @return {Collection} accountUser collection
 */
const transform = response =>
  response.reduce((acc, { accountUser }) => {
    acc[accountUser.id] = parse(accountUser);
    return acc;
  }, {});

export function* fetchAccountUsers() {
  try {
    const api = yield getContext('api');
    const res = yield call(api.getAccountUsers);

    yield put(resolve(transform(res)));
  } catch (error) {
    yield put(reject(error));
  }
}

function* fetchAccountUser({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    const { accountUser } = yield call(api.getAccountUser, id);
    yield put(replace({ ...accountUser, id }));
  } catch (error) {
    yield put(reject(error));
  }
}

function* updateAccountUser({ payload: { id, delta = {} } }) {
  try {
    const { usageIntent, invitations, ...rest } = delta;

    const api = yield getContext('api');
    const accountUser = yield call(api.updateAccountUser, id, rest);

    yield put(replace(accountUser));

    return accountUser;
  } catch (error) {
    yield put(reject(id, error));
    throw error;
  }
}
function* fetchCurrentUser() {
  const { id } = yield select(selectUser);
  yield call(fetchAccountUser, readOne(id));
}

export function* inviteTeamMember({ payload: { email, role } }) {
  try {
    const api = yield getContext('api');
    const invited = yield call(api.inviteTeamMember, {
      email,
      role,
    });
    return invited;
  } catch (error) {
    yield call(reportError, error);
    throw error;
  }
}

export default function* saga() {
  yield takeLatest(INITIALIZE, fetchAccountUsers);

  yield fork(fetchCurrentUser);
  yield takeEvery(patterns.readOne, fetchAccountUser);

  // Page actions
  yield takeEvery(
    [
      replacePagePatternFor('/pins/:pinId/settings'),
      replacePagePatternFor('/banners/:experienceId/settings'),
      replacePagePatternFor('/mobile/flows/:experienceId/settings'),
      replacePagePatternFor('/flows/:flowId/settings'),
      replacePagePatternFor('/launchpads/:experienceId/settings'),
      replacePagePatternFor('/flows/v2/:experienceId/settings'),
    ],
    fetchCurrentUser
  );

  yield takeLeadingPromised(patterns.update, updateAccountUser);
  yield takeEveryPromised(INVITE_TEAM_MEMBER, inviteTeamMember);
}
