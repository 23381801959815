import moment from 'moment';

import { createCollectionReducer } from 'reducers/account/collections';
import { selectAccountUser } from './users';

export const TYPE = 'flowVersions';
export default createCollectionReducer(TYPE);

export const selectFlowVersions = (state, { flowId, startTime }) => {
  let startArg;
  if (typeof startTime === 'string') {
    const [number, timeUnit] = startTime.split(' ');
    startArg = moment().subtract({ [timeUnit]: number });
  } else {
    startArg = startTime;
  }

  return (
    state.account.flowVersions &&
    state.account.flowVersions[flowId] &&
    state.account.flowVersions[flowId].versions.filter(({ version }) => {
      return moment(version).isAfter(startArg);
    })
  );
};

export const selectFlowHistory = (state, flowId) => {
  const flowVersions = state.account.flowVersions?.[flowId] || {};
  const versions = flowVersions.versions || [];

  // Versions are ordered newest -> oldest (so index - 1 == newer version, index + 1 == older version)
  return versions.flatMap((version, index) => {
    // If there's a gap between when this version was unpublished and when the newer version was published, then log as unpublished.
    // There's also a strange case where the current published version has an incorrect unpublishedAt value,
    // so checking that the version has been unpublished after it's been created is a reasonable sanity check.
    const isUnpublish =
      version.unpublishedAt !== versions[index - 1]?.version &&
      version.unpublishedAt > version.version;
    // If this version was published at the exact same time the older version was unpublished, it's pushing changes,
    // otherwise it's a publish (including the initial version).
    const isPushingChanges =
      versions[index + 1]?.unpublishedAt === version.updatedAt;

    return [
      ...(isUnpublish
        ? [
            {
              updatedAt: version.unpublishedAt,
              user: selectAccountUser(state, version.unpublishedBy)?.meta || {
                id: version.unpublishedBy,
              },
              status: 'Unpublished',
            },
          ]
        : []),
      {
        updatedAt: version.updatedAt,
        user: selectAccountUser(state, version.updatedBy)?.meta || {
          id: version.updatedBy,
        },
        status: isPushingChanges ? 'Pushed changes' : 'Published',
      },
    ];
  });
};

export const selectFlowVersionsByDimension = (
  state,
  { flowId, startTime, dimension }
) =>
  selectFlowVersions(state, { flowId, startTime }) &&
  selectFlowVersions(state, { flowId, startTime }).reduce((acc, version) => {
    const { version: versionId } = version;
    const date = moment(versionId).format(
      dimension === 'day' ? 'L' : 'YYYY-MM'
    );
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(version);
    return acc;
  }, {});
