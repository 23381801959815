/* global LAUNCHDARKLY_CLIENT_ID */

import { initialize } from 'launchdarkly-js-client-sdk';

/**
 * Account properties to pass to LaunchDarkly
 */
const ALLOWED_PROPERTIES = ['createdAt', 'tier'];

/**
 * Filter account properties for allowed only
 *
 * @param {object} properties - All account properties
 * @return {object} Allowed properties
 */
const filterProperties = properties =>
  ALLOWED_PROPERTIES.reduce((acc, property) => {
    acc[property] = properties[property];
    return acc;
  }, {});

/**
 * Fetch LaunchDarkly features flags
 *
 * @param {object} account - Account meta
 * @param {string} account.id - Account ID
 * @return {Promise<Features>} Promise holding Features client
 */
export async function getFeatureFlags({ id, ...meta }) {
  const custom = filterProperties(meta);
  const userContext = { key: id, kind: 'user', ...custom };
  const client = initialize(LAUNCHDARKLY_CLIENT_ID, userContext);
  await client.waitForInitialization();
  return client.allFlags();
}

export function getLocalFeature(feature) {
  const stored = localStorage.getItem('appcues:devtools:features');
  const overrides = stored ? JSON.parse(stored) : {};
  return overrides[feature] ?? false;
}
