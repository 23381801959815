/* global CUSTOMER_API_URL, JOB_API_URL */

import HTTPError from 'errors/HTTPError';
// eslint-disable-next-line no-restricted-imports
import CHECKLIST_TEMPLATE from 'entities/checklists/template.json';

export function getLocalFeature(feature) {
  const stored = localStorage.getItem('appcues:devtools:features');
  const overrides = stored ? JSON.parse(stored) : {};
  return overrides[feature] ?? false;
}
export function getAnalyticsBackend() {
  if (getLocalFeature('FORCE_CLICKHOUSE')) {
    return 'ch';
  }
  if (getLocalFeature('FORCE_SNOWFLAKE')) {
    return 'sf';
  }
  return null;
}

const fetchCustomerApi = (
  token,
  accountId,
  path,
  method = 'GET',
  { body, download, form, headers } = {},
  prependAccountId = true
) => {
  /**
   * In general, all the customer API endpoints are associated to account ID. However, in some cases like the
   * `planEntitlements` endpoint, it is not attached to any account, so we can omit it in the path.
   */
  const url = prependAccountId
    ? `${CUSTOMER_API_URL}/v1/accounts/${accountId}/${path}`
    : `${CUSTOMER_API_URL}/v1/${path}`;

  return fetch(url, {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
      /**
       * NOTE: When form data is used, omit content type to ensure the browser
       *       properly handles adding the header boundary value. Also for
       *       downloads requests, set the content type to zip (which is the
       *       only format we use for downloads at the moment)
       */
      ...(!form && {
        'Content-Type': download ? 'application/zip' : 'application/json',
      }),
      ...headers,
    },

    // NOTE: Do not stringify the body if FormData is used
    ...(form && { body: form }),
    ...(body && { body: JSON.stringify(body) }),
  })
    .then(async response => {
      if (!response.ok) {
        const data = await response.json();
        throw new HTTPError({ ...response, data }, data.message);
      }

      // NOTE: When requesting a download, serialize the response to a Blob
      //       rather than the default JSON
      return download ? response.blob() : response.json();
    })
    .then(data => ({ data }));
};

const fetchGraphql = (token, accountId, body) => {
  const analyticsBackend = getAnalyticsBackend();
  return fetchCustomerApi(
    token,
    accountId,
    'graphql',
    'POST',
    {
      body,
      headers: {
        ...(analyticsBackend && {
          'force-analytics': analyticsBackend,
        }),
      },
    },
    false
  );
};

const fetchJobApi = (
  token,
  accountId,
  path,
  method = 'GET',
  { body, download } = {}
) => {
  return fetch(`${JOB_API_URL}/api/batch/v3/${path}`, {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...body, account_id: `${accountId}` }),
  })
    .then(async response => {
      if (!response.ok) {
        const data = await response.json();
        throw new HTTPError({ ...response, data }, data.message);
      }

      // NOTE: When requesting a download, serialize the response to a Blob
      //       rather than the default JSON
      return download ? response.blob() : response.json();
    })
    .then(data => ({ data }));
};

const getRule = (token, accountId, id) =>
  fetchCustomerApi(token, accountId, `rules/${id}`);

const getSegmentMembership = (token, accountId, id) =>
  fetchCustomerApi(token, accountId, `segment_memberships/${id}`);

const getAllSegmentMemberships = (token, accountId) =>
  fetchCustomerApi(token, accountId, `segment_memberships`);

const getAllCustomEvents = (token, accountId) =>
  fetchCustomerApi(token, accountId, `custom_events`);

const createChart = (token, accountId, chart) =>
  fetchCustomerApi(token, accountId, `charts`, 'POST', { body: chart });

const getAllCharts = (token, accountId) =>
  fetchCustomerApi(token, accountId, `charts`);

const getChart = (token, accountId, chartId) =>
  fetchCustomerApi(token, accountId, `charts/${chartId}`);

const updateChart = (token, accountId, chartId, delta) =>
  fetchCustomerApi(token, accountId, `charts/${chartId}`, 'PATCH', {
    body: delta,
  });

const deleteChart = (token, accountId, chartId) =>
  fetchCustomerApi(token, accountId, `charts/${chartId}`, 'DELETE');

const getAllSegments = (token, accountId) =>
  fetchCustomerApi(token, accountId, `segments`, 'GET');

const updateSegment = (token, accountId, id, delta) =>
  fetchCustomerApi(token, accountId, `segments/${id}`, 'PATCH', {
    body: delta,
  });

const deleteSegment = (token, accountId, id) =>
  fetchCustomerApi(token, accountId, `segments/${id}`, 'DELETE');

const uploadSegmentUserIds = (token, accountId, body) =>
  fetchCustomerApi(token, accountId, 'segments/upload', 'POST', { form: body });

const preSignSegmentCsv = (token, accountId, segmentId) =>
  fetchCustomerApi(
    token,
    accountId,
    `segments/${segmentId}/pre-sign-csv`,
    'GET'
  );

const createSegment = (token, accountId, segment) =>
  fetchCustomerApi(token, accountId, `segments`, 'POST', { body: segment });

const getAllGoals = (token, accountId) =>
  fetchCustomerApi(token, accountId, `goals`, 'GET');

const updateGoal = (token, accountId, id, delta) =>
  fetchCustomerApi(token, accountId, `goals/${id}`, 'PATCH', { body: delta });

const deleteGoal = (token, accountId, id) =>
  fetchCustomerApi(token, accountId, `goals/${id}`, 'DELETE');

const createGoal = (token, accountId, goal) =>
  fetchCustomerApi(token, accountId, `goals`, 'POST', { body: goal });

const getGoalsFirstReachedThisWeek = (token, accountId) =>
  fetchCustomerApi(
    token,
    accountId,
    'goals/metrics/goals_first_reached_this_week',
    'GET'
  );

const getAllPublishedRules = (token, accountId) =>
  fetchCustomerApi(token, accountId, `rules?sorted=true`, 'GET');

const getAllKeys = (token, accountId) =>
  fetchCustomerApi(token, accountId, `api_keys`, 'GET');

const createKey = (token, accountId, apiKey) =>
  fetchCustomerApi(token, accountId, `api_keys`, 'POST', { body: apiKey });

const getAccountGates = (token, accountId) =>
  fetchCustomerApi(token, accountId, 'gates', 'GET');

const revokeKey = (token, accountId, key) =>
  fetchCustomerApi(token, accountId, `api_keys/${key}/revoke`, 'POST');

const activateKey = (token, accountId, key) =>
  fetchCustomerApi(token, accountId, `api_keys/${key}/activate`, 'POST');

const deleteKey = (token, accountId, key) =>
  fetchCustomerApi(token, accountId, `api_keys/${key}`, 'DELETE');

const getAllProfileAttributeLabels = (token, accountId) =>
  fetchCustomerApi(token, accountId, 'profile_attributes/labels', 'GET');

const updateProfileAttributeLabel = (token, accountId, name, delta) =>
  fetchCustomerApi(
    token,
    accountId,
    `profile_attributes/labels/${encodeURIComponent(name)}`,
    'PATCH',
    { body: delta }
  );

const getAllEventLabels = (token, accountId) =>
  fetchCustomerApi(token, accountId, 'event_labels', 'GET');

const createEventLabel = (token, accountId, body) =>
  fetchCustomerApi(token, accountId, `event_labels`, 'POST', {
    body,
  });

const updateEventLabel = (token, accountId, name, delta) =>
  fetchCustomerApi(token, accountId, `event_labels/${name}`, 'PATCH', {
    body: delta,
  });

const inviteTeammate = (token, accountId, invitation) =>
  fetchCustomerApi(token, accountId, 'invites', 'POST', { body: invitation });

const getAccount = (token, accountId) =>
  fetchCustomerApi(token, accountId, '', 'GET');

const getAccounts = (token, accountId, id) =>
  fetchCustomerApi(
    token,
    accountId,
    `users/${id}/accounts`,
    'GET',
    undefined,
    false
  );

const updateAccount = (token, accountId, delta) =>
  fetchCustomerApi(token, accountId, '', 'PATCH', { body: delta });

const getAllExperiences = (token, accountId) =>
  fetchCustomerApi(token, accountId, 'experiences?summary=true');

const getTrialStatus = (token, accountId) =>
  fetchCustomerApi(token, accountId, 'trial_status');

/**
 * Flow Builder API Methods
 */
const getAllFlows = (token, accountId) =>
  fetchCustomerApi(token, accountId, 'flows?summary=true');

const getFlow = (token, accountId, flowId) =>
  fetchCustomerApi(token, accountId, `flows/${flowId}`);

const createFlow = (token, accountId, flow) =>
  fetchCustomerApi(token, accountId, 'flows', 'POST', { body: flow });

const archiveFlow = (token, accountId, flowId) =>
  fetchCustomerApi(token, accountId, `flows/${flowId}/archive`, 'POST');

const unarchiveFlow = (token, accountId, flowId) =>
  fetchCustomerApi(token, accountId, `flows/${flowId}/unarchive`, 'POST');

const deleteFlow = (token, accountId, flowId) =>
  fetchCustomerApi(token, accountId, `flows/${flowId}`, 'DELETE');

const revertFlow = (token, accountId, flowId, versionId) =>
  fetchCustomerApi(
    token,
    accountId,
    `flows/${flowId}/revert?versionId=${versionId}`,
    'POST'
  );

const updateFlow = (token, accountId, flowId, delta) =>
  fetchCustomerApi(token, accountId, `flows/${flowId}`, 'PATCH', {
    body: delta,
  });

const publishFlow = (token, accountId, flowId) =>
  fetchCustomerApi(token, accountId, `flows/${flowId}/publish`, 'POST');

const unpublishFlow = (token, accountId, flowId) =>
  fetchCustomerApi(token, accountId, `flows/${flowId}/unpublish`, 'POST');

const cloneFlow = (token, accountId, flowId) =>
  fetchCustomerApi(token, accountId, `flows/${flowId}/clone`, 'POST');

const localizeFlow = (token, accountId, flowId) =>
  fetchCustomerApi(token, accountId, `flows/${flowId}/localize`, 'POST');

const delocalizeFlow = (token, accountId, flowId) =>
  fetchCustomerApi(token, accountId, `flows/${flowId}/delocalize`, 'POST');

const createTestFlow = (token, accountId, flowId) =>
  fetchCustomerApi(token, accountId, `flows/${flowId}/test`, 'POST');

const getAllFlowVersions = (token, accountId, flowId) =>
  fetchCustomerApi(token, accountId, `flows/${flowId}/versions`);

const getFlowVersionById = (token, accountId, flowId, versionId) =>
  fetchCustomerApi(token, accountId, `flows/${flowId}/versions/${versionId}`);

const getFlowContentStatus = (token, accountId, flowId) =>
  fetchCustomerApi(token, accountId, `flows/${flowId}/content-status`);

const createStepGroups = (token, accountId, flowId, stepGroup) =>
  fetchCustomerApi(token, accountId, `flows/${flowId}/stepGroups`, 'POST', {
    body: stepGroup,
  });

const exportAnalytics = async (token, accountId, data) => {
  return fetchJobApi(token, accountId, `csv`, 'POST', {
    method: 'POST',
    body: { ...data, account_id: accountId },
    account: false,
  });
};

const removeUser = (token, accountId, id) =>
  fetchCustomerApi(token, accountId, `users/${id}`, 'DELETE');

const updateAccountUser = (token, accountId, id, delta) =>
  fetchCustomerApi(token, accountId, `users/${id}`, 'PATCH', {
    body: delta,
  });

const createStepChild = (
  token,
  accountId,
  flowId,
  stepGroupType,
  stepGroupId,
  stepChild
) =>
  fetchCustomerApi(
    token,
    accountId,
    `flows/${flowId}/stepGroups/${stepGroupType}/${stepGroupId}/stepChildren`,
    'POST',
    { body: stepChild }
  );

const getAllRules = (token, accountId) =>
  fetchCustomerApi(token, accountId, 'rules');

const updateRule = (token, accountId, ruleId, delta) =>
  fetchCustomerApi(token, accountId, `rules/${ruleId}`, 'PATCH', {
    body: delta,
  });

const getAllNps = (token, accountId) =>
  fetchCustomerApi(token, accountId, 'nps');

const getNps = (token, accountId, npsId) =>
  fetchCustomerApi(token, accountId, `nps/${npsId}`);

const updateNps = (token, accountId, npsId, delta) =>
  fetchCustomerApi(token, accountId, `nps/${npsId}`, 'PATCH', { body: delta });

const createNps = (token, accountId, nps) =>
  fetchCustomerApi(token, accountId, 'nps', 'POST', { body: nps });

const publishNps = (token, accountId, npsId) =>
  fetchCustomerApi(token, accountId, `nps/${npsId}/publish`, 'POST');

const unpublishNps = (token, accountId, npsId) =>
  fetchCustomerApi(token, accountId, `nps/${npsId}/unpublish`, 'POST');

const revertNps = (token, accountId, npsId) =>
  fetchCustomerApi(token, accountId, `nps/${npsId}/revert`, 'POST');

const getTranslationMetaForFlow = (token, accountId, flowId) =>
  fetchCustomerApi(token, accountId, `flows/${flowId}/translations/meta`);

const getFlowNameTranslations = (token, accountId, flowId) =>
  fetchCustomerApi(token, accountId, `flows/${flowId}/flow_translations`);

const updateFlowNameTranslation = (
  token,
  accountId,
  flowId,
  translationId,
  name
) =>
  fetchCustomerApi(
    token,
    accountId,
    `flows/${flowId}/translations/${translationId}/flow_name`,
    'PUT',
    { body: { name } }
  );

const getAllLocales = (token, accountId) =>
  fetchCustomerApi(token, accountId, 'locales');

const createLocale = (token, accountId, locale) =>
  fetchCustomerApi(token, accountId, 'locales', 'POST', { body: locale });

const deleteLocale = (token, accountId, localeId) =>
  fetchCustomerApi(token, accountId, `locales/${localeId}`, 'DELETE');

const downloadTranslations = (token, accountId, flowId, format) =>
  fetchCustomerApi(
    token,
    accountId,
    `flows/${flowId}/translations/download?format=${format}`,
    'GET',
    { download: true }
  );

const uploadTranslation = (token, accountId, flowId, localeId, translation) => {
  const form = new FormData();
  form.append('locale_id', localeId);
  form.append('file', translation);

  return fetchCustomerApi(
    token,
    accountId,
    `flows/${flowId}/translations`,
    'POST',
    { form }
  );
};

const deleteTranslation = (token, accountId, flowId, translationId) =>
  fetchCustomerApi(
    token,
    accountId,
    `flows/${flowId}/translations/${translationId}`,
    'DELETE'
  );

const getAccountEntitlements = (token, accountId) =>
  fetchCustomerApi(token, accountId, 'entitlements');

const getAccountEntitlement = (token, accountId, name) =>
  fetchCustomerApi(token, accountId, `entitlements/${name}`);

const getAccountMetrics = (token, accountId, startTime, endTime) =>
  fetchCustomerApi(
    token,
    accountId,
    `metrics?start_time=${startTime}&end_time=${endTime}`
  );

const getIntegrations = (token, accountId) =>
  fetchCustomerApi(token, accountId, 'integrations');

const setIntegration = (token, accountId, id, integration) =>
  fetchCustomerApi(token, accountId, `integrations/${id}`, 'PUT', {
    body: integration,
  });

const deleteIntegration = (token, accountId, id) =>
  fetchCustomerApi(token, accountId, `integrations/${id}`, 'DELETE');

const getPlanEntitlements = (token, accountId, planId) =>
  fetchCustomerApi(
    token,
    accountId,
    `plans/${planId}/entitlements`,
    'GET',
    undefined,
    false
  );

const createOrUpdateSubscription = (token, accountId, subscriptionPayload) =>
  fetchCustomerApi(token, accountId, `subscription`, 'POST', {
    body: subscriptionPayload,
  });

const getAccountUsers = (token, accountId) =>
  fetchCustomerApi(token, accountId, `users`);

const getUser = (token, _, userId) =>
  fetchCustomerApi(
    token,
    undefined,
    `users/${userId}`,
    'GET',
    undefined,
    false
  );

const updateUser = (token, _, userId, delta) =>
  fetchCustomerApi(
    token,
    undefined,
    `users/${userId}`,
    'PATCH',
    { body: delta },
    false
  );

const getThemes = (token, accountId) =>
  fetchCustomerApi(token, accountId, `themes`);

const updateTheme = (token, accountId, themeId, delta) =>
  fetchCustomerApi(token, accountId, `themes/${themeId}`, 'PATCH', {
    body: delta,
  });

const createTheme = (token, accountId, theme) =>
  fetchCustomerApi(token, accountId, `themes`, 'POST', {
    body: theme,
  });

const deleteTheme = (token, accountId, id) =>
  fetchCustomerApi(token, accountId, `themes/${id}`, 'DELETE');

const setDefaultTheme = (token, accountId, id) =>
  fetchCustomerApi(token, accountId, `themes/${id}/set_default`, 'POST');

const getAllChecklists = (token, accountId) =>
  fetchCustomerApi(token, accountId, 'checklists?state=draft');

const getChecklist = (token, accountId, id) =>
  fetchCustomerApi(token, accountId, `checklists/${id}`);

const updateChecklist = (token, accountId, id, delta) =>
  fetchCustomerApi(token, accountId, `checklists/${id}`, 'PATCH', {
    body: delta,
  });

const createChecklist = (token, accountId) =>
  fetchCustomerApi(token, accountId, `checklists`, 'POST', {
    body: CHECKLIST_TEMPLATE,
  });

const deleteChecklist = (token, accountId, id) =>
  fetchCustomerApi(token, accountId, `checklists/${id}`, 'DELETE');

const cloneChecklist = (token, accountId, id) =>
  fetchCustomerApi(token, accountId, `checklists/${id}/clone`, 'POST');

const getChecklistStatus = (token, accountId, id) =>
  fetchCustomerApi(token, accountId, `checklists/${id}/content-status`);

const publishChecklist = (token, accountId, id) =>
  fetchCustomerApi(token, accountId, `checklists/${id}/publish`, 'POST');

const unpublishChecklist = (token, accountId, id) =>
  fetchCustomerApi(token, accountId, `checklists/${id}/unpublish`, 'POST');

const revertChecklist = (token, accountId, id) =>
  fetchCustomerApi(token, accountId, `checklists/${id}/revert`, 'POST');

const getExperienceSchedules = (token, accountId, contentId) =>
  fetchGraphql(token, accountId, {
    query: `query Schedules($contentId: String!) {
            schedules(contentId: $contentId) {
              id
              startDate
              endDate
              contentId
            }
          }`,
    variables: { contentId },
  });

const deleteExperienceSchedule = (token, accountId, id) =>
  fetchGraphql(token, accountId, {
    query: `mutation DeleteSchedule($id: String!) {
            deleteSchedule(id: $id) {
              success
              message
            }
          }`,
    variables: { id },
  });

const createExperienceSchedule = (token, accountId, input) =>
  fetchGraphql(token, accountId, {
    query: `mutation CreateSchedule($input: ScheduleInput!) {
        createSchedule(input: $input) {
          id
          startDate
          endDate
          contentId
        }
      }`,
    variables: { input },
  });

/**
 * Customer API - Survey Responses
 */

const getSurveyResponses = (
  token,
  accountId,
  id,
  start,
  end,
  propertyNames = []
) =>
  fetchGraphql(token, accountId, {
    query: `query surveyAnswers($id: String!, $page: Page, $sort: [SurveyAnswerSortOrder!], $filter: SurveyFilter, $propertyNames: [String]) {
          surveyAnswers(id: $id, page: $page, sort: $sort, filter: $filter, propertyNames: $propertyNames) {
            answers {
              userId
              sessionId
              userProfileAttributes
              timestamp
              fieldAnswers {
                fieldId
                value
              }
              userProfileAttributes
            }
            labels {
              fieldId
              label
              stepChildId
              stepChildNumber
              customReportingLabel
            }
            totalAnswersCount
            totalAnswersInRangeCount
          }
        }`,
    variables: {
      id,
      page: {
        size: 1000,
      },
      filter: {
        start,
        end,
      },
      sort: {
        key: 'TIMESTAMP',
        direction: 'DESC',
      },
      propertyNames,
    },
  });

const getUserProfiles = (
  token,
  accountId,
  propertyNames,
  sortKey = '_lastSeenAt',
  direction = 'DESC',
  limit = 5,
  offset = 0
) => {
  return fetchGraphql(token, accountId, {
    query: `query GetEndUserProfileList($filter: ProfileFilter, $page: PageInput!, $sort: ProfileListSort) {
          endUserProfileList(
            profilesPage: $page,
            filter: $filter,
            sort: $sort
          ) {
            profiles
            properties {
              name
              label
            }
            profilesPageInfo {
              limit
              total
              offset
            }
          }
        }
      `,
    variables: {
      sort: {
        sortKey,
        direction,
      },
      page: {
        limit,
        offset,
      },
      filter: {
        propertyNames,
      },
    },
  });
};

/**
 * Customer API - Users
 */
const getUserProfileAttributes = (token, accountId) =>
  fetchGraphql(token, accountId, {
    query: `query UserProfileAttributes {
              userProfileAttributes(
                profileAttributesPage: { limit: 1500, offset: 0 }
                filter: { showInUi: true }
              ) {
                attributes {
                  name
                  label
                  type
                }
              }
            }
          `,
  });

const getEventUserProfiles = (
  token,
  accountId,
  {
    eventNames,
    stepId,
    contentId,
    contentType,
    startTime,
    endTime,
    propertyNames,
    direction = 'DESC',
    limit = 10,
    offset = 0,
    searchString,
  }
) =>
  fetchGraphql(token, accountId, {
    query: `query EndUserProfileListForEvent($filter: ProfileForEventFilter, $sort: ProfileForEventListSort, $page: PageInput!) {
          endUserProfileListForEvent(
            filter: $filter
            sort: $sort
            page: $page
          ) {
             results {
              accountId
              userId
              profile
              event {
                timestamp
                name
                contentName
                stepId
                stepName
                stepType
                url
              }
            }
            pageInfo {
              limit
              total
              offset
            }
          }
        }
      `,
    variables: {
      sort: {
        direction,
      },
      page: {
        limit,
        offset,
      },
      filter: {
        propertyNames,
        eventNames,
        stepId,
        searchString,
        startTime,
        endTime,
        contentId,
        contentType,
      },
    },
  });

const getGroups = (
  token,
  accountId,
  propertyNames,
  sortKey = '_lastSeenAt',
  direction = 'DESC',
  limit = 5,
  offset = 0
) =>
  fetchGraphql(token, accountId, {
    query: `query groupProfileList(
          $filter: ProfileFilter
          $profilesPage: PageInput!
          $sort: ProfileListSort
        ) {
          groupProfileList(
            profilesPage: $profilesPage
            filter: $filter
            sort: $sort
          ) {
            profiles
            properties {
              name
              label
            }
            profilesPageInfo {
              limit
              total
              offset
            }
          }
        }`,
    variables: {
      sort: {
        sortKey,
        direction,
      },
      profilesPage: {
        limit,
        offset,
      },
      filter: {
        propertyNames,
      },
    },
  });

const getGroupProfileProperties = (
  token,
  accountId,
  groupId,
  searchString,
  limit = 1,
  offset = 0
) =>
  fetchGraphql(token, accountId, {
    query: `query GetGroupProfile(
          $groupId: String!
          $filter: ProfileFilter
          $page: PageInput!
          $sort: ProfileSort
        ) {
          groupProfile(groupId: $groupId, page: $page, filter: $filter, sort: $sort) {
            properties {
              value
              name
              lastSeen
            }
            pageInfo {
              limit
              total
              offset
            }
          }
        }`,
    variables: {
      groupId,
      page: {
        limit,
        offset,
      },
      filter: {
        searchString,
      },
    },
  });

/**
 * As of 2019-10-23, the API client doesn't deal directly with the auth client.
 * So, to keep the code simple, we're injecting a patched version that handles authentication
 * inside of each call. Any additional arguments well be passed through to the original method.
 *
 * @param auth - an instance of an auth client
 *
 * @return object - contains all methods of the original client, partially applied with the JWT and account id
 *
 *  *
 * Example:
 *   given a method signature of
 *      getFlowVersionById(jwt, accountId, flowId, versionId),
 *
 *   the returned method looks like
 *      getFlowVersionById(flowId, versionId)
 */

export default function createApiClient(auth) {
  const client = {
    getRule,
    getSegmentMembership,
    getAllSegmentMemberships,
    getAllCustomEvents,
    exportAnalytics,
    createChart,
    getAllCharts,
    getChart,
    updateChart,
    deleteChart,
    getAllSegments,
    updateSegment,
    deleteSegment,
    uploadSegmentUserIds,
    preSignSegmentCsv,
    createSegment,
    getAllGoals,
    updateGoal,
    deleteGoal,
    createGoal,
    getGoalsFirstReachedThisWeek,
    getAllPublishedRules,
    getAllKeys,
    createKey,
    getAccountGates,
    revokeKey,
    activateKey,
    deleteKey,
    getAllProfileAttributeLabels,
    updateProfileAttributeLabel,
    getAllEventLabels,
    createEventLabel,
    updateEventLabel,
    inviteTeammate,
    getAccount,
    getAccounts,
    updateAccount,
    getAllExperiences,
    getTrialStatus,

    // Flow Builder API Methods
    getAllFlows,
    getFlow,
    createFlow,
    archiveFlow,
    unarchiveFlow,
    deleteFlow,
    revertFlow,
    updateFlow,
    publishFlow,
    unpublishFlow,
    cloneFlow,
    localizeFlow,
    delocalizeFlow,
    createTestFlow,
    getAllFlowVersions,
    getFlowVersionById,
    getFlowContentStatus,
    createStepGroups,
    createStepChild,
    getAllRules,
    updateRule,
    getAllNps,
    getNps,
    updateNps,
    createNps,
    publishNps,
    unpublishNps,
    revertNps,
    getTranslationMetaForFlow,
    getFlowNameTranslations,
    updateFlowNameTranslation,
    getAllLocales,
    createLocale,
    deleteLocale,
    downloadTranslations,
    uploadTranslation,
    deleteTranslation,

    // Entitlements endpoints
    getAccountEntitlements,
    getAccountEntitlement,
    getPlanEntitlements,

    // Account Metrics
    getAccountMetrics,

    // Integration endpoints
    getIntegrations,
    setIntegration,
    deleteIntegration,

    // Subscriptions
    createOrUpdateSubscription,

    removeUser,
    updateAccountUser,
    getAccountUsers,
    getUser,
    updateUser,
    getThemes,
    updateTheme,
    createTheme,
    deleteTheme,
    setDefaultTheme,

    getAllChecklists,
    getChecklist,
    updateChecklist,
    createChecklist,
    deleteChecklist,
    cloneChecklist,
    getChecklistStatus,
    publishChecklist,
    unpublishChecklist,
    revertChecklist,

    getExperienceSchedules,
    deleteExperienceSchedule,
    createExperienceSchedule,

    getSurveyResponses,
    getUserProfiles,
    getUserProfileAttributes,
    getEventUserProfiles,
    getGroups,
    getGroupProfileProperties,
  };

  return Object.keys(client).reduce((acc, key) => {
    acc[key] = async (...args) => {
      const token = await auth.getToken();
      const id = Number.parseInt(await auth.getAccountId(), 10);
      return client[key](token, id, ...args);
    };
    return acc;
  }, {});
}
