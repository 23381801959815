import React, {
  StrictMode,
  Suspense,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { addMethod, string } from 'yup';
import { Toast } from '@studio/legacy-components';
import { useHistory } from 'react-router-dom';
import { DevTools, enabled, enhance } from 'next/lib/devtools';
import initialize from 'next/root/initialize';
import { configure, StoreContext } from 'next/root/store';
import { navigate } from 'next/lib/history';
import Routes from 'next/pages/Routes';
import GlobalStyle from 'next/components/GlobalStyle';
import { urlWithLocalhost } from 'utils/yup-validations';
import Loader from 'components/Common/Loader';
/**
 * NOTE: During the migration, the router provider needs to be shared so it will
 *       be provided at the original entry. Once we can deprecate old studio, we
 *       can bring the provider back here.
 *
 *       Additionally, this application will accept the legacy application as a
 *       child in order to pass down the initialized bootstrap data for the
 *       legacy application to use if needed. Specifically, having access
 *       feature flags is crucial so that we can properly set up the available
 *       routes for next studio and the passthrough routes for legacy studio.
 */

/**
 * Hook to fetch auth and configure Redux store
 */
const useInitialize = () => {
  const [state, setState] = useState({});

  useLayoutEffect(() => {
    (async () => {
      try {
        const data = await initialize();
        const bootstrap = enhance(data);
        const store = configure(bootstrap);

        StoreContext.set(store);

        setState({ bootstrap, store });
      } catch (error) {
        setState({ error });

        // TODO: Handle error properly...
        // eslint-disable-next-line no-console
        console.error(error);
      }
    })();
  }, []);

  return state;
};

/**
 * Precondition check to determine if a redirect is required
 *
 * @param {boolean} isSignedUp - Whether to redirect user to signup info page
 * @param {boolean} redirectToAccSwitcher - Whether to redirect user to account switcher page
 * @param {boolean} isWelcomeTrialPagesEnabled - Whether to enable welcome trial pages
 * @return {void}
 */
export default function usePreconditions(
  isSignedUp,
  redirectToAccSwitcher,
  isWelcomeTrialPagesEnabled
) {
  const [isResolved, setIsResolved] = useState(false);
  const {
    location: { pathname },
  } = useHistory();

  useEffect(() => {
    if (isSignedUp && pathname === '/signup_info') {
      navigate('/');
      return;
    }

    // The first time that the welcome pages loads in, the flags are undefined.
    if (
      typeof isWelcomeTrialPagesEnabled !== 'undefined' &&
      !isWelcomeTrialPagesEnabled &&
      ['/welcome', '/usage', '/invite-team-members'].includes(pathname)
    ) {
      navigate('/');
      return;
    }

    if (
      isSignedUp === false &&
      !['/signup_info', '/usage'].includes(pathname)
    ) {
      navigate('/signup_info');
      return;
    }

    if (redirectToAccSwitcher && pathname !== '/accounts') {
      navigate('/accounts');
      return;
    }

    if (isSignedUp !== undefined && pathname !== undefined) {
      setIsResolved(true);
    }
  }, [isSignedUp, pathname, redirectToAccSwitcher, isWelcomeTrialPagesEnabled]);

  return isResolved;
}

// Add global method of validating URL with localhost to yup
addMethod(string, 'urlWithLocalhost', urlWithLocalhost);

export function NextApplication({ children }) {
  const { bootstrap, error, store } = useInitialize();

  const { isSignedUp, redirectToAccSwitcher } = bootstrap || {};
  const { WELCOME_TRIAL_PAGES: isWelcomeTrialPagesEnabled } =
    bootstrap?.features || {};

  // Run precondition checks to see if a redirectToAccSwitcher is required
  const isResolved = usePreconditions(
    isSignedUp,
    redirectToAccSwitcher,
    isWelcomeTrialPagesEnabled
  );

  // If the store has not been set or an error occurred, show nothing (for now)
  // to ensure we don't block legacy studio from loading properly. Eventually,
  // we will want to show a loading state and actionable error state.
  if (store == null || error) {
    // FIXME: Remove this behavior once we have pages in studio next
    return null;
  }

  // Destructure properties for Devtools
  const { features, spoofing, user } = bootstrap;

  if (!isResolved || !bootstrap) {
    return <Loader />;
  }

  // Otherwise render the application
  // NOTE: The TemporaryLayout serves as a placeholder for the new
  // Navbar until we make it completely independent
  return (
    <StrictMode>
      <Suspense fallback={<Loader />}>
        <GlobalStyle />

        {enabled(user, spoofing) && (
          <DevTools features={features} user={user} />
        )}
        <Provider store={store}>
          <Routes />
        </Provider>

        {children?.(bootstrap)}

        <Toast />
      </Suspense>
    </StrictMode>
  );
}

NextApplication.propTypes = {
  children: PropTypes.func,
};
