export const selectEntitlementsByName = (state, name) => {
  if (!state.entitlementsV2) {
    return null;
  }
  return state.entitlementsV2[name];
};

export const selectIsEntitledByName = (state, name) => {
  const entitlement = selectEntitlementsByName(state, name);
  return entitlement?.allowed_units > entitlement?.used_units;
};
