import { combineReducers } from 'redux';

/* eslint-disable no-restricted-imports */
import experiences from 'entities/experiences/reducer';
import schedules from 'entities/schedules/reducer';
import surveys from 'entities/surveys/reducer';
import endUserProfiles from 'entities/user-profiles/reducer';
import userProfileAttributes from 'entities/user-profiles-attributes/reducer';
import eventUserProfiles from 'entities/event-user-profiles/reducer';
import groups from 'entities/groups/reducer';
import groupProfileProperties from 'entities/group-profile-properties/reducer';
import account from 'reducers/account';
import accounts from 'reducers/accounts';
import currentModal from 'reducers/currentModal';
import view from 'reducers/view';
import user from 'reducers/user';
import chromeExtension from 'reducers/chromeExtension';
import billing from 'reducers/billing';
import newBilling from 'reducers/newBilling';
import spoofing from 'reducers/spoofing';
import entitlements from 'reducers/entitlements';
import entitlementsV2 from 'reducers/entitlements-v2';
import accountMetrics from 'reducers/accountMetrics';

const state = combineReducers({
  account,
  accounts,
  currentModal,
  user,
  view,
  chromeExtension,
  billing,
  newBilling,
  spoofing,
  entitlements,
  entitlementsV2,
  schedules,
  surveys,
  endUserProfiles,
  eventUserProfiles,
  userProfileAttributes,
  experiences,
  groups,
  groupProfileProperties,
  accountMetrics,
});

export default state;
