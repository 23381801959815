/* eslint-disable @appcues/jsx-props-no-spreading */

import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import {
  selectFeatures,
  MOBILE_FLOWS,
  NEXT_FLOW_SETTINGS,
  LAUNCHPADS_V2,
  OPEN_BUILDER_FLOWS,
} from 'next/entities/features';
import TemporaryLayout from 'next/pages/TemporaryLayout';
import { RedirectToStudio5 } from 'next/lib/platform';
import useListeners from './use-listeners';

/**
 * Pins
 */

const LazyPinsPage = lazy(() =>
  import('next/pages/pins').then(module => ({
    default: module.PinsPage,
  }))
);

const LazyPinsSettingsPage = lazy(() =>
  import('next/pages/pins').then(module => ({
    default: module.SettingsPage,
  }))
);

const LazyPinsAnalyticsPage = lazy(() =>
  import('next/pages/pins').then(module => ({
    default: module.AnalyticsPage,
  }))
);

/**
 * Banners
 */

const LazyBannerSettingsPage = lazy(() =>
  import('next/pages/banners').then(module => ({
    default: module.BannerSettingsPage,
  }))
);

const LazyBannerAnalyticsPage = lazy(() =>
  import('next/pages/banners').then(module => ({
    default: module.BannerAnalyticsPage,
  }))
);

const LazyBannersPage = lazy(() =>
  import('next/pages/banners').then(module => ({
    default: module.BannersPage,
  }))
);

/**
 * Flows
 */

const LazyFlowsListingPage = lazy(() =>
  import('next/pages/flows').then(module => ({
    default: module.FlowsListingPage,
  }))
);

const LazyFlowSettingsPage = lazy(() =>
  import('next/pages/flows').then(module => ({
    default: module.FlowSettingsPage,
  }))
);

const LazyMobileSettingsPage = lazy(() =>
  import('next/pages/mobile').then(module => ({
    default: module.SettingsPage,
  }))
);

const LazyMobileAnalyticsPage = lazy(() =>
  import('next/pages/mobile').then(module => ({
    default: module.AnalyticsPage,
  }))
);

/**
 * Launchpad
 */

const LazyLaunchpadSettingsPage = lazy(() =>
  import('next/pages/launchpad').then(module => ({
    default: module.LaunchpadSettingsPage,
  }))
);

const LazyLaunchpadsPage = lazy(() =>
  import('next/pages/launchpad').then(module => ({
    default: module.LaunchpadsPage,
  }))
);

const LazyAccountsPage = lazy(() =>
  import('next/pages/accounts').then(module => ({
    default: module.AccountsPage,
  }))
);

const LazySignupInfo = lazy(() =>
  import('next/pages/SignupInfo').then(module => ({
    default: module.SignupInfo,
  }))
);

const LazyUsageIntent = lazy(() =>
  import('next/pages/UserIntent').then(module => ({
    default: module.UsageIntent,
  }))
);

const LazyUserIntent = lazy(() =>
  import('next/pages/UserIntent').then(module => ({
    default: module.UserIntent,
  }))
);

const LazyInviteTeamMembers = lazy(() =>
  import('next/pages/UserIntent').then(module => ({
    default: module.InviteTeamMembers,
  }))
);

const LazyFlowsV2SettingsPage = lazy(() =>
  import('next/pages/flowsV2').then(module => ({
    default: module.FlowsV2SettingsPage,
  }))
);

/**
 * Creates a route that redirects the user to studio5.
 *
 * @param {string} path - Route path
 * @param {string} [feature] - Feature flag associated with the route
 * @returns {object} Route metadata object
 */
const createExternalRoute = (path, feature) => ({
  enabled: feature ? undefined : true, // defaults to true (enabled) if there's no feature flag associated
  feature,
  path,
  exact: true,
  render: () => {
    return (
      <TemporaryLayout>
        <RedirectToStudio5 />
      </TemporaryLayout>
    );
  },
});

/**
 * Shareable route registry
 *
 * NOTE: In the future, we can inline the parameters directly as props to
 *       <Route>, but since we need to create conditional passthrough routes in
 *       legacy studio as well, we need to define these routes in a way it can
 *       be consumed in multiple places
 *
 * FIXME: remove TemporaryLayout once we make the Nav independent from old studio
 */
export const routes = [
  createExternalRoute('/apps/:id'),
  createExternalRoute('/audience/accounts'),
  createExternalRoute('/audience/accounts/:id/overview'),
  createExternalRoute('/audience/accounts/:id/properties'),
  createExternalRoute('/audience/accounts/:id/users'),
  createExternalRoute('/audience/segments-v2'),
  createExternalRoute('/audience/users-v2'),
  createExternalRoute('/audience/users-v2/:id'),
  createExternalRoute('/audience/users-v2/:id/properties'),
  createExternalRoute('/emails/:id'),
  createExternalRoute('/experiments'),
  createExternalRoute('/experiments/:id/settings'),
  createExternalRoute('/flows/performance'),
  createExternalRoute('/insights/pages'),
  createExternalRoute('/integrations/connections'),
  createExternalRoute('/integrations/webhooks'),
  createExternalRoute('/journeys'),
  createExternalRoute('/journeys/:id'),
  createExternalRoute('/journeys/preview'),
  createExternalRoute('/launchpads/:id/analytics'),
  createExternalRoute('/launchpads/:id/builder'),
  createExternalRoute('/launchpads/builder'),
  createExternalRoute('/flows/v2/builder'),
  createExternalRoute('/flows/v2/:experienceId/builder'),
  createExternalRoute('/flows/v2/:experienceId/analytics'),
  createExternalRoute('/push-notifications/:id'),
  createExternalRoute('/segments-v2/:id/edit'),
  createExternalRoute('/segments-v2/:id/view'),
  createExternalRoute('/settings/emails/senders'),
  createExternalRoute('/settings/emails/suppression-lists/unsubscribes'),
  createExternalRoute('/settings/emails/suppression-lists/bounces'),
  createExternalRoute('/settings/emails/suppression-lists/spams'),
  createExternalRoute('/settings/emails/sending-behavior'),
  createExternalRoute('/settings/identifier'),
  createExternalRoute('/settings/team'),
  createExternalRoute('/settings/subscription-v2'),
  createExternalRoute('/settings/properties-v2'),
  createExternalRoute('/settings/properties-v2/user'),
  createExternalRoute('/settings/properties-v2/group'),
  createExternalRoute('/webhooks'),
  createExternalRoute('/workflows'),
  createExternalRoute('/workflows/:id'),
  {
    enabled: true,
    path: '/flows',
    exact: true,
    render: routeProps => {
      return (
        <TemporaryLayout>
          <LazyFlowsListingPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
  {
    feature: NEXT_FLOW_SETTINGS,
    path: '/flows/:flowId/settings',
    exact: true,
    render: routeProps => {
      return (
        <TemporaryLayout>
          <LazyFlowSettingsPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
  {
    enabled: true,
    path: `/pins/:pinId/settings`,
    render: routeProps => {
      return (
        <TemporaryLayout>
          <LazyPinsSettingsPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
  {
    enabled: true,
    path: '/pins/:pinId/analytics',
    render: routeProps => {
      return (
        <TemporaryLayout>
          <LazyPinsAnalyticsPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
  {
    enabled: true,
    path: '/pins',
    render: routeProps => {
      return (
        <TemporaryLayout>
          <LazyPinsPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
  {
    enabled: true,
    path: '/banners/:experienceId/settings',
    render: routeProps => {
      return (
        <TemporaryLayout>
          <LazyBannerSettingsPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
  {
    enabled: true,
    path: '/banners/:experienceId/analytics',
    render: routeProps => {
      return (
        <TemporaryLayout>
          <LazyBannerAnalyticsPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
  {
    enabled: true,
    path: '/banners',
    render: routeProps => {
      return (
        <TemporaryLayout>
          <LazyBannersPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
  {
    enabled: true,
    path: '/accounts',
    render: routeProps => {
      return <LazyAccountsPage size="full" {...routeProps} />;
    },
  },
  {
    feature: MOBILE_FLOWS,
    path: '/mobile/flows/:experienceId/settings',
    render: routeProps => {
      return (
        <TemporaryLayout>
          <LazyMobileSettingsPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
  {
    feature: MOBILE_FLOWS,
    path: '/mobile/flows/:experienceId/analytics',
    render: routeProps => {
      return (
        <TemporaryLayout>
          <LazyMobileAnalyticsPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
  {
    enabled: true,
    path: '/signup_info',
    render: () => <LazySignupInfo />,
  },
  {
    enabled: true,
    path: '/usage',
    render: () => <LazyUsageIntent />,
  },
  {
    enabled: true,
    path: '/welcome',
    render: () => <LazyUserIntent />,
  },
  {
    enabled: true,
    path: '/invite-team-members',
    render: () => <LazyInviteTeamMembers />,
  },
  {
    feature: LAUNCHPADS_V2,
    path: '/launchpads/:experienceId/settings',
    render: routeProps => {
      return (
        <TemporaryLayout>
          <LazyLaunchpadSettingsPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
  {
    feature: LAUNCHPADS_V2,
    path: '/launchpads',
    render: routeProps => {
      return (
        <TemporaryLayout>
          <LazyLaunchpadsPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
  {
    feature: OPEN_BUILDER_FLOWS,
    path: '/flows/v2/:experienceId/settings',
    render: routeProps => {
      return (
        <TemporaryLayout>
          <LazyFlowsV2SettingsPage {...routeProps} />
        </TemporaryLayout>
      );
    },
  },
];

/**
 * Filter accessible routes based on feature flags
 *
 * @param {Route[]} registry - List of registered routes
 * @param {object<boolean>} features - Map of feature flags
 * @return {Route[]} Filtered list of routes
 */
export const filter = (registry = [], features = {}) =>
  registry.filter(({ feature, enabled }) => enabled ?? features[feature]);

/**
 * Top-level routes for studio next
 */
export function Routes({ features = {}, routes: registry = routes }) {
  const accessible = filter(registry, features);

  useListeners();

  return (
    <Switch>
      {accessible.map(({ path, ...rest }) => (
        <Route key={path} path={path} {...rest} />
      ))}
    </Switch>
  );
}

Routes.propTypes = {
  features: PropTypes.objectOf(PropTypes.bool),
  routes: PropTypes.shape({
    enabled: PropTypes.bool,
    exact: PropTypes.bool,
    feature: PropTypes.bool,
    path: PropTypes.string,
    render: PropTypes.func,
  }),
};

const mapStateToProps = state => ({
  features: selectFeatures(state),
});

export default connect(mapStateToProps)(Routes);
