import React from 'react';
import styled from 'styled-components';
import { BeaconLink } from 'next/components/BeaconLink';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function HistoricalLimitExceeded() {
  return (
    <Wrapper>
      Your plan's historical data is limited.
      <BeaconLink
        subject="Upgrading plan limits"
        message="I'd like to upgrade my Appcues plan to have a greater range in my date range reporting."
      >
        Contact support to upgrade
      </BeaconLink>
    </Wrapper>
  );
}
