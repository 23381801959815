import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from '@studio/legacy-components';
import { provide } from 'next/components/Hacks';
import { selectHistoricalLimit } from 'next/lib/selectors';
import { restrict, validate } from './date-limits';
import FooterMessage from './FooterMessage';

export function BoundedDateRangePicker({
  disabled,
  duration,
  limit,
  options,
  value,
  onChange,
  ...unhandledProps
}) {
  const [message, setMessage] = useState();
  const [restrictions, setRestrictions] = useState({});

  useEffect(() => {
    setRestrictions(restrict(value, { limit, duration }));
  }, [duration, limit, value]);

  const handleChange = next => {
    setMessage(validate(next, { limit, duration }));
    setRestrictions(restrict(next, { limit, duration }));
    onChange(next);
  };

  const handleDayClick = next => {
    setMessage(validate(next, { limit, duration }));
  };

  const invalid = Boolean(message);

  const inputs = {
    footer: typeof message === 'string' && (
      <FooterMessage duration={duration} message={message} />
    ),
    inputProps: { error: invalid },
  };

  return (
    <DateRangePicker
      disabled={disabled}
      error={invalid}
      onChange={handleChange}
      onDayClick={handleDayClick}
      options={options}
      endProps={{
        ...inputs,
        dayPickerProps: {
          disabledDays: [restrictions.end],
        },
        placement: 'top',
      }}
      startProps={{
        ...inputs,
        dayPickerProps: {
          disabledDays: [restrictions.start],
        },
        placement: 'top',
      }}
      value={value}
      {...unhandledProps}
    />
  );
}

BoundedDateRangePicker.propTypes = {
  ...DateRangePicker.propTypes,
  duration: PropTypes.number,
  limit: PropTypes.number,
};

const mapStateToProps = (state, { limit }) => ({
  limit: limit ?? selectHistoricalLimit(state),
});

export default provide(mapStateToProps)(BoundedDateRangePicker);
