export const NPS_FLAT_MAU_LIMIT = '100000';
export const DEFAULT_PLAN_LIMIT = '2500';
export const NORMALIZED_PLAN_LIMIT = 20000;

export const MONTHLY_INTERVAL = 'monthly';
export const YEARLY_INTERVAL = 'yearly';

export const BILLING_PERIODS = ['yearly', 'monthly', 'custom'];

export const BILLING_INTERVALS = {
  yearly: {
    name: 'yearly',
    displayName: 'annually',
    displayPeriod: 'year',
    aliases: ['annual', 'year'],
    npsAlias: 'annual',
    multiplier: 12,
  },
  monthly: {
    name: 'monthly',
    displayName: 'monthly',
    displayPeriod: 'Monthly',
    multiplier: 1,
  },
  custom: {
    name: 'custom',
    displayName: 'custom',
    displayPeriod: 'Custom',
    multiplier: 1,
  },
};

export const PRICES = {
  [BILLING_INTERVALS.monthly.name]: {
    400: { starter: 79, standard: 129, enterprise: 3000 },
    1000: { starter: 129, standard: 199, enterprise: 3000 },
    2500: { starter: 199, standard: 299, enterprise: 3000 },
    5000: { starter: 199, standard: 359, enterprise: 3000 },
    10000: { starter: 299, standard: 479, enterprise: 3000 },
    15000: { starter: 299, standard: 599, enterprise: 3000 },
    20000: { starter: 479, standard: 779, enterprise: 3000 },
  },
  [BILLING_INTERVALS.yearly.name]: {
    400: { starter: 69, standard: 99, enterprise: 2500 },
    1000: { starter: 99, standard: 159, enterprise: 2500, start: 250 },
    1500: { start: 290 },
    2000: { start: 330 },
    2500: { start: 370, starter: 159, standard: 249, enterprise: 2500 },
    3000: { start: 410 },
    3500: { start: 450 },
    4000: { start: 490 },
    4500: { start: 530 },
    5000: { start: 570, starter: 199, standard: 299, enterprise: 2500 },
    6000: { start: 630 },
    7000: { start: 690 },
    8000: { start: 750 },
    9000: { start: 810 },
    10000: { start: 870, standard: 399 },
    15000: { starter: 249, standard: 499, enterprise: 2500 },
    20000: { starter: 399, standard: 649, enterprise: 2500 },
  },
};

export const NPS_PRICES = {
  [BILLING_INTERVALS.monthly.name]: {
    [NPS_FLAT_MAU_LIMIT]: {
      'nps-starter-100k': 99,
      'nps-standard-100k': 499,
      'nps-standard-addon': 399,
      'nps-enterprise': 3000,
    },
  },
  [BILLING_INTERVALS.yearly.name]: {
    [NPS_FLAT_MAU_LIMIT]: {
      'nps-starter-100k': 84,
      'nps-standard-100k': 429,
      'nps-standard-addon': 349,
      'nps-enterprise': 2500,
    },
  },
};

const TARGETING = 'Audience targeting';
const PERSONALIZE = 'Personalization';
export const ONBOARD_MANAGER = '90 day onboarding program';

const UNLIMITED = 'Publish unlimited flows';
const BRANDING = 'Appcues branding';

export const PLAN_TYPES = [
  {
    id: 'starter',
    name: 'Starter',
    description: 'Everything a startup needs to improve activation',
    visible: false,
    features: {
      [UNLIMITED]: true,
      [TARGETING]: true,
      [PERSONALIZE]: true,
      [BRANDING]: true,
    },
  },
  {
    id: 'essentials',
    pricePlanId: 'standard',
    name: 'Essentials',
    description: 'Ideal for individuals at startups and small businesses',
    visible: true,
    pricePostCaption: 'paid annually',
    color: '$blue-dark',
    gradient: '$daba-dee-daba-die',
    featuresCaption: 'Includes:',
    features: {
      'Up to 3 user seats': true,
      'All standard UI patterns': true,
      'Up to 10 click-to-track events': true,
      'Analytics integrations': true,
      'Email support': true,
    },
  },
  {
    id: 'standard',
    name: 'Standard',
    description: 'Robust features for growth-focused teams',
    visible: false,
    features: {
      '5 seats on account': true,
      'Unlimited core patterns': true,
      'Unlimited NPS': true,
      'Analytics integrations': true,
    },
  },
  {
    id: 'growth',
    pricePlanId: 'standard',
    name: 'Growth',
    description: 'Ideal for teams at high-growth companies',
    visible: true,
    price: '$879/mo',
    pricePostCaption: 'paid annually',
    color: '$purple',
    gradient: '$blurple-haze',
    cta: 'Contact sales',
    featuresCaption: 'Everything in Essentials, plus:',
    features: {
      'Up to 10 user seats': true,
      'Advanced UI patterns': true,
      'Unlimited click-to-track events': true,
      'Access to all integrations': true,
    },
  },
  {
    id: 'enterprise',
    name: 'Enterprise',
    description: 'Ideal for enterprises or businesses with 2+ products',
    visible: true,
    price: "Let's talk",
    pricePreCaption: 'Request a demo for pricing',
    color: '$gray-8',
    gradient: '$regulus',
    cta: 'Contact sales',
    featuresCaption: 'Everything in Growth, plus:',
    features: {
      'Unlimited user seats': true,
      'Advanced user targeting options': true,
      'Multi-account support': true,
      'Enhanced security & SLA': true,
      'Premium services and support': true,
    },
  },
  {
    id: 'start',
    pricePlanId: 'start',
    name: 'Start',
    description: '',
    visible: false,
    pricePostCaption: 'paid annually',
    color: '$purple',
    gradient: '$blurple-haze',
    cta: '',
    featuresCaption: '',
    features: {
      'In-app, email, and mobile push messaging': true,
      '5,000+ Messages per month': true,
      'User data and segmentation': true,
      'No-code event tracking': true,
      'Email support': true,
    },
  },
  {
    id: 'grow',
    pricePlanId: 'grow',
    name: 'Grow',
    description: '',
    visible: false,
    pricePostCaption: 'paid annually',
    color: '$purple',
    gradient: '$blurple-haze',
    cta: '',
    featuresCaption: '',
    features: {
      'Premium in-app experiences': true,
      '5,000+ Messages per month': true,
      'More Flows, Workflows, Segments, and Events': true,
      'Premium integrations': true,
      'Customer Success': true,
    },
  },
  {
    id: 'enterprise-v2',
    pricePlanId: 'enterprise-v2',
    name: 'Enterprise',
    description: '',
    visible: false,
    pricePostCaption: 'paid annually',
    color: '$purple',
    gradient: '$blurple-haze',
    cta: '',
    featuresCaption: '',
    features: {
      'Flexible pricing that works at scale': true,
      'Custom entitlements and limits': true,
      'Favorable multi-product pricing': true,
      'Ask about advanced security & SLAs': true,
      'Dedicated Implementation & Success Manager': true,
    },
  },
  {
    id: 'scale',
    pricePlanId: 'scale',
    name: 'Scale',
    description: '',
    visible: false,
    pricePostCaption: 'paid annually',
    color: '$purple',
    gradient: '$blurple-haze',
    cta: '',
    featuresCaption: '',
    features: {
      'Premium in-app experiences': true,
      '5,000+ Messages per month': true,
      'More Flows, Workflows, Segments, and Events': true,
      'Premium integrations': true,
      'Customer Success': true,
    },
  },
];

export const NPS_PLAN_TYPES = [
  {
    id: 'nps-starter-100k',
    name: 'NPS Starter',
    description:
      'Everything your product-led organization needs to measure user satisfaction',
    features: [
      'Customizable, rolling NPS surveys',
      'Targeted to activated users',
      'Reporting dashboard and exports',
      'Up to 100k Monthly Active Users',
    ],
  },
  {
    id: 'nps-standard-100k',
    name: 'NPS Standard',
    subhead: 'Coming Soon',
    cta: 'Contact Us',
    description:
      'Distribute feedback across your organization for immediate action',
    features: [
      'Analytics, CRM, and ESP integrations',
      'Multiple cohort dashboards',
      'Up to 100k Monthly Active Users',
    ],
  },
  {
    id: 'nps-enterprise',
    name: 'Enterprise',
    subhead: 'Starting at',
    cta: 'Contact Us',
    description: 'Full-service package for lasting impact',
    features: [
      'Permissions and roles',
      'Enhanced security & SLA',
      'Custom contracts & invoicing',
      'Unlimited Monthly Active Users',
    ],
  },
];

export const PLAN_LIMITS = [
  {
    id: '2500',
    name: '1 - 2,500',
  },
  {
    id: '5000',
    name: '2,500 - 5,000',
  },
  {
    id: '10000',
    name: '5,000 - 10,000',
  },
  {
    id: '15000',
    name: '10,000 - 15,000',
  },
  {
    id: '20000',
    name: '15,000 - 20,000',
  },
  {
    id: 'high-volume',
    name: '20,000 and up',
  },
];

export const HISTORICAL_PLAN_LIMITS = [
  {
    id: '400',
    name: '1 - 400',
  },
  {
    id: '1000',
    name: '401 - 1,000',
  },
  {
    id: '2500',
    name: '1 - 2,500',
  },
  {
    id: '10000',
    name: '2,500 - 10,000',
  },
  {
    id: '20000',
    name: '10,000 - 20,000',
  },
  {
    id: '50000',
    name: '20,000 - 50,000',
  },
  {
    id: 'high-volume',
    name: '50,000 and up',
  },
];

export const PLAN_FEATURES = [
  {
    name: 'Team members',
    description:
      'Invite your teammates to Appcues and collaborate on building, designing, and analyzing flows.',
    plans: {
      starter: 'Up to 4',
      standard: 'Up to 12',
      enterprise: 'Unlimited',
    },
  },
  {
    name: 'Unlimited flows',
    description:
      'Create and publish unlimited flows to improve activation, help users discover new features, and more.',
    plans: 'all',
  },
  {
    name: 'Audience targeting & personalization',
    description:
      'Personalize your product experience by targeting segments of users based on their attributes, product usage, language, and anything else.',
    plans: 'all',
  },
  {
    name: 'Flow analytics',
    description:
      'See how many people viewed your flows, how they engaged with them, and analyze their completion rates all from within Appcues.',
    plans: 'all',
  },
  {
    name: 'Custom styling',
    description:
      'Make your Appcues flows look just like your product with customizable design editor and CSS support.',
    plans: 'all',
  },
  {
    name: 'Basic integrations',
    description:
      'Send Appcues data to tools like Google Analytics, Mixpanel, Segment, Amplitude, Intercom, and more.',
    plans: 'all',
  },
  {
    name: 'Awesome support',
    description:
      'Access our online knowledge base and get email support from our friendly team of product experts Monday - Friday 9am-5pm EST.',
    plans: 'all',
  },
  {
    name: 'Advanced behavioral targeting',
    description:
      'Target users based how often they take certain actions within specific timeframes.',
    plans: { standard: true, enterprise: true },
  },
  {
    name: 'Unbranded flows',
    description: 'Remove the "Powered by Appcues" badge from your flows.',
    plans: { standard: true, enterprise: true },
  },
  {
    name: 'Advanced integrations',
    description:
      'Coming soon! Send Appcues data to big data tools like Looker or Tableau, or trigger webhooks to send data to any other custom integration.',
    isComingSoon: true,
    plans: { standard: true, enterprise: true },
  },
  {
    name: 'Localization',
    description:
      'Coming soon! Automatically translate your flows into different languages to message users in their native tongue.',
    isComingSoon: true,
    plans: { standard: true, enterprise: true },
  },
  {
    name: 'Dedicated success coach',
    description:
      "Get white glove treatment from a dedicated Success Manager on technical implementation. best practice consultation, and strategy review tailored to your team's specific activation and user engagement goals.",
    plans: { enterprise: true },
  },
  {
    name: 'Custom contracts & invoicing',
    description:
      'Create a custom contract for high-volume usage and receive an invoice to pay by check or ACH.',
    plans: { enterprise: true },
  },
  {
    name: 'Enhanced security & SLA',
    description:
      'Get an uptime guarantee and additional security provisions like 2-Factor Authentication or custom security reporting.',
    plans: { enterprise: true },
  },
  {
    name: 'Advanced user permissions',
    description:
      'Coming soon! Restrict permissions like publishing or editing flows to only certain members of your team.',
    isComingSoon: true,
    plans: { enterprise: true },
  },
];

export const PLAN_TO_TIER_MAPPINGS = {
  '1188-standard-yearly': 'standard',
  '1188-starter-yearly': 'starter',
  '11988-standard-yearly': 'standard', // standard high
  '1199-standard-monthly': 'standard', // standard high
  '129-standard-monthly': 'standard',
  '129-starter-monthly': 'starter',
  '150-startup': 'standard',
  '150/mo6mo/upfront': 'starter',
  '150yrupfront': 'starter',
  '1908-standard-yearly': 'standard',
  '1908-starter-yearly': 'starter',
  '199-standard-monthly': 'standard',
  '199-starter-monthly': 'starter',
  '200-startup': 'standard',
  '2988-standard-yearly': 'standard',
  '2988-starter-yearly': 'starter',
  '299-standard-monthly': 'standard',
  '299-starter-monthly': 'starter',
  '299-startup': 'standard',
  '40-beta': 'starter',
  '45-hobby': 'starter',
  '450-business': 'standard',
  '4788-standard-yearly': 'standard',
  '4788-starter-yearly': 'starter',
  '479-standard-monthly': 'standard',
  '479-starter-monthly': 'starter',
  '50%Growth100k': 'standard',
  '5kstartup': 'standard',
  '600-custom-monthly': 'standard',
  '6000-custom-yearly': 'standard',
  '699-growth': 'standard',
  '7788-standard-yearly': 'standard', // standard high
  '7788-starter-yearly': 'starter',
  '779-standard-monthly': 'standard', // standard high
  '779-starter-monthly': 'starter',
  '79-starter-monthly': 'starter',
  '828-starter-yearly': 'starter',
  '99-bootstrap': 'starter',
  '99-custom': 'starter',
  '99/mo/6moupfront': 'starter',
  '999permonth': 'standard', // standard high
  'Appcues Standard (Monthly) - 500k': 'standard',
  'Appcues Standard - 150k': 'standard',
  'Appcues Standard 100k': 'standard',
  'Appcues Standard 1M (Annual)': 'standard',
  'Appcues Standard 200k (monthly)': 'standard',
  'Appcues Standard 500k (Annual)': 'standard',
  'Appcues Standard 50k': 'standard',
  'Bootstrap year upfront': 'starter',
  CBINSIGHTS: 'standard',
  DH1: 'standard',
  DH2: 'standard',
  'bootstrap-2000': 'starter',
  'nps-starter-100k-annual': 'nps-starter',
  'nps-starter-100k-monthly': 'nps-starter',
  'startup7k25%off': 'standard',
  'trial-unlimited': 'trial',
  Amplitudecustom: 'standard',
  CMAPstartup9k: 'standard',
  Growth100k: 'standard',
  Growth22k: 'standard',
  Growth40k: 'standard',
  HubSpotmo: 'standard',
  Monthly_Services: 'standard',
  Usertesting750: 'standard',
  adroll1299mo: 'standard',
  avero780: 'standard',
  aweber750mo: 'standard',
  bootstrap4m: 'starter',
  bootstrapnobranding1yr: 'starter',
  bootstrapnobranding: 'starter',
  brightscope1year: 'standard',
  cbinsightsyearly: 'standard',
  couchsurfing1250: 'standard',
  custom200k: 'standard',
  enterprise2k: 'standard',
  growth10poff: 'standard',
  growthyearupfront: 'standard',
  growthyrupfront: 'standard',
  inman449quart: 'standard',
  mentionspecial499: 'standard',
  salesloft7k: 'standard',
  startup11k: 'standard',
  startup12k: 'standard',
  startupagilityhealth: 'standard',
  startupyearupfront: 'standard',
  startupyrupfront2990: 'standard',
  storyhunter197mo: 'starter',
  unicorn1M: 'enterprise',
  WK: 'enterprise',
  brightcove: 'enterprise',
  '299-essentials-monthly': 'essentials',
  '479-essentials-monthly': 'essentials',
  '779-essentials-monthly': 'essentials',
  '1199-essentials-monthly': 'essentials',
  '2988-essentials-yearly': 'essentials',
  '4788-essentials-yearly': 'essentials',
  '7788-essentials-yearly': 'essentials',
  '11988-essentials-yearly': 'essentials',
  '359-essentials-monthly': 'essentials',
  '599-essentials-monthly': 'essentials',
  '3588-essentials-yearly': 'essentials',
  '5988-essentials-yearly': 'essentials',
};

export const TIER_LIMITS = {
  trial: {
    maxGoals: 2,
  },
  starter: {
    maxGoals: 10,
  },
  essentials: {
    maxGoals: 10,
  },
  standard: {
    maxGoals: 20,
  },
  enterprise: {
    maxGoals: 50,
  },
};

export const NEW_PLANS = ['start', 'grow', 'scale'];
