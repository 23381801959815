// eslint-disable-next-line no-restricted-imports
import scheduleSaga from 'entities/schedules/saga';
import accountManagement from 'sagas/accountManagement';
import accountMetricsSagas from 'sagas/accountMetrics';
import accountSagas from 'sagas/account';
import accountsSagas from 'sagas/accounts';
import analyticsSagas from 'sagas/analytics';
import billing from 'sagas/billing';
import chromeExtension from 'sagas/chromeExtension';
import estimationSagas from 'sagas/estimation';
import eventSagas from 'sagas/events';
import exportSagas from 'sagas/exports';
import entitlementsSagas from 'sagas/entitlements';
import entitlementsV2Sagas from 'sagas/entitlements-v2';
import bootstrap from 'sagas/bootstrap';
import metricsSagas from 'sagas/metrics';
import routingSagas from 'sagas/routing';
import satisfactionSagas from 'sagas/satisfaction';
import segmentsSagas from 'sagas/account/segments';
import timingSaga from 'sagas/timing';
import userProfileSagas from 'sagas/userProfiles';
import userSagas from 'sagas/user';
import view from 'sagas/view';

/**
 * NOTE: These imports are intentionally NOT public APIs of the entities and
 *       only to be used here for registering the sagas.
 */

/* eslint-disable no-restricted-imports */
import npsSaga from 'entities/nps/saga';
import surveysSaga from 'entities/surveys/saga';
import endUserProfiles from 'entities/user-profiles/saga';
import userProfileAttributes from 'entities/user-profiles-attributes/saga';
import eventUserProfiles from 'entities/event-user-profiles/saga';
import groups from 'entities/groups/saga';
import groupProfileProperties from 'entities/group-profile-properties/saga';
import keySaga from 'entities/keys/saga';
import userSegmentsSaga from 'entities/user-segments/saga';
import userQualifiedContentSaga from 'entities/user-qualified-content/saga';
import profileAttributeLabelsSaga from 'entities/profile-attribute-labels/saga';
import conditionsEstimatesSaga from 'entities/conditions-estimates/saga';
import experiencesSaga from 'entities/experiences/saga';
import eventLabelsSaga from 'entities/event-labels/saga';
import checklistsSaga from 'entities/checklists/saga';
import checklistStatusSaga from 'entities/checklist-status/saga';
/* eslint-enable no-restricted-imports */

export const SAGAS = [
  ...accountSagas,
  accountsSagas,
  accountManagement,
  accountMetricsSagas,
  analyticsSagas,
  billing,
  chromeExtension,
  entitlementsSagas,
  entitlementsV2Sagas,
  estimationSagas,
  eventSagas,
  eventLabelsSaga,
  exportSagas,
  bootstrap,
  metricsSagas,
  routingSagas,
  satisfactionSagas,
  segmentsSagas,
  timingSaga,
  userProfileSagas,
  userSagas,
  view,
  npsSaga,
  keySaga,
  userSegmentsSaga,
  profileAttributeLabelsSaga,
  userQualifiedContentSaga,
  conditionsEstimatesSaga,
  experiencesSaga,
  checklistsSaga,
  checklistStatusSaga,
  scheduleSaga,
  surveysSaga,
  endUserProfiles,
  eventUserProfiles,
  groups,
  groupProfileProperties,
  userProfileAttributes,
];
