import { takeEvery, call, put, getContext } from 'redux-saga/effects';
import { replacePagePatternFor } from 'next/entities/page';
import { resolve, reject, patterns } from './actions';

const transform = response =>
  response.reduce((acc, label) => {
    acc[label.name] = label;
    return acc;
  }, {});

export function* fetchProfileAttributeLabels() {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getProfileAttributeLabels);
    yield put(resolve(transform(response)));
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* saga() {
  // Internal actions
  yield takeEvery(patterns.read, fetchProfileAttributeLabels);

  // Page actions
  yield takeEvery(
    [
      replacePagePatternFor('/pins/:pinId/settings'),
      replacePagePatternFor('/mobile/flows/:experienceId/settings'),
      replacePagePatternFor('/pins/:pinId/analytics'),
      replacePagePatternFor('/mobile/flows/:experienceId/analytics'),
      replacePagePatternFor('/flows/:flowId/settings'),
      replacePagePatternFor('/banners/:experienceId/settings'),
      replacePagePatternFor('/launchpads/:experienceId/settings'),
      replacePagePatternFor('/flows/v2/:experienceId/settings'),
    ],
    fetchProfileAttributeLabels
  );
}
