import React from 'react';
import PropTypes from 'prop-types';
import { HISTORICAL_LIMIT_EXCEEDED, RANGE_LIMIT_EXCEEDED } from './date-limits';
import HistoricalLimitExceeded from './HistoricalLimitExceeded';

export default function FooterMessage({ duration, message }) {
  switch (message) {
    case RANGE_LIMIT_EXCEEDED:
      return `Exceeds maximum ${duration} day range.`;

    case HISTORICAL_LIMIT_EXCEEDED:
      return <HistoricalLimitExceeded />;

    default:
      return null;
  }
}

FooterMessage.propTypes = {
  duration: PropTypes.number,
  message: PropTypes.string,
};
