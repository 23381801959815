import { createLifecycleFor } from 'actions/account/collections';

const TYPE = 'translations';

const actions = createLifecycleFor(TYPE);

export const TRANSLATION_UPLOADED = 'TRANSLATION_UPLOADED';
export const TRANSLATION_DELETED = 'TRANSLATION_DELETED';
export const TRANSLATIONS_REQUESTED = 'TRANSLATION_REQUESTED';
export const TRANSLATIONS_DOWNLOADED = 'TRANSLATIONS_DOWNLOADED';
export const UPDATE_FLOW_NAME_TRANSLATION = 'UPDATE_FLOW_NAME_TRANSLATION';
export const uploadTranslation = ({ flowId, localeId, translation }) => ({
  type: TRANSLATION_UPLOADED,
  payload: { flowId, localeId, translation },
});

export const deleteTranslation = ({ flowId, translationId }) => ({
  type: TRANSLATION_DELETED,
  payload: { flowId, translationId },
});

export const requestTranslations = ({ flowId, format }) => ({
  type: TRANSLATIONS_REQUESTED,
  payload: { flowId, format },
});

export const translationsDownloaded = id => ({
  type: TRANSLATIONS_DOWNLOADED,
  payload: { id },
});

export const REMOVE_TRANSLATIONS = 'REMOVE_TRANSLATIONS';
export const removeTranslations = id => ({
  type: REMOVE_TRANSLATIONS,
  payload: { id },
});

export const TRANSLATIONS_REMOVED = 'TRANSLATIONS_REMOVED';
export const translationsRemoved = id => ({
  type: TRANSLATIONS_REMOVED,
  payload: { id },
});

export const updateFlowNameTranslation = payload => ({
  type: UPDATE_FLOW_NAME_TRANSLATION,
  payload,
});

export const {
  callApi: fetchTranslations,
  send,
  resolve,
  reject,
  update,
  replace,
  create,
  flush,
  remove,
  insert,
  patterns,
} = actions;
