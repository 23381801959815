/**
 * @deprecated - Please add flags to next/entities/feature/constants
 */

export const FREQUENCY_BUFFER = 'FREQUENCY_BUFFER';
export const WIDGET_TARGETING = 'WIDGET_TARGETING';
export const EXTRA_CHECKLIST_CUSTOMIZATION = 'EXTRA_CHECKLIST_CUSTOMIZATION';
export const SALESFORCE_INTEGRATION = 'SALESFORCE_INTEGRATION';
export const AB_TESTING = 'AB_TESTING';
export const LOCALIZATION_RELEASED = 'localization-released';
export const HUBSPOT_TWO_WAY_INTEGRATION = 'hubspot-two-way-integration';
export const SLACK_INTEGRATION = 'SLACK_INTEGRATION';
export const ZENDESK_SUPPORT_INTEGRATION =
  'zendesk-support-two-way-integration';
export const MARKETO_INTEGRATION = 'MARKETO_INTEGRATION';
export const CREATE_NEW_ACCOUNTS = 'CREATE_NEW_ACCOUNTS';
export const SAVED_CHARTS = 'SAVED_CHARTS';
export const STANDARD_TIMEFRAME_OPTIONS = 'STANDARD_TIMEFRAME_OPTIONS';
export const NPS_RANGE_SM = 'NPS_RANGE_SM';
export const NPS_RANGE_MED = 'NPS_RANGE_MED';
export const NPS_RANGE_LG = 'NPS_RANGE_LG';
export const NPS_DASHBOARD = 'NPS_DASHBOARD';
export const FLOW_PRIORITIZATION = 'FLOW_PRIORITIZATION';
export const FLOW_FREQUENCY = 'FLOW_FREQUENCY';
export const EXTRACTION_V2 = 'EXTRACTION_V2';
export const USER_PROFILE = 'USER_PROFILE';
export const ENTITLEMENTS = 'ENTITLEMENTS';
export const SEGMENT_EXPORT = 'SEGMENT_EXPORT';
export const RUDDERSTACK_INTEGRATION = 'RUDDERSTACK_INTEGRATION';
export const LAUNCHPAD_FLOW_NAME_LOCALIZATION =
  'LAUNCHPAD_FLOW_NAME_LOCALIZATION';
export const WORKFLOWS_PUBLISHING = 'WORKFLOWS_PUBLISHING';
